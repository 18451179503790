/**
 * Created by bcole on 7/14/18.
 */
import React from 'react';
import Axios from 'axios';
//import Validator from 'validator';
import FormPage from '../components/formpage';
import Endpoints from '../endpoints/endpoints';
import {parseJSON} from '../Utils/utils';
import Api from '../Utils/api';
import localStorageUtil from '../Utils/localStorageUtil';

import './form.css'
import serverUtil from '../Utils/serverUtil';

export default class Form extends React.Component {

    constructor(){
        super();

        this.state = {
            formdata: {
                company_name: "unknown",
                field_groups: [],
            },
            selectedFieldGroup: 0,
            values: {},
            otherValues: {},
            projectId: -1,
            imagesToUpload: [],
            hasUnsyncedChanges: false,
            connected: true,
            interval: null,
            openNote: false,
            notes: {},
            error: "",
            isStoringImage: false,
            hasError: false,
            loginMessage: false,
            menuOpen: false
        }
    }

    componentDidMount() {
       let projectId = this.props.match.params.id;

        this.setState({
            projectId: projectId
        });

        this.initUploads(projectId);


        //get your form values from either local storage
        //or from the api
        let localFormValues = {};
        localStorageUtil.getFormData(projectId).then((result) => {
            localFormValues = result;
            return(Api.getFormData(projectId))
        }).then((result) => {
            console.log("testing local against network");
            if(JSON.stringify(result) == JSON.stringify(localFormValues)) {
                this.setState({
                    formdata: result
                })
            } else {
                console.log("you are connected, but your form values don't match with your saved ones");
                this.setState({
                    formdata: result
                })
            }
        }).catch((e) => {
            //you aren't connected, use your local values
            this.setState({
                formdata: localFormValues
            })
        });

        let localReportValues = {};
        localStorageUtil.getReportValues(projectId).then((result) => {
            localReportValues = result;
            return(Api.getReportValues(projectId))
        }).then((result) => {
            const parsedResult = parseJSON(result);
            if(JSON.stringify(parsedResult) == JSON.stringify(localReportValues)) {
                this.setState({
                    values: parsedResult
                })
            } else {
                console.log("you are connected, but your local values don't match with your saved ones");
                console.log(parsedResult);
                this.setState({
                    values: localReportValues
                })
            }
        }).catch((e) => {
            //you aren't connected, use your local values
            this.setState({
                values: localReportValues
            })
        });

        //finally get your notes:
        let localNotesValues = {};
        localStorageUtil.getNotes(projectId).then((result) => {
            localNotesValues = result;
            return(Api.getNotes(projectId))
        }).then((result) => {

            console.log(result);
            if(JSON.stringify(result) == JSON.stringify(localNotesValues)) {
                this.setState({
                    notes: parseJSON(result)
                })
            } else {
                console.log("you are connected, but your notes values don't match with your saved ones");
                const resultKeys = Object.keys(result);
                const localKeys = Object.keys(localNotesValues);
                const updateKeys = [];
                for(let i=0; i<resultKeys.length; i++) {
                    if(result[resultKeys[i]] != localNotesValues[resultKeys[i]]) {
                        updateKeys.push(resultKeys[i])
                    }
                }

                for(let i=0; i<localKeys.length; i++) {
                    if(result[localKeys[i]] != localNotesValues[localKeys[i]]) {
                        updateKeys.push(localKeys[i])
                    }
                }

                for(let i=0; i<updateKeys.length; i++) {
                    localStorageUtil.storeUncommitedNotesChange(projectId, updateKeys[i]);
                }

                this.setState({
                    notes: localNotesValues
                })
            }
        }).catch((e) => {
            //you aren't connected, use your local values
            this.setState({
                notes: localNotesValues
            })
        });
    }

    componentWillUnmount() {

    };

    storingImage = (value) => {
        this.setState({
            storingImage: value
        })
    };

    setError = (error) => {
        this.setState({
            hasError: true,
            error: error
        })
    };

    refreshConnectionStatus = () => {
        return new Promise((resolve, reject) => {
            Api.checkConnection().then(() => {
                this.setState({
                    connected: true
                });
                resolve();
            }).catch(() => {
                this.setState({
                    connected: false
                });
                reject();
            })
        })
    };

    setValue = (key, id, value, useOther) => {
        const {projectId} = this.state;
        const {values, otherValues} = this.state;

        if (values[key] !== value) {
            this.setValueInState(key, value, useOther);

            let stringifiedValue = value;
            if (typeof stringifiedValue == 'object') {
                stringifiedValue = JSON.stringify(value) || "";
            }
            localStorageUtil.storeUncommitedChange(projectId, key).then(() => {
                return(serverUtil.runUpdate(projectId, key, stringifiedValue))
            }).then(() => {
                return(this.refreshConnectionStatus());
            }).catch((e) => {
                if(e && e.response && e.response.status == 403) {
                    this.setState({
                        loginMessage: true
                    })
                }
                return(this.refreshConnectionStatus());
            })
        }
    };

    setAllValuesInState(projectData) {
        this.setState({
            values: projectData
        });
    };


    setValueInState(key, value, useOther) {
        const {values, otherValues} = this.state;
        if(!useOther) {
            values[key] = value;
        } else {
            otherValues[key] = value;
        }

        this.setState({
            values: values,
            otherValues: otherValues
        });
    }

    syncProjectWithServer = () => {
        const {projectId} = this.state;
        if (localStorageUtil.hasUncommittedChanges(projectId)) {
            serverUtil.updateDatabaseToMatchLocalStorage(projectId)
            .then(() => {
                console.log("Successfully synced the database with the project.");
                this.refreshConnectionStatus();
                this.setState({
                    loginMessage: false
                })
            }).catch((e) => {
                console.log("Error occurred whiling syncing database with the project:\n" + e);
                if(e && e.response && e.response.status == 403) {
                    this.setState({
                        loginMessage: true
                    })
                }
                this.refreshConnectionStatus();
            });
        }
    };

    initUploads = (projectId) => {
        let imagesInSessionStorage = localStorageUtil.getImages(projectId);
        console.log("Images in session storage: \n" + JSON.stringify(imagesInSessionStorage));
        this.setState({
            imagesToUpload: imagesInSessionStorage
        });
    };

    setUploads = (uploads) => {
        const {projectId, imagesToUpload} = this.state;

        this.setState({
            imagesToUpload: uploads
        });
    };

    renderFieldGroupLabels = (fieldGroups) => {
        const {selectedFieldGroup} = this.state;

        return(
            fieldGroups.map((fieldGroup, index) => {
                return(
                    <div key={index} className={`tabItem shiny ${selectedFieldGroup} ${index} ${selectedFieldGroup === index && 'selected'}`}
                          onClick={() => {
                              this.setState({
                                  selectedFieldGroup: index,
                                  menuOpen: false
                              })
                          }}
                    >{fieldGroup.label}</div>
                )
            })
        )
    };

    generateReport = () => {
        console.log(this.state.values);
        this.props.history.push(`/report/${this.state.projectId}`, this.state);
    };

    returnToProjects = () => {
        this.props.history.push(`/project`);
    };

    // User logs out and it takes them back to the 
    logOut = () => {
        localStorageUtil.setUserIdToNull();
        this.props.history.push(`/`);
    };

    toggleOpenNote = () => {
        const {openNote} = this.state;
        this.setState({
            openNote: !openNote
        })
    };

    closeModal = () => {
        this.setState({
            error: "",
            isStoringImage: false,
            hasError: false
        })
    };

    saveNotes = (e) => {

        return new Promise((resolve, reject) => {
            const {formdata, projectId, selectedFieldGroup, notes} = this.state;
            const {field_groups} = formdata;
            const pagename = field_groups[selectedFieldGroup].pagename;
            const note = notes[pagename] || "";

            localStorageUtil.storeNote(projectId, pagename, note).then(() => {
                return (localStorageUtil.storeUncommitedNotesChange(projectId, pagename))
            }).then(() => {
                return (Api.saveNotes(projectId, pagename, note));
            }).then(() => {
                return (localStorageUtil.clearComitedNotesChange(projectId, pagename))
            }).then(() => {
                return (this.refreshConnectionStatus());
            }).then(() => {
                this.setState({
                    openNote: false
                });
                resolve();
            }).catch((e) => {
                return (this.refreshConnectionStatus()).then(()=> {
                    this.setState({
                        openNote: false
                    });
                    reject();
                }).catch((e) => {
                    this.setState({
                        openNote: false
                    });
                    reject();
                });
            })
        })
    };

    storeNotes = (e) => {
        const {formdata, projectId, selectedFieldGroup, notes} = this.state;
        const {company_name, field_groups} = formdata;
        const pagename = field_groups[selectedFieldGroup].pagename;

        notes[pagename] = e.currentTarget.value;

        this.setState({
            notes: notes
        })
    };

    setUsername = (e) => {
        this.setState({
            username: e.currentTarget.value
        })
    };

    setPassword = (e) => {
        this.setState({
            password: e.currentTarget.value
        })
    };

    checkLogin = () => {
        Api.getLoggedInUser().catch((e) => {
            this.loginError(e);
        })
    };

    loginError = (e) => {
        if (e && e.response && (e.response.status == 401 || e.response.status == 403 || e.response.status == 404 )) {
            console.log("Failed to log in:\n" + e);
            this.setState({
                loginMessage: true
            })
        } else {
            this.setState({
                loginMessage: false
            })
        }
    };

    openMenu = (e) => {
        const {menuOpen} = this.state;

      this.setState({
          menuOpen: !menuOpen
      })
    };

    tryLogin = () => {
        const {username, password} = this.state;
        Axios(Endpoints.getEndpoint(Endpoints.path.login), {
            method: 'post',
            withCredentials: true,
            data:{
                user: username,
                password: password
            },
        }).then((result) => {
            console.log("Logged in as user with id: " + result.data.user_id);
            localStorageUtil.setUserId(result.data.user_id);
            this.setState({
                loginMessage: false
            });

            this.syncProjectWithServer();
        }).catch((e) => {
            if(e && e.response && (e.response.status == 401 || e.response.status == 403 || e.response.status == 404 )) {
                console.log("Failed to log in:\n" + e);
                alert("Username or password incorrect.");

            } else {
                alert("Failed to log in.  Network unavailable");
                this.setState({
                    loginMessage: false
                })
            }
        });
    };

    render(){
        const {formdata, selectedFieldGroup, values, otherValues,
            projectId, imagesToUpload, connected,
            openNote, notes, isStoringImage, hasError, error, loginMessage, menuOpen} = this.state;
        const {company_name, field_groups} = formdata;
        const changes = localStorageUtil.getNumberOfChanges(projectId);
        const notesChanges = localStorageUtil.getNumberOfNotesChanges(projectId);

        return(
            <div className="pageForm">
                {
                    (loginMessage) &&
                        <div className="login-window">
                            <div className="loginMessage">
                                <div className="error-message">
                                    An internet connection is available but you are logged out. Please log in.
                                </div>
                                <div>
                                    username:
                                    <input type="text" onChange={this.setUsername}></input>
                                </div>
                                <div>
                                    password:
                                    <input type="text" onChange={this.setPassword}></input>
                                </div>
                                <div>
                                    <button className="admin-button" onClick={this.tryLogin}>login</button>
                                </div>
                            </div>
                        </div>
                }
                {
                    (isStoringImage) &&
                        <div className="blocker">
                            <div className="blockerWindow">

                                <div className="dialogFill">
                                    <div>
                                        storing image...
                                    </div>
                                </div>
                            </div>
                        </div>
                }

                {
                    (hasError) &&
                    <div className="blocker">
                        <div className="blockerWindow">

                            <div className="dialogFill">
                                <div>
                                    {error}
                                </div>
                            </div>
                            <div className="windowButtons">
                                <div className="confirmButton">
                                    <div onClick={this.closeModal}>OK</div>
                                </div>
                            </div>

                        </div>
                    </div>
                }

                <div className="header">
                    <h1>{company_name}</h1>
                    {
                        !connected &&
                        <div className="unsynched_changes">
                            <span> not connected </span>
                            {
                                (changes > 0) &&
                                <span> unsynched: {changes} </span>
                            }
                            {   (notesChanges > 0) &&
                                <span> unsynched notes: {notesChanges} </span>
                            }
                        </div>
                    }
                    {
                        connected && (changes > 0 || notesChanges > 0) &&
                        <div className="unsynched_changes">
                            <span> unsynched changes: </span>
                            {
                                (changes > 0) &&
                                <span> unsynched: {changes} </span>
                            }
                            {   (notesChanges > 0) &&
                                <span> unsynched notes: {notesChanges} </span>
                            }
                            <span className="link" onClick={this.syncProjectWithServer}>sync now</span>
                        </div>
                    }
                </div>
                <div className="innerPageContent">
                    <div className={`selectPageBarContainer ${menuOpen && 'menuOpen'}`}>
                        <div className={`closeMenuButton ${menuOpen && 'menuOpen'}`} onClick={this.openMenu}><i className="fa fa-close" /></div>
                        <div className="selectPageBar">
                            {this.renderFieldGroupLabels(field_groups)}
                            <div className="tabItem divider" />
                            <div className="tabItem shiny special" onClick={this.generateReport}><div><i className="fa fa-file"/> Generate Report</div></div>
                            <div className="tabItem shiny special" onClick={this.returnToProjects}><div><i className="fa fa-backward"/> Back to Projects</div> </div>
                            <div className="tabItem shiny special" onClick={this.logOut}><div><i className="fa fa-user"/> Log Out</div></div>
                        </div>
                    </div>
                    <div className="pageContent">
                    {
                        <div className="pageTitleContainer shiny">
                            <div className="pageTitleTitle">{field_groups[selectedFieldGroup] ? field_groups[selectedFieldGroup].label : "NONE"}</div>
                            <div className="hamburger" onClick={this.openMenu}><i className="fa fa-bars"/></div>
                            <div className="notes" onClick={this.toggleOpenNote}><i className="fa fa-sticky-note" /> NOTES</div>
                        </div>
                    }

                    {
                        field_groups.map((fieldGroup, index) => {
                            if(index === selectedFieldGroup) {
                                return (
                                    <FormPage key={index}
                                              fieldGroup={fieldGroup}
                                              values={values}
                                              otherValues={otherValues}
                                              setValue={this.setValue}
                                              projectId={projectId}
                                              setUploads={this.setUploads}
                                              imagesToUpload={imagesToUpload}
                                              connected={connected}
                                              refreshConnectionStatus={this.refreshConnectionStatus}
                                              storingImage={this.storingImage}
                                              setError={this.setError}
                                              checkLogin={this.checkLogin}
                                              loginError={this.loginError}
                                    />
                                )
                            }
                        })
                    }
                    </div>
                </div>
                { openNote &&

                    <div className="openNoteBackground">
                        <div className="openNote">
                            <div className="notesHeader">
                                <div className="notesIcon">
                                    <i className="fa fa-close" onClick={this.toggleOpenNote}/>
                                </div>
                                <div className="notesTitle">
                                    {`${field_groups[selectedFieldGroup].label} Notes`}
                                </div>
                            </div>
                            <div className="notesBody">
                                <div className="notesText">
                                    <textarea onChange={this.storeNotes} value={notes[field_groups[selectedFieldGroup].pagename] || ""}></textarea>
                                </div>
                            </div>
                            <div className="notesFooter">
                                <div className="button" onClick={this.saveNotes}>save</div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
