import localStorageUtil from './localStorageUtil';
import parseJSON from './utils';
import Api from './api';


const serverUtil = {
    updateDatabaseToMatchLocalStorage: function(projectId) {
        return new Promise((resolve, reject) => {
            Api.checkConnection()
            .then(() => {
                return (localStorageUtil.getCommittedChangesArray(projectId));
            }).then((result) => {
                return (Api.postReportValues(projectId, result));
            }).then(() => {
                return (Api.syncAllNotes(projectId));
            }).then(() => {
                console.log("Successfully updated the database to match local values.");
                localStorageUtil.clearCommittedChanges(projectId);
                resolve(true);
            }).catch((e) => {
                console.log("Failed to sync to the database.");
                reject(e);
            });
        });
    },

    runUpdate: function(projectId, key, value) {
        console.log("updating");
        return new Promise((resolve, reject) => {
            // First set the value in our localStorage
            localStorageUtil.storeReportValue(projectId, key, value).then(() => {
                // Check to make sure we have an internet connection)
                return(Api.checkConnection());
            }).then((result) => { // We are connected to the server, so we can run an update on the current changes.
                return (localStorageUtil.getCommittedChangesArray(projectId));
            }).then((result) => {
                return(Api.postReportValues(projectId, result));
            }).then((result) => {   // Successfully updated values
                return (localStorageUtil.clearCommittedChanges(projectId));
            }).then((e) => {
                resolve();
            }).catch((e) => {   // failed to update database
                localStorageUtil.storeUncommitedChange(projectId, key);
                reject(e);
            });
        });
    }
};

export default serverUtil;