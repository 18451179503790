const Endpoints = {
    host_local: `${window.location.protocol}//${window.location.hostname}:7001/api`,
    host_dev: `${window.location.protocol}//${window.location.hostname}:7001/api`,
    host_prod: `${window.location.protocol}//${window.location.hostname}:8001/api`,
    host: '',
    path: {
        login: "/login",
        logout: "/logout",
        post_image: "/image",
        post_image_direct: "/image/direct",
        get_projects: "/projects",
        get_single_project: "/projects/single",
        get_all_projects: "/projects",
        get_project_count: "/projects/get_count",
        create_project: "/projects/create_project",
        get_images: "/image/project",
        get_image: "/image",
        delete_image: "/image/delete",
        get_authorized: "/isAuth",
        get_values: "/reportvalues",
        get_values_by_key: "/reportvalues/retrieve_by_key",
        update_report_value: "/reportvalues",
        notes: "/notes",
        add_tag_to_image: "/image/tags/add",
        remove_tag_from_image: "/image/tags/remove",
        get_tags_from_image: "/image/tags/getall",
        get_tagged_images: "/image/tagged",
        edited_report: "/report/edit",
        get_logged_in_user: "/user",
        export_word_doc: "/report/export/",
        word_doc_status: "/report/export/status/",
        get_exported_word_doc: "/report/docx/",
        app_test: "/test",
        save_form_json: '/admin/json/form/',
        save_report_json: '/admin/json/form/',
        style: '/admin/css',
        save_crop: '/image/crop/',
        get_users: '/users',
        get_groups: '/groups',
        add_new_user: '/newuser',
        change_password: '/password',
        assign_project: '/projects/group',
        assign_group_user: '/user/group',
        remove_user: '/user',
        add_all_groups_to_projects: '/projects/group/assign/all',
        post_group: '/group',
        backup: '/backup',
        base64Image: '/image/base64/',
        auth_upload: '/image/auth_upload'
    },

    getEndpoint(path){
        let hostname = window.location.hostname;
        switch (hostname) {
            case "localhost":
                Endpoints.host = Endpoints.host_local;
                break;
            case "stage.poolinspection.online":
                Endpoints.host = Endpoints.host_dev;
                break;
            case "test.poolinspection.online":
                Endpoints.host = Endpoints.host_dev;
                break;
            default:
                Endpoints.host = Endpoints.host_prod;
            break;
        }

        return `${Endpoints.host}${path}`;
    }
}

Endpoints.GetAuthUpload = () => {
    console.log("ENDPOINT?: " + Endpoints.host);
    return(`${Endpoints.host}${Endpoints.path.auth_upload}`);
}

module.exports = Endpoints;
