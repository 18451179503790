/**
 * Created by bcole on 4/26/19.
 */
import React from 'react';

import './admin.css'
import serverUtil from '../Utils/serverUtil';
import api from '../Utils/api'
import AdminForm from '../components/adminForm';
import AdminReport from '../components/adminReport';
import AdminStyles from '../components/adminStyles';
import UserManager from '../components/userManager';

export default class Form extends React.Component {

    componentDidMount() {

    };

    setUsers = () => {
        this.props.history.push(`/admin/user`, this.state);
    };

    setForm = (whatJson) => {
        this.props.history.push(`/admin/form/${whatJson}`, this.state);
    };

    setReport = (whatJson) => {
        this.props.history.push(`/admin/report/${whatJson}`, this.state);
    };

    setStyle = () => {
        this.props.history.push(`/admin/style`, this.state);
    };

    backToProjects = () => {
        this.props.history.push(`/project`, this.state);
    };

    backupDatabase = () => {
        api.backupDatabase().then(() => {
            alert("database backed up successfully");
        }).catch((e) => {
            alert("error backing up database");
        })
    };

    render() {

        let path = this.props.match.params.path;
        let component = this.props.match.params.component;

        return(
            <div className="pageAdmin">
                <div className="title-bar">
                    <div className={`admin-button`} onClick={this.backToProjects}>
                        <i className="fa fa-backward" /> BACK TO PROJECTS
                    </div>

                    <div className={`admin-button ${path=='user' && 'selected'}`} onClick={this.setUsers}>Users</div>
                    <div className={`admin-button ${path=='form' && 'selected'}`} onClick={() => {this.setForm("formdata.json")}}>Form Template</div>
                    <div className={`admin-button ${path=='report' && 'selected'}`} onClick={() => this.setReport("report.json")}>Report Template</div>
                    <div className={`admin-button ${path=='style' && 'selected'}`} onClick={this.setStyle}>Report Styles</div>
                    <div className="admin-button red" onClick={this.backupDatabase}>Backup Database Now</div>

                </div>
                {
                    path=='user' &&
                        <UserManager path={path} component={component} history={this.props.history} />
                }
                {
                    path=='form' &&
                        <AdminForm path={path} component={component} history={this.props.history} />
                }
                {
                    path=='report' &&
                        <AdminReport path={path} component={component} history={this.props.history} />
                }
                {
                    path=='style' &&
                        <AdminStyles path={path} component={component} history={this.props.history} />
                }
                {
                    path !='user' && path !='form' && path != 'report' && path !='style' &&
                        <div className="jsonGroup">
                            Administration Home.  Set up users, change the form, or report rendering details here.
                        </div>
                }
            </div>
        )
    }
}