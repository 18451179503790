/**
 * Created by bcole on 7/18/18.
 */
import React from 'react';

export default class CheckboxWidget extends React.Component {
    render() {

        const {data, label, setValue, value, otherOption, id, filledValue, otherFilledValue} = this.props;
        let values = value || [];

        // NOTE: filledValue is always stored in the state as a csv.

        // Before running render(), convert filledValue into an array and store in processedFilledValue
        // which will be used in render()
        let processedFilledValue = filledValue.slice();  //store a copy
        
        if (filledValue === "") {
            processedFilledValue = [];
        } else if (!Array.isArray(filledValue)) {
            processedFilledValue = filledValue.split(',');
        }

        return(
            <div className="inputField">
                {label}
                {
                    // Iterate through each checkbox item
                    data.map((option, index) => {
                        let checked = false;
                        if(processedFilledValue) {
                            checked = processedFilledValue.indexOf(option.value) >= 0;
                        }

                        return (
                            <div key={index} className="checkboxWidget">
                                <input type="checkbox"
                                       id={`${id}_${option.value}`}
                                       name={`${option.value}`}
                                       checked={checked}
                                       onChange={(e) => {

                                    // Check to see if the checked item is already in our filled values, and if it isn't
                                    // then add it to our filled values.
                                    if(e.target.checked) {
                                        if(processedFilledValue.indexOf(option.value) < 0) {
                                            processedFilledValue.push(option.value);
                                        }
                                    // If it's not checked, then remove it from the filledValues array
                                    } else {
                                        const index = processedFilledValue.indexOf(option.value);
                                        if(index >= 0) {
                                            processedFilledValue.splice(index, 1);
                                        }
                                    }
                                    setValue(processedFilledValue, id);
                                }
                                } />
                                <label htmlFor={`${id}_${option.value}`}>
                                    <div className="checkbox-label" style={{position: "absolute", top: 0, left: 40}}>
                                        {option.key}
                                    </div>
                                </label>
                            </div>
                        )
                    })
                }
                {
                    values.indexOf("!") >=0 && otherOption &&
                    <div>
                        <input type="text" onChange={(e) => setValue(value, id, true)} />
                    </div>
                }
            </div>
        )
    }
}