/**
 * Created by bcole on 8/7/18.
 */
import React from 'react';
import { Route, Redirect } from "react-router-dom";
import Axios from 'axios';
import Endpoints from './endpoints/endpoints';
import localStorageUtil from './Utils/localStorageUtil';

export default class PrivateRoute extends React.Component {

    constructor() {
        super();

        this.state = {
            authOk: false,
            authReached: false,
            offline: false,
        }
    }

    componentDidMount(){

        this.testAuth().then((result) => {
            this.setState({
                authOk: result,
                authReached: true
            })
        }).catch((status) => {
            //are we offline?
            switch(status){
                case -1:
                    this.setState({
                        offline: true,
                    });
                    break;
                case 404:
                    this.setState({
                        authOk: false,
                        offline: false,
                        authReached: true
                    });
                    break;
                case 500:
                    this.setState({
                        authOk: false,
                        offline: false,
                        authReached: true
                    });
                    break;
                case 401:
                    this.setState({
                        authOk: false,
                        offline: false,
                        authReached: true
                    });
                    break;
            }
        })
    }

    render() {
        const {component: Component, ...rest} = this.props;
        const {authReached, authOk, offline} = this.state;
        console.log(this.props);

        if ((authReached && authOk) || offline) {
            return (
                <Route {...rest} component={Component} />
            )
        } else if (authReached && !authOk) {
            return (
                <Route {...rest} render={(props) => (
                    <Redirect to='/login' push={true}/>
                )}/>
        )

        } else {
            return (<div>...</div>);
        }
    }

    testAuth = () => {
        return new Promise((resolve, reject) => {
            // First check if localStorage has the user logged in.
            if (localStorageUtil.checkIfLoggedIn() === true) {
                resolve(true);
            }
            // If not, have the user log in through the server.
            Axios(Endpoints.getEndpoint(Endpoints.path.get_authorized), {
                method: 'get',
                withCredentials: true,
                headers: {
                    'Cache-Control': 'no-cache'
                }
            }).then((result) => {
                resolve(true)
            }).catch((e) => {
                if(e.response) {
                    reject(e.response.status);
                } else {
                    reject(-1);
                }
            })
        })
    }
}