import serverUtil from './serverUtil';
import {parseJSON} from './utils';


const KEY_USER_ID = "user_id";
const KEY_VALUES = "values";
const KEY_PROJECTS = "projects";
const KEY_FORMS = "forms";
const KEY_NOTES = "notes";

const KEY_CHANGES = "changes";
const KEY_NOTES_CHANGES = "notes_changes";
const KEY_IMAGES = "images";

// Local Storage Utilities
const localStorageUtil = {
    // User Utility functions
    getUserId: function () {
        let user_id = localStorage.getItem(KEY_USER_ID);
        return user_id;
    },

    setUserId: function (user_id) {
        localStorage.setItem(KEY_USER_ID, user_id);
        sessionStorage.setItem(KEY_USER_ID, user_id);
    },

    resetSessionUserIdIfNecessary: function () {
        if (sessionStorage.getItem(KEY_USER_ID) === null) {
            sessionStorage.setItem(KEY_USER_ID, this.getUserId());
        }
    },

    setUserIdToNull: function () {
        localStorage.removeItem(KEY_USER_ID);
        sessionStorage.removeItem(KEY_USER_ID);
    },

    checkIfLoggedIn: function () {
        if (localStorage.getItem(KEY_USER_ID) === null) {
            return false;
        } else {
            return true;
        }
    },

    hasUncommittedChanges: function (projectId) {
        const changes = localStorage.getItem(KEY_CHANGES) || "{}";
        const changesObj = JSON.parse(changes);
        const changes_project = changesObj[projectId] || [];

        const notes_changes = localStorage.getItem(KEY_NOTES_CHANGES) || "{}";
        const notes_changesObj = JSON.parse(notes_changes);
        const notes_changes_project = notes_changesObj[projectId] || [];

        return (changes_project.length > 0 || notes_changes_project.length > 0);
    },

    getReportValues: function (projectId) {
        return new Promise((resolve, reject) => {
            const values = localStorage.getItem(KEY_VALUES) || "{}";
            const valuesObj = JSON.parse(values);
            const values_for_user = valuesObj[projectId] || {};
            const parsed = parseJSON(values_for_user);

            resolve(parsed);
        });
    },

    storeReportValues: function (projectId, data) {
        return new Promise((resolve, reject) => {
            const values = localStorage.getItem(KEY_VALUES) || "{}";
            const valuesObj = JSON.parse(values);

            valuesObj[projectId] = data;
            const jsonstr = JSON.stringify(valuesObj);

            try {
                localStorage.setItem(KEY_VALUES, jsonstr);
                resolve()
            } catch (e) {
                reject()
            }
        })
    },

    storeReportValue: function (projectId, key, value) {
        return new Promise((resolve, reject) => {
            const forms = localStorage.getItem(KEY_VALUES) || "{}";
            const dataObj = JSON.parse(forms);

            const data_project = dataObj[projectId] || {};
            data_project[key] = value;
            dataObj[projectId] = data_project;

            const jsonstr = JSON.stringify(dataObj);

            try {
                localStorage.setItem(KEY_VALUES, jsonstr);
                resolve()
            } catch (e) {
                reject()
            }
        })
    },

    storeDownloadedProject: function (projectData) {
        return new Promise((resolve, reject) => {
            const allProjects = localStorage.getItem(KEY_PROJECTS) || "{}";
            const allProjectsObj = JSON.parse(allProjects);
            const uid = this.getUserId();
            const projectList = allProjectsObj[uid] || {};

            projectData.downloadedOn = new Date().getTime();
            projectList[projectData.project_id] = projectData;
            allProjectsObj[uid] = projectList;
            const allProjectsStr = JSON.stringify(allProjectsObj);

            try {
                localStorage.setItem(KEY_PROJECTS, allProjectsStr);
                resolve();
            } catch (e) {
                reject();
            }
        })
    },

    getDownloadedProjects: function () {
        const projects = localStorage.getItem(KEY_PROJECTS) || "{}";
        const projectObj = JSON.parse(projects);
        const uid = this.getUserId();

        const values = projectObj[uid] || {};
        const keys = Object.keys(values);

        const projectArr = [];
        for (var i = 0; i < keys.length; i++) {
            projectArr.push(values[keys[i]]);
        }

        //then sort them
        projectArr.sort((a, b) => {
            return (a.downloadedOn > b.downloadedOn)
        });

        //return an empty array if we don't have any project info stored
        return (projectArr);
    },

    isStoredLocally: function (project_id) {
        const projects = localStorage.getItem(KEY_PROJECTS) || "{}";
        const projectObj = JSON.parse(projects);
        const uid = this.getUserId();
        const projectsForUser = projectObj[uid] || {};

        return (projectsForUser[project_id] != null);
    },

    storeNotes: function (projectId, notesData) {
        return new Promise((resolve, reject) => {
            const allNotes = localStorage.getItem(KEY_NOTES) || "{}";
            const allNotesObj = JSON.parse(allNotes);
            allNotesObj[projectId] = notesData;

            const allNotesStr = JSON.stringify(allNotesObj);

            try {
                localStorage.setItem(KEY_NOTES, allNotesStr);
                resolve();
            } catch (e) {
                reject();
            }
        })
    },

    getNotes: function(projectId) {
        return new Promise((resolve, reject) => {
            const allNotes = localStorage.getItem(KEY_NOTES) || "{}";
            const allNotesObj = JSON.parse(allNotes);
            const project_notes = allNotesObj[projectId];
            resolve(project_notes);
        })
    },

    storeNote: function (projectId, noteKey, note) {
        return new Promise((resolve, reject) => {
            const allNotes = localStorage.getItem(KEY_NOTES) || "{}";
            const allNotesObj = JSON.parse(allNotes);
            const project_notes = allNotesObj[projectId] || {};
            project_notes[noteKey] = note;
            allNotesObj[projectId] = project_notes;

            const allNotesStr = JSON.stringify(allNotesObj);
            console.log(allNotesObj);

            try {
                localStorage.setItem(KEY_NOTES, allNotesStr);
                resolve();
            } catch (e) {
                reject();
            }
        })
    },

    storeFormData: function (projectId, formData) {
        return new Promise((resolve, reject) => {
            const allForms = localStorage.getItem(KEY_FORMS) || "{}";
            const allFormsObj = JSON.parse(allForms);
            allFormsObj[projectId] = formData;

            const allFormsStr = JSON.stringify(allFormsObj);

            try {
                localStorage.setItem(KEY_FORMS, allFormsStr);
                resolve();
            } catch (e) {
                reject();
            }
        })
    },

    getFormData: function (projectId) {
        return new Promise((resolve, reject) => {
            const allForms = localStorage.getItem(KEY_FORMS) || "{}";
            const allFormsObj = JSON.parse(allForms);
            resolve (allFormsObj[projectId]);
        })
    },

    // Image storage
    storeImage: function (projectId, originalname, mimetype, base64Str) {
        return new Promise((resolve, reject) => {
            const allImages = sessionStorage.getItem(KEY_IMAGES) || "{}";
            let allImagesObj = JSON.parse(allImages);
            let images_project = allImagesObj[projectId] || {};
            let images = images_project.images || [];

            let value = {
                "base64": base64Str,
                "originalname": originalname,
                "mimetype": mimetype
            }

            images.push(value);
            images_project.images = images;
            allImagesObj[projectId] = images_project;
            const allImagesStr = JSON.stringify(allImagesObj);

            try {
                sessionStorage.setItem(KEY_IMAGES, allImagesStr);
                resolve();
            } catch (e) {
                console.log(e);
                reject();
            }
        });
    },

    getImages: function (projectId) {
        const allImages = sessionStorage.getItem(KEY_IMAGES) || "{}";
        let allImagesObj = JSON.parse(allImages);
        let images_project = allImagesObj[projectId] || {};
        let images = images_project.images || [];

        let imagesArr = [];
        for (let i = 0; i < images.length; i++) {
            let newObj = {
                "file": this.convertDataUrlToFile(images[i].base64, images[i].originalname),
                "image": images[i].base64
            }
            imagesArr.push(newObj);
        }
        return imagesArr;
    },

    removeImage: function (projectId, index) {
        return new Promise((resolve, reject) => {
            const allImages = sessionStorage.getItem(KEY_IMAGES) || "{}";
            let allImagesObj = JSON.parse(allImages);
            let images_project = allImagesObj[projectId] || {};
            let images = images_project.images || [];

            images.splice(index, 1);
            images_project.images = images;
            allImagesObj[projectId] = images_project;
            const allImagesStr = JSON.stringify(allImagesObj);

            try {
                sessionStorage.setItem(KEY_IMAGES, allImagesStr);
                resolve();
            } catch (e) {
                reject();
            }
        });
    },

    convertDataUrlToFile: function (dataUrl, filename) {
        let arr = dataUrl.split(',');
        let mimetype = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mimetype});
    },

    storeUncommitedChange: function (projectId, key) {
        //we only need to store a list of keys that need pushed.  We can look these up.
        return new Promise((resolve, reject) => {
            const changes = localStorage.getItem(KEY_CHANGES) || "{}";
            const changesObj = JSON.parse(changes);
            const changes_project = changesObj[projectId] || [];

            //don't add this key if it's already there
            if (changes_project.indexOf(key) < 0) {
                changes_project.push(key);
                changesObj[projectId] = changes_project
                const changesStr = JSON.stringify(changesObj);
                try {
                    localStorage.setItem(KEY_CHANGES, changesStr);
                    resolve();
                } catch (e) {
                    reject();
                }
            } else {
                resolve();
            }
        })
    },

    storeUncommitedNotesChange: function (projectId, key) {
        //we only need to store a list of keys that need pushed.  We can look these up.
        return new Promise((resolve, reject) => {
            const changes = localStorage.getItem(KEY_NOTES_CHANGES) || "{}";
            const changesObj = JSON.parse(changes);
            const changes_project = changesObj[projectId] || [];

            //don't add this key if it's already there
            if (changes_project.indexOf(key) < 0) {
                changes_project.push(key);
                changesObj[projectId] = changes_project;
                const changesStr = JSON.stringify(changesObj);
                try {
                    localStorage.setItem(KEY_NOTES_CHANGES, changesStr);
                    resolve();
                } catch (e) {
                    reject();
                }
            } else {
                resolve();
            }
        })
    },

    getCommittedChangesArray: function (projectId) {
        return new Promise((resolve, reject) => {
            const changes = localStorage.getItem(KEY_CHANGES) || "{}";
            const changesObj = JSON.parse(changes);
            const changes_project = changesObj[projectId] || [];

            const forms = localStorage.getItem(KEY_VALUES) || "{}";
            const dataObj = JSON.parse(forms);
            const data_project = dataObj[projectId] || {};

            let changesArray = [];
            for (var i = 0; i < changes_project.length; i++) {
                changesArray.push({key: changes_project[i], value: data_project[changes_project[i]]})
            }

            resolve(changesArray);
        })
    },

    getNumberOfChanges: function (projectId) {
        const changes = localStorage.getItem(KEY_CHANGES) || "{}";
        const changesObj = JSON.parse(changes);
        const changes_project = changesObj[projectId] || [];

        return changes_project.length;
    },

    getNumberOfNotesChanges: function (projectId) {
        const changes = localStorage.getItem(KEY_NOTES_CHANGES) || "{}";
        const changesObj = JSON.parse(changes);
        const changes_project = changesObj[projectId] || [];

        return changes_project.length;
    },

    clearCommittedChanges: function (projectId) {
        return new Promise((resolve, reject) => {
            const changes = localStorage.getItem(KEY_CHANGES) || "{}";
            const changesObj = JSON.parse(changes);
            changesObj[projectId] = [];

            const changesStr = JSON.stringify(changesObj);

            try {
                localStorage.setItem(KEY_CHANGES, changesStr);
                resolve();
            } catch (e) {
                reject();
            }
        });
    },

    clearComitedNotesChange: function(projectId, key) {
        //we only need to store a list of keys that need pushed.  We can look these up.
        return new Promise((resolve, reject) => {
            const changes = localStorage.getItem(KEY_NOTES_CHANGES) || "{}";
            const changesObj = JSON.parse(changes);
            const changes_project = changesObj[projectId] || [];

            //don't add this key if it's already there
            const index = changes_project.indexOf(key);
            if (index >= 0) {
                changes_project.splice(index, 1);
                changesObj[projectId] = changes_project;
                const changesStr = JSON.stringify(changesObj);
                try {
                    localStorage.setItem(KEY_NOTES_CHANGES, changesStr);
                    resolve();
                } catch (e) {
                    reject();
                }
            } else {
                resolve();
            }
        })
    },

    clearAllComitedNotesChanges: function(projectId) {
        //we only need to store a list of keys that need pushed.  We can look these up.
        return new Promise((resolve, reject) => {
            const changes = localStorage.getItem(KEY_NOTES_CHANGES) || "{}";
            const changesObj = JSON.parse(changes);
            const changes_project = [];

            //don't add this key if it's already there
            changesObj[projectId] = changes_project;
            const changesStr = JSON.stringify(changesObj);
            try {
                localStorage.setItem(KEY_NOTES_CHANGES, changesStr);
                resolve();
            } catch (e) {
                reject();
            }
        })
    },

    removeProjectFromLocalStorage: function (projectId) {

        return new Promise((resolve, reject) => {
            const projects = localStorage.getItem(KEY_PROJECTS) || "{}";
            const projectObj = JSON.parse(projects);
            const uid = this.getUserId();
            const projectsForUser = projectObj[uid] || {};
            delete(projectsForUser[projectId]);
            projectObj[uid] = projectsForUser;
            const projectsStr = JSON.stringify(projectObj);
            localStorage.setItem(KEY_PROJECTS, projectsStr);


            const allForms = localStorage.getItem(KEY_FORMS) || "{}";
            const allFormsObj = JSON.parse(allForms);
            delete(allFormsObj[projectId]);
            const allFormsStr = JSON.stringify(allFormsObj);
            localStorage.setItem(KEY_FORMS, allFormsStr);


            const values = localStorage.getItem(KEY_VALUES) || "{}";
            const valuesObj = JSON.parse(values);
            delete(valuesObj[projectId]);
            const valuesStr = JSON.stringify(valuesObj);
            localStorage.setItem(KEY_VALUES, valuesStr);


            const allNotes = localStorage.getItem(KEY_NOTES) || "{}";
            const allNotesObj = JSON.parse(allNotes);
            delete(allNotesObj[projectId]);
            const allNotesStr = JSON.stringify(allNotesObj);
            localStorage.setItem(KEY_NOTES, allNotesStr);


            const changes = localStorage.getItem(KEY_CHANGES) || "{}";
            const changesObj = JSON.parse(changes);
            delete(changesObj[projectId]);
            const changesStr = JSON.stringify(changesObj);
            localStorage.setItem(KEY_CHANGES, changesStr);


            const notes_changes = localStorage.getItem(KEY_NOTES_CHANGES) || "{}";
            const notes_changesObj = JSON.parse(notes_changes);
            delete(notes_changesObj[projectId]);
            const notes_changesStr = JSON.stringify(notes_changesObj);
            localStorage.setItem(KEY_NOTES_CHANGES, notes_changesStr);

            resolve();
        })
    }
}

export default localStorageUtil;