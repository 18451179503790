/**
 * Created by bcole on 9/28/18.
 */
exports.parseJSON = function(d){
    //this isn't the prettiest, but let's parse the JSON for each object if necessary
    let objectData = Object.assign({}, d);

    Object.keys(objectData).forEach((key) => {
        let data = objectData[key];

        if(data.indexOf("[") == 0) { //lets assume you're an array
            try {
                objectData[key] = (JSON.parse(data));
            } catch (e) {
                objectData[key] = [];
            }

        } else if(data.indexOf("{") == 0) { //let's assume you're an object
            console.log(data);
            try {
                objectData[key] = (JSON.parse(data));
            } catch (e) {
                objectData[key] = {};
            }
        }
    });

    console.log(" O B J E C T D A T A ");
    console.log(objectData)
    return objectData;
};

exports.printUser = function() {
    return localStorage.getItem("user_id");
};

exports.emailIsValid = function(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
};

