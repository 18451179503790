/**
 * Created by bcole on 12/18/18.
 */
import React from 'react';
import './resizeWidget.css';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Api from '../Utils/api';
import ReactResizeDetector from 'react-resize-detector';

export default class ResizeWidget extends React.Component {

    constructor() {
        super();

        this.state = {
            base64Image: null,
            crop: {
                unit: "%",
                width: 100,
                aspect: 4 / 3
            },

            croppedCanvas: null,
            imageWidth: -1,
            imageHeight: -1,
            actualWidth: -1,
            actualHeight: -1,
            imageScale: -1,
        }
    }

    componentDidMount() {
        this.imageRef = React.createRef();
        const src = this.props.src;

        var img = new Image();
        img.src = src;
        img.onload = () => {

            this.setState({
                actualWidth: img.width,
                actualHeight: img.height
            });
        }
    }

    componentWillUpdate(nextProps, nextState) {
        const {imageWidth, actualWidth, imageScale, crop} = nextState;
        const newCrop = this.props.crop;

        if (imageWidth > 0 && actualWidth > 0 && imageScale < 0) {
            const scale = actualWidth / imageWidth;
            const inverseScale = 1 / scale;

            if (newCrop.width != 0 && newCrop.height != 0) {
                crop.x = newCrop.x * inverseScale;
                crop.y = newCrop.y * inverseScale;
                crop.width = newCrop.width * inverseScale;
                crop.height = newCrop.height * inverseScale;
                crop.unit = "px";

                this.setState({
                    imageScale: scale,
                    crop: crop
                })
            }
        }
    }

    onImageLoaded = image => {
        this.imageRef = image;

        this.setState({
            imageWidth: image.width,
            imageHeight: image.height
        });
    };

    setCrop = (crop) => {
        if (crop.width > 0 && crop.height > 0) {
            this.setState({
                crop: crop
            })
        }
    };

    saveCrop = () => {
        const {crop, imageScale} = this.state;
        const scaledCrop = {
            x: crop.x * imageScale,
            y: crop.y * imageScale,
            width: crop.width * imageScale,
            height: crop.height * imageScale,
            unit: crop.unit
        };

        this.props.saveCrop(scaledCrop);
    };

    changeAspectRatio = (e) => {
        const ratio = e.currentTarget.value;
        const {crop} = this.state;

        crop.aspect = parseFloat(ratio);
        this.setState({
            crop: crop
        })
    };

    onResize = (e) => {
        const {width, height} = this.imageRef;
        this.setState({
            imageWidth: width,
            imageHeight: height,
            imageScale: -1
        })
    };

    render() {

        const {src} = this.props;
        const {croppedCanvas, crop} = this.state;

        return(
            <div className="resizeWidget">
                { croppedCanvas != null &&
                    {croppedCanvas}
                }

                { src != null &&
                    <div>
                        <ReactResizeDetector handleWidth onResize={this.onResize}>
                            <div className="croppingTool">
                                <ReactCrop
                                    src={src}
                                    crop={crop}
                                    onChange={crop => this.setCrop(crop)}
                                    onImageLoaded={this.onImageLoaded}
                                />
                            </div>
                        </ReactResizeDetector>
                        <div className="saveBar">
                        <button className="admin-button" onClick={this.saveCrop}>Save</button>
                        <button className="admin-button" onClick={this.props.closeResizeModal}>Cancel</button>
                        <select className="admin-dropdown" onChange={this.changeAspectRatio}>
                            <option value={4/3}>4:3</option>
                            <option value={5/4}>5:4</option>
                            <option value={16/10}>16:10</option>
                            <option value={16/9}>16:9</option>
                        </select>
                    </div>
                    </div>
                    /*
                    <div className="resizeWidgetCrop" style={{backgroundImage: `url(${src})`}}>
                        <div className="croppingTool">
                        </div>
                    </div>
                    */
                }
            </div>
        )
    }
}