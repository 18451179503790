/**
 * Created by bcole on 7/17/18.
 */
import React from 'react';
import ImageWidget from './imageWidget';
import TextWidget from './textWidget';
import DateWidget from './dateWidget'
import SelectWidget from './selectWidget';
import CheckboxWidget from './checkboxWidget';

export default class FormPage extends React.Component {

    componentDidMount() {
        this.props.checkLogin();
    }

    setValue = (key, id, value, useOther) => {
        this.props.setValue(key, id, value, useOther);
    };

    checkValue = (enteredValue, splitValue) => {
        if(splitValue) {
            const vals = splitValue.split("||");
            for(let i=0; i<vals.length; i++) {
                if(enteredValue == vals[i]) {
                    return true;
                }

                if(typeof(enteredValue) == "object" && enteredValue.indexOf(vals[i]) >= 0){
                    return true;
                }
            }
        }

        return false;
    };

    // TODO: Make property level values for filledValue and otherFilledValue
    // field is the coping_condition
    // filledValue is the value chosen, example loose
    renderField = (field, filledValue, otherFilledValue, index, parentCopy, group_css_class) => {

        const {type, data, label, css_class, display, value, id, dataurl} = field;
        const {projectId, values, otherValues, imagesToUpload} = this.props;

        let shouldDisplay = false;
        for(let i=0; i<display.length; i++) {
            let displayValue = display[i];


            if(displayValue === "always") {
                shouldDisplay = true;
            } else {
                //split on the token '=';
                const splitValues = displayValue.split('=');

                //splitValues[0] is the key we should be looking at
                //splitValues[1] is the value we need to match to display this field
                const enteredValue = values[splitValues[0]] || otherValues[splitValues[0]];

                if(this.checkValue(enteredValue, splitValues[1])) {
                    shouldDisplay = true;
                } else {
                    shouldDisplay = false;
                    break;
                }
            }
        }

        if(!shouldDisplay) return null;

        switch (type) {
            case "startGroup":
                return (
                    <div className="small-12" key={index}>
                        {
                            label &&
                            <div className={`inputField groupLabel`}>
                                {label}
                            </div>
                        }
                    </div>
                );
            case "endGroup":
                return (
                    <div className={`small-12 columns ${group_css_class}`} key={index}>
                        <div className="row">
                            {parentCopy}
                        </div>
                    </div>
                );

            case "hr":
                return (
                    <hr key={index}/>
                );
            case "image_widget":
                return (
                    <div className={css_class} key={index}>
                        <ImageWidget data={data}
                                     label={label}
                                     id={id}
                                     projectId={projectId}
                                     filledValue={filledValue}
                                     otherFilledValue={otherFilledValue}
                                     setValue={(returnValue, id, useOther) => {
                                         this.setValue(value, id, returnValue, useOther)
                                     }}
                                     setUploads={this.props.setUploads}
                                     imagesToUpload={imagesToUpload}
                                     connected={this.props.connected}
                                     refreshConnectionStatus={this.props.refreshConnectionStatus}
                                     storingImage={this.props.storingImage}
                                     setError={this.props.setError}
                                     loginError={this.props.loginError}
                                     />
                    </div>
                );
            case "text":
                return (
                    <div className={css_class} key={index}>
                        <TextWidget data={data}
                                    label={label}
                                    id={id}
                                    projectId={projectId}
                                    filledValue={filledValue}
                                    otherFilledValue={otherFilledValue}
                                    setValue={(returnValue, id, useOther) => {
                                        this.setValue(value, id, returnValue, useOther)
                                    }}/>
                    </div>
                );
            case "number":
                return (
                    <div className={css_class} key={index}>
                        <TextWidget data={data}
                                    label={label}
                                    id={id}
                                    projectId={projectId}
                                    filledValue={filledValue}
                                    otherFilledValue={otherFilledValue}
                                    setValue={(returnValue, id, useOther) => {
                                        this.setValue(value, id, returnValue, useOther)
                                    }}/>
                    </div>
                );
            case "date":
                //TODO: replace with date widget
                return (
                    <div className={css_class} key={index}>
                        <DateWidget data={data}
                                    label={label}
                                    id={id}
                                    projectId={projectId}
                                    filledValue={filledValue}
                                    otherFilledValue={otherFilledValue}
                                    setValue={(returnValue, id, useOther) => {
                                        this.setValue(value, id, returnValue, useOther)
                                    }}/>
                    </div>
                );
            case "select":
                //TODO: replace with date widget
                return (
                    <div className={css_class} key={index}>
                        <SelectWidget data={data}
                                      label={label}
                                      id={id}
                                      projectId={projectId}
                                      filledValue={filledValue}
                                      otherFilledValue={otherFilledValue}
                                      dataurl={dataurl}
                                      setValue={(returnValue, id, useOther) => {
                                          this.setValue(value, id, returnValue, useOther)
                                      }}/>
                    </div>
                );
            case "checkbox":
                //TODO: replace with date widget
                return (
                    <div className={css_class} key={index}>
                        <CheckboxWidget data={data}
                                        label={label}
                                        id={id}
                                        projectId={projectId}
                                        filledValue={filledValue || []}
                                        otherFilledValue={otherFilledValue}
                                        setValue={(returnValue, id, useOther) => {
                                            this.setValue(value, id, returnValue, useOther)
                                        }}/>
                    </div>
                );
            default:
                    return (<div className="small-12">unknown</div>);
        }
    };

    render(){

        const {group} = this.props.fieldGroup;
        const {values, otherValues} = this.props;
        let parentContainer = null;
        let group_css_class = "";

        return(
            <div className="row pageContentRow">
                {group.map((field, index) => {

                    let value = values[field.value];
                    let otherValue = otherValues[field.value];

                    if(field.type == "startGroup") {
                        parentContainer = [];
                        group_css_class = field.css_class;
                        parentContainer.push(
                            this.renderField(field, value, otherValue, index)
                        );
                    } else if(field.type == "endGroup") {
                        const parentCopy = parentContainer.slice();
                        parentContainer = null;
                        return(
                            this.renderField(field, value, otherValue, index, parentCopy, group_css_class)
                        );
                    } else if(parentContainer != null) {
                      parentContainer.push(this.renderField(field, value, otherValue, index));
                    } else {
                        return (
                            this.renderField(field, value, otherValue, index)
                        );
                    }
                })}
            </div>
        )
    }
};
