/**
 * Created by bcole on 2/2/19.
 */
//
//saving items has gotten too complicated
//move more functionality to this class

import Axios from 'axios';
import Endpoints from '../endpoints/endpoints';
import localStorageUtils from './localStorageUtil';

let Api = {
    connected: true
}

Api.checkConnection = () => {
    return new Promise((resolve, reject) => {
        Axios(`${Endpoints.host}${Endpoints.path.app_test}`, {
            method: 'get',
            withCredentials: true,
        }).then(() => {
            resolve(true);
        }).catch((e) => {
            console.log("check connection failed");
            console.log(e);
            reject(e);
        });
    });
};

Api.saveNotes = (projectId, pagename, data) => {
    console.log("Saving a note: "+ Endpoints.path.notes);
    return new Promise((resolve, reject) => {
        Axios.post(`${Endpoints.host}${Endpoints.path.notes}/${projectId}/${pagename}`, {data: data}, {withCredential: true}).then((result) => {
            resolve();
        }).catch((e) => {
            Api.connected = false;
            reject();
        })
    });
};

Api.syncAllNotes = (projectId) => {
    return new Promise((resolve, reject) => {
        localStorageUtils.getNotes(projectId).then((notes) => {
            const keys = Object.keys(notes);

            Promise.all(keys.map(key => Api.saveNotes(projectId, key, notes[key]))).then((result) => {
                Api.connected = true;
                return (localStorageUtils.clearAllComitedNotesChanges(projectId));
            }).then(() => {
                resolve();
            }).catch((e) => {
                Api.connected = false;
                reject();
            });
        })
    })
};

Api.getNotes = (projectId) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${Endpoints.host}${Endpoints.path.notes}/${projectId}`, {withCredentials: true}).then((result) => {
            resolve(result.data);
        }).catch((e) => {
            Api.connected = false;
            reject();
        })
    })
};

Api.getReportValues = (projectId) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${Endpoints.host}${Endpoints.path.get_values}/${projectId}`,
            {withCredentials: true}
        ).then((result) => {
            resolve(result.data);
        }).catch((e) => {
            reject();
        })
    })
};

Api.postReportValues = (projectId, data) => {
    return new Promise((resolve, reject) => {
        Axios(`${Endpoints.host}${Endpoints.path.update_report_value}/${projectId}`, {
            method: 'post',
            data: {
                "data": data
            },
            withCredentials: true
        }).then((result) => {
            resolve();
        }).catch((e) => {
            reject(e);
        });
    });
}

Api.getProjectInfo = (projectId, userId) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${Endpoints.host}${Endpoints.path.get_single_project}`,
            { user_id: userId, project_id: projectId},
            {withCredentials: true}
        ).then((result) => {
            resolve(result.data);
        }).catch((e) => {
            reject();
        });
    })
};

Api.getFormData = () => {
    const data = {};
    return new Promise((resolve, reject) => {
        Api.getJSONForm('formdata.json').then((result) => {

            data.company_name = result.company_name;
            const field_groups = result.field_groups;

            return (Api.getFieldGroups(field_groups || []))

        }).then((results) => {
            let formdata = [];
            for(let i=0; i<results.length; i++) {
                formdata[results[i].order] = results[i];
            }

            data.field_groups = formdata;
            resolve(data);

        }).catch((e) => {
            reject(e);
        });
    });
};

Api.getFormElementsByKeys = () => {
    const data = {};
    return new Promise((resolve, reject) => {
        Api.getJSONForm('formdata.json').then((result) => {

            data.company_name = result.company_name;
            const field_groups = result.field_groups;

            return (Api.getFieldGroups(field_groups || []))

        }).then((results) => {
            let formdata = [];
            for(let i=0; i<results.length; i++) {
                for(let k=0; k<results[i].group.length; k++) {
                    const element = results[i].group[k];
                    formdata[element.value] = element;
                }
            }

            data.field_groups = formdata;
            resolve(data);

        }).catch((e) => {
            reject(e);
        });
    });
};

Api.getFieldGroups = (field_groups) => {
    return new Promise((resolve, reject) => {
        const fg = [];

        for (let i = 0; i < field_groups.length; i++) {
            fg.push(new Promise((resolve_inner, reject_inner) => {
                Axios.get(`${Endpoints.host}${Endpoints.path.save_report_json}${field_groups[i]}`, {withCredentials: true}).then((result) => {
                    resolve_inner(result.data);
                }).catch((e) => {
                    reject_inner();
                })
            }));
        }

        Axios.all(fg).then((results) => {
            resolve(results);
        })
    });
};

Api.getImages = (projectId) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${Endpoints.getEndpoint(Endpoints.path.get_images)}/${projectId}`, {
            withCredentials: true
        }).then((result) => {
            resolve(result);
        }).catch((e) => {
            reject();
        })
    })
};

Api.getProjects = (searchCriteria, pageSize, currentPage) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${Endpoints.host}${Endpoints.path.get_projects}/${pageSize}/${currentPage}`, {search: searchCriteria}, {withCredentials: true}).then((result) => {
            resolve(result);
        }).catch((e) => {
            reject(e);
        });
    });
};

Api.getNumberOfProjects = (searchCriteria) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${Endpoints.host}${Endpoints.path.get_project_count}`, {search: searchCriteria}, {withCredentials: true}).then((result) => {
            resolve(result);
        }).catch((e) => {
            reject(e);
        });
    })
};

Api.getReport = () => {
    return new Promise((resolve, reject) => {
        Axios.get(`${Endpoints.host}${Endpoints.path.save_report_json}report.json`, {withCredentials: true}).then((result) => {
            const pages = result.data.pages;
            return (Api.getReportPages(pages || []));

        }).then((results) => {

            //sort the resule
            let pages = [];
            const sortedResults = results.sort((a, b) => {
                return(a.order - b.order)
            });

            for(let i=0; i<sortedResults.length; i++) {
                pages = pages.concat(sortedResults[i].page);
            }
            resolve(pages);

        }).catch((e) => {
            reject();
        });
    })
};

Api.getReportPages = (pages) => {
    return new Promise((resolve, reject) => {
        const fg = [];

        for (let i = 0; i < pages.length; i++) {
            fg.push(new Promise((resolve_inner, reject_inner) => {
                Axios.get(`${Endpoints.host}${Endpoints.path.save_report_json}${pages[i]}`, {withCredentials: true}).then((result) => {
                    resolve_inner(result.data);
                }).catch((e) => {
                    reject_inner();
                })
            }));
        }

        Axios.all(fg).then((results) => {
            resolve(results);
        })
    });
};

Api.getOptionsFrom = (dataurl) => {
    return new Promise((resolve, reject) => {
        Axios.get(dataurl).then((result) => {
            resolve(result.data)
        }).catch((e) => {
            reject();
        })
    })
}

Api.getLoggedInUser = () => {
    return new Promise((resolve, reject) => {
        Axios.get(`${Endpoints.host}${Endpoints.path.get_logged_in_user}`, {withCredentials: true}).then((result) => {
            resolve(result.data);
        }).catch((e) => {
            reject(e);
        })
    })
}

Api.exportWord = (projectId, html) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${Endpoints.host}${Endpoints.path.export_word_doc}${projectId}`,
            {html: html},
            {withCredentials: true}).then((result) => {
            resolve(result.data);
        }).catch((e) => {
            reject(e);
        })
    })
};

Api.getJSONForm = (file) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${Endpoints.host}${Endpoints.path.save_form_json}${file}`, {withCredentials: true}).then((result) => {
            resolve(result.data);
        }).catch((e) => {
            reject(e);
        })
    });
};

Api.saveFormJSON = (file, json) => {
    let jsonText = JSON.stringify(json);
    return new Promise((resolve, reject) => {
        Axios.post(`${Endpoints.host}${Endpoints.path.save_form_json}${file}`, {json: jsonText}, {withCredentials: true}).then((result) => {
            resolve();
        }).catch((e) => {
            reject();
        })
    })
};

Api.saveCrop = (crop, file) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${Endpoints.host}${Endpoints.path.save_crop}${file}`, {crop: crop}, {withCredentials: true}).then((result) => {
            resolve();
        }).catch((e) => {
            reject();
        })
    })
};

Api.saveHtml = (projectId, savedHTML) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${Endpoints.host}${Endpoints.path.edited_report}/${projectId}`, {edits: savedHTML}, {withCredentials: true}).then((result) => {
            resolve();
        }).catch((e) => {
            console.log(e);
            reject();
        })
    })
};

Api.getGroups = () => {
    return new Promise((resolve, reject) => {
        Axios.get(`${Endpoints.host}${Endpoints.path.get_groups}`, {withCredentials: true}).then((result) => {
            const groups = result.data;

            resolve(groups);
        }).catch((e) => {
            reject();
        })
    })
};

Api.getUsers = () => {
    return new Promise((resolve, reject) => {
        Axios.get(`${Endpoints.host}${Endpoints.path.get_users}`, {withCredentials: true}).then((result) => {
            const users = result.data;

            //split the groups
            for(let i=0; i<users.length; i++) {
                if(users[i].group_id) {
                    users[i].groups = users[i].group_id.split(",");
                } else {
                    users[i].groups = [];
                }
            }

            resolve(users);
        }).catch((e) => {
            reject();
        })
    })
};

Api.addNewUser = (username, email) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${Endpoints.host}${Endpoints.path.add_new_user}`, {username: username, email: email}, {withCredentials: true}).then((result) => {
            if(result.status == 200) {
                resolve()
            } else {
                reject(result.status);
            }
        }).catch((e) => {
            reject(999);
        })
    })
};

Api.changePassword = (username, token, password) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${Endpoints.host}${Endpoints.path.change_password}`, {username: username, token: token, password: password}, {withCredentials: true}).then((result) => {
            if(result.status == 200) {
                resolve()
            } else {
                reject(result.status)
            }
        }).catch((e) => {
            reject();
        })
    });
};

Api.assignGroupToProject = (group_id, project_id) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${Endpoints.host}${Endpoints.path.assign_project}`, {group_id: group_id, project_id: project_id}, {withCredentials: true}).then((result) => {
            if(result.status == 200) {
                resolve()
            } else {
                reject(result.status)
            }
        }).catch((e) => {
            reject();
        })
    });
};

Api.removeGroupFromProject = (group_id, project_id) => {
    return new Promise((resolve, reject) => {
        Axios.delete(`${Endpoints.host}${Endpoints.path.assign_project}/${group_id}/${project_id}`, {withCredentials: true}).then((result) => {
            if(result.status == 200) {
                resolve()
            } else {
                reject(result.status)
            }
        }).catch((e) => {
            reject();
        })
    });
};

Api.assignGroupToUser = (user_id, group_id) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${Endpoints.host}${Endpoints.path.assign_group_user}`, {user_id: user_id, group_id: group_id}, {withCredentials: true}).then((result) => {
            if(result.status == 200) {
                resolve()
            } else {
                reject(result.status)
            }
        }).catch((e) => {
            reject();
        })
    });
};

Api.removeUserFromGroup = (user_id, group_id) => {
    return new Promise((resolve, reject) => {
        Axios.delete(`${Endpoints.host}${Endpoints.path.assign_group_user}/${user_id}/${group_id}`, {withCredentials: true}).then((result) => {
            if(result.status == 200) {
                resolve()
            } else {
                reject(result.status)
            }
        }).catch((e) => {
            reject();
        })
    });
};

Api.removeUser = (user_id) => {
    return new Promise((resolve, reject) => {
        Axios.delete(`${Endpoints.host}${Endpoints.path.remove_user}/${user_id}`, {withCredentials: true}).then((result) => {
            if(result.status == 200) {
                resolve()
            } else {
                reject(result.status)
            }
        }).catch((e) => {
            reject();
        })
    });
};

Api.addAllGroupsToProjects = () => {
    return new Promise((resolve, reject) => {
        Axios.get(`${Endpoints.host}${Endpoints.path.add_all_groups_to_projects}`, {withCredentials: true}).then((result) => {
            if(result.status == 200) {
                resolve()
            } else {
                reject(result.status)
            }
        }).catch((e) => {
            reject();
        })
    });
};

Api.addNewGroup = (groupName) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${Endpoints.host}${Endpoints.path.post_group}`, {group_name: groupName}, {withCredentials: true}).then((result) => {
            if(result.status == 200) {
                resolve(result)
            } else {
                reject(result.status)
            }
        }).catch((e) => {
            reject();
        })
    });
};

Api.deleteGroup = (groupId) => {
    return new Promise((resolve, reject) => {
        Axios.delete(`${Endpoints.host}${Endpoints.path.post_group}/${groupId}`, {withCredentials: true}).then((result) => {
            if(result.status == 200) {
                resolve()
            } else {
                reject(result.status)
            }
        }).catch((e) => {
            reject();
        })
    });
};

Api.backupDatabase = () => {
    return new Promise((resolve, reject) => {
        Axios.get(`${Endpoints.host}${Endpoints.path.backup}`, {withCredentials: true}).then((result) => {
            if(result.status == 200) {
                resolve()
            } else {
                reject(result.status)
            }
        }).catch((e) => {
            reject();
        })
    });
};

Api.getBase64ImageData = (imageId, x, y, w, h) => {

    if(w > 0 && h > 0) {

        return new Promise((resolve, reject) => {
            Axios.get(`${Endpoints.host}${Endpoints.path.base64Image}${imageId}/${x}/${y}/${w}/${h}`, {withCredentials: true}).then((result) => {
                if (result.status == 200) {
                    resolve(result.data)
                } else {
                    reject(result.status)
                }
            }).catch((e) => {
                reject();
            })
        });

    } else {

        return new Promise((resolve, reject) => {
            Axios.get(`${Endpoints.host}${Endpoints.path.base64Image}${imageId}`, {withCredentials: true}).then((result) => {
                if (result.status == 200) {
                    resolve(result.data)
                } else {
                    reject(result.status)
                }
            }).catch((e) => {
                reject();
            })
        });
    }
};

export default Api;
