import React from 'react';
import Axios from 'axios';
import Endpoints from '../endpoints/endpoints';
import './project.css'
import PageButtonsWidget from '../components/pageButtonsWidget';
import {parseJSON} from '../Utils/utils';
import localStorageUtil from '../Utils/localStorageUtil';
import serverUtil from '../Utils/serverUtil';
import Api from '../Utils/api';

export default class Project extends React.Component {
/*
    // The projects need a new field for last_updated.
    Work on pagination tomorrow. Sort by the last updated, then limit number of responses.
    Figure it out tomorrow
*/

    constructor() {
        super();

        this.state = {
            pageSize: 32,                    // Set the page size here. It will remain consistent.
            projectList:[],
            allProjects: [],
            projectCount: 0,
            newProjectWindowOpen: false,
            projectName: "",
            client: "",
            addressStreet: "",
            addressCity: "",
            addressState: "",
            addressZip: "",
            description: "",
            currentPage: 0,
            numPages: 0,
            connected: true,
            downloadingProject: false,
            downloadProjectPercent: 0,
            confirmRemoveProject: false,
            removeProject: false,
            removeProjectPercent: 0,
            projectToDelete: -1,
            username: "user",
            displayOverwriteMessage: false,
            projectToOverwrite: -1,
            searchCriteria: "",
            hasUnsavedChanges: false,
            groups: [],
            groupMap: {},
            group: null,
            assignGroupsModal: false,
            projectToModify: -1
        }
    }

    componentDidMount() {
        localStorageUtil.resetSessionUserIdIfNecessary();
        let {pageSize, currentPage} = this.state;

        this.loadAllProjectDetailsFromServer("", pageSize, currentPage);
        this.loadGroups();

        // Event listener to detect an escape press, which will close the new project modal
        document.addEventListener("keydown", this.escapePress, false);
    }

    componentWillUnmount() {
        // Event listener removed after unmount
        document.removeEventListener("keydown", this.escapePress, false);
    }

    loadGroups = () => {
        Api.getGroups().then((groups) => {
            const groupMap = {};

            for(let i=0; i<groups.length; i++) {
                groupMap[groups[i].group_id] = groups[i];
            }

            this.setState({
                groups: groups,
                groupMap: groupMap
            })
        })
    };

    loadAllProjectDetailsFromServer = (searchCriteria, pageSize, currentPage) => {

        let numberProjects = 0;

        this.checkConnection().then(() => {
            return (Api.getLoggedInUser());
        }).then((result) => {
            this.setState({
                username: result.username
            });

            return Api.getNumberOfProjects(searchCriteria);

        }).then((result) => {
            //hold the total number of projects
            numberProjects = result.data.length;
            return Api.getProjects(searchCriteria, pageSize, currentPage);
        }).then((result) => {
                let projectList = result.data.results;

                this.setState({
                    projectList: projectList,
                    connected: true,
                    projectCount: numberProjects,
                    numPages: Math.ceil(numberProjects/pageSize),
                    currentPage: currentPage
                });
        }).catch((e) => {
            if(e && e.response && (e.response.status == 401 || e.response.status == 403)) {
                localStorageUtil.setUserIdToNull();
                this.props.history.push(`/`);
            } else {
                // Assume no internet connection, so set project details to display from localStorage.
                let projectList = localStorageUtil.getDownloadedProjects();
                console.log(projectList);
                this.setState({
                    projectList: projectList,
                    connected: false,
                    projectCount: projectList.length,
                    numPages: Math.ceil(projectList.length/pageSize),
                    currentPage: currentPage
                });
                console.log(e);
            }
        });
    };

    isStoredLocally = (projectId) => {
        return localStorageUtil.isStoredLocally(projectId);
    };

    /**
     * Retrivethe the projects for the current page.
     */
    changeCurrentPage = (newPageNum) => {
        const {searchCriteria, pageSize, numPages} = this.state;

        console.log("change page: "+newPageNum);
        console.log("pageSize: " + pageSize +", numPages: "+numPages);

        if (newPageNum < numPages && newPageNum >= 0) {
            this.loadAllProjectDetailsFromServer(searchCriteria, pageSize, newPageNum);
        }
    };

    // escapePress will close the new project window if the user has that modal open
    escapePress = (e) => {
        const {newProjectWindowOpen} = this.state;
        if (e.keyCode === 27 && newProjectWindowOpen) {
            this.closeNewProjectWindow();
        }
    };

    // Close the new project window
    closeNewProjectWindow = () => {
        this.updateConnectionStatus();
        this.setState ({
            newProjectWindowOpen: false,
            projectName: "",
            client: "",
            addressStreet: "",
            addressCity: "",
            addressState: "",
            addressZip: "",
            description: ""
        })
    };

    openNewProjectWindow = () => {
        this.updateConnectionStatus();
        this.setState ({
            newProjectWindowOpen: true
        })
    };

    createNewProject = () => {
        const {projectName, client, addressStreet, addressCity, addressState, addressZip, description, group, searchCriteria, pageSize, currentPage} = this.state;
        console.log("Running createNewProject");

        if(group <= 0) {
            alert("You must assign project to valid group");
            return;
        }

        //todo: put this in the API
        Axios(`${Endpoints.host}${Endpoints.path.create_project}`, {
            method: 'post',
            withCredentials: true,
            data: {
                user_id: localStorageUtil.getUserId(),
                data: [
                    {
                        "client_name": client,
                        "street": addressStreet,
                        "city": addressCity,
                        "state": addressState,
                        "zip": addressZip,

                        "project_name": projectName,
                        "project_description": description,
                        "group_id": group
                    }
                ]
            }
        })
        .then((result) => {
            console.log("Successfully created project");
            this.closeNewProjectWindow();
            this.loadAllProjectDetailsFromServer(searchCriteria, pageSize, currentPage);

            //let currentDatetime = Date.now();
            //localStorageUtil.setLastSyncDatetime(result.data.id, currentDatetime);
        })
        .catch((e) => {
            console.log("Error occurred while creating project:\n" + e);
        });
    }

    // FORM SETTING FIELDS
    // The following "set" functions will set the state for the value entered in each field
    // of the new project window
    setProjectName = (e) => {
        this.setState({
            projectName: e.currentTarget.value
        });
    };

    setClient = (e) => {
        this.setState({
            client: e.currentTarget.value
        });
    };

    setAddressStreet = (e) => {
        this.setState({
            addressStreet: e.currentTarget.value
        });
    };

    setAddressCity = (e) => {
        this.setState({
            addressCity: e.currentTarget.value
        });
    };

    setAddressState = (e) => {
        this.setState({
            addressState: e.currentTarget.value
        });
    };

    setAddressZip = (e) => {
        this.setState({
            addressZip: e.currentTarget.value
        });
    };

    setDescription = (e) => {
        this.setState({
            description: e.currentTarget.value
        });
    };

    // Open the project upon being selected.
    gotoProject = (id, hasChanges) => {

        this.setState({
            projectToOverwrite: id
        });

        if(localStorageUtil.isStoredLocally(id)) {
            Api.checkConnection().then(() => {

                if(hasChanges) {
                    this.pushLocalChanges(id);
                } else {
                    this.compareLocalToRemote(id);
                }

            }).catch((e) => {
                //if we don't have a network connection
                //don't worry about it.  Just go there.
                this.props.history.push(`/project/${id}`);
            })
        } else {
            this.downloadProjectValues(id).then(() => {
                this.props.history.push(`/project/${id}`);
            })
        }
    };

    pushLocalChanges(id) {
        this.setState({
            hasUnsavedChanges: true
        })
    }

    confirmDownloadAndOpenProject(id) {
        this.downloadProjectValues(id).then(() => {
            this.props.history.push(`/project/${id}`);
        }).catch(() => {
            //we don't have a connection but we're stored locally.
            this.props.history.push(`/project/${id}`);
        })
    }

    removeFromLocalStorage = (projectId) => {
        this.setState({
            confirmRemoveProject: true,
            projectToDelete: projectId
        })
    };

    removeDownloadProjectWindow = (id) => {
        this.setState({
            displayOverwriteMessage: false
        });

        this.props.history.push(`/project/${id}`);
    };

    syncProjectWithServer = (projectId) => {
        return new Promise((resolve, reject) => {
            if (localStorageUtil.hasUncommittedChanges(projectId)) {
                serverUtil.updateDatabaseToMatchLocalStorage(projectId)
                    .then(() => {
                        console.log("Successfully synced the database with the project.");
                        resolve(1);
                    }).catch((e) => {
                    console.log("Error occurred whiling syncing database with the project:\n" + e);
                    reject(e);
                });
            } else {
                console.log("nothing to sync");
                resolve(1);
            }
        });
    };

    confirmPushUnsavedChanges = (id) => {
        this.syncProjectWithServer(id).then(() => {
            alert("Sync'd changes. Click again on project to edit.");
            this.setState({
                hasUnsavedChanges: false
            })
        }).catch((e) => {
            if (e && e.response && e.response.status == 403) {
                alert("Session expired or user logged out.  Please log back in.");
                this.props.history.push(`/login`);
            } else {
                alert("Error saving.  You may not be connected to the internet");
            }
        });
    };

    removeUnsavedChangesWindow = (id) => {
        this.setState({
            hasUnsavedChanges: false
        });

        //try going to the project but ignore the changes now
        this.gotoProject(id, false);
    };

    compareLocalToRemote = (id) => {

        let remoteValues = "";
        Api.getReportValues(id).then((results) => {
            remoteValues = results;
            return(localStorageUtil.getReportValues(id));
        }).then((results) => {
            const resultsString = JSON.stringify(parseJSON(results));
            const remoteValuesString = JSON.stringify(parseJSON(remoteValues));


            if(resultsString == remoteValuesString) {
                this.props.history.push(`/project/${id}`);
            } else {
                this.setState({
                    displayOverwriteMessage: true
                })
            }
        }).catch(() => {
            console.log("error");
            //we couldn't get this for some reason.
            //should log this
            this.setState({
                displayOverwriteMessage: true
            })
        });
    };

    // Download the project values from the server
    downloadProjectValues = (projectId) => {

        return new Promise((resolve, reject) => {
            this.setState({
                downloadingProject: true,
                downloadProjectPercent: 0
            });

            this.checkConnection().then(() => {
                //get your project info
                this.setState({
                    downloadProjectPercent: 10
                });

                return (Api.getProjectInfo(projectId, localStorageUtil.getUserId()));
            }).then((result) => {
                //then save them per user
                this.setState({
                    downloadProjectPercent: 20
                });

                console.log(result);

                return (localStorageUtil.storeDownloadedProject(result.results[0], localStorageUtil.getUserId()))
            }).then(() => {

                this.setState({
                    downloadProjectPercent: 30
                });
                //get the form data for the project
                return (Api.getFormData())
            }).then((results) => {
                //save the form data
                this.setState({
                    downloadProjectPercent: 40
                });

                return (localStorageUtil.storeFormData(projectId, results));
            }).then(() => {
                this.setState({
                    downloadProjectPercent: 50
                });

                //then get your notes
                return (Api.getNotes(projectId))
            }).then((results) => {
                this.setState({
                    downloadProjectPercent: 60
                });

                //store the notes
                return (localStorageUtil.storeNotes(projectId, results))
            }).then(() => {
                this.setState({
                    downloadProjectPercent: 75
                });

                //then get all your report values
                return (Api.getReportValues(projectId))
            }).then((results) => {
                //save them
                this.setState({
                    downloadProjectPercent: 85
                });

                return (localStorageUtil.storeReportValues(projectId, results))
            }).then(() => {
                this.setState({
                    downloadProjectPercent: 98
                });

                window.setTimeout(() => {
                    this.setState({
                        downloadingProject: false,
                        downloadProjectPercent: 100
                    });

                    resolve();
                }, 1500);
            }).catch((e) => {
                alert("error downloading project");
                console.log(e);
                reject();
            });
        })
    };

    checkConnection = () => {
        return new Promise((resolve, reject) => {
            Api.checkConnection().then(() => {
                this.setState({
                    connected: true
                });
                resolve(true);
            }).catch((e) => {
                this.setState({
                    connected: false
                });
                reject(e);
            });
        });
    };

    updateConnectionStatus = () => {
        Api.checkConnection().then(() => {
            this.setState({
                connected: true
            });
        }).catch(() => {
            this.setState({
                connected: false
            });
        });
    };

    removeProjectFromLocalStorage = () => {
        const {projectToDelete} = this.state;
        this.setState({
            confirmRemoveProject: false,
            removeProject: true,
            removeProjectPercent: 0
        })

        localStorageUtil.removeProjectFromLocalStorage(projectToDelete).then(() => {
            window.setTimeout(() => {
                this.setState({
                    removeProjectPercent: 50
                });
                return Promise.resolve();
            }, 500)
        }).then(() => {
            window.setTimeout(() => {
                this.setState({
                    removeProjectPercent: 100
                });
                return Promise.resolve();
            }, 500)
        }).then(() => {
            this.setState({
                confirmRemoveProject: false,
                removeProject: false,
                removeProjectPercent: 0
            })
        });
    };

    removeConfirmWindow = () => {
        this.setState({
            confirmRemoveProject: false
        })
    };

    changeSearchCriteria = (e) => {
        this.setState({
            searchCriteria: e.currentTarget.value
        })
    };

    search = () => {
      const {searchCriteria, pageSize, currentPage} = this.state;
      this.loadAllProjectDetailsFromServer(searchCriteria, pageSize, currentPage);
    };

    logout = () => {
        localStorageUtil.setUserIdToNull();
        this.props.history.push(`/`);
    };

    admin = () => {
        this.props.history.push(`/admin`);
    };

    setGroup = (e) => {
        const group = e.currentTarget.value;
        this.setState({
            group: group
        })
    };

    assignGroups = (project_id, groups) => {
        this.setState({
            assignGroupsModal: true,
            assignedGroups: groups ? groups.split(',') : [],
            projectToModify: project_id
        });
    };

    closeAssignGroupsModal = () => {
        this.setState({
            assignGroupsModal: false,
            assignedGroups: [],
            projectToModify: -1
        });
    };

    assignGroupToProject = (e) => {
        const group = e.currentTarget.value;
        const project_id = this.state.projectToModify;
        const projects = this.state.projectList;

        Api.assignGroupToProject(group, project_id).then((e) => {

            //we assigned the project.  Now update the UI
            let assignedGroups = [];
            for(let i=0; i<projects.length; i++) {
                if(projects[i].project_id == project_id) {
                    const gp = projects[i].groups.split(',');

                    if(gp.indexOf(group) < 0) {
                        gp.push(group);
                        projects[i].groups = gp.join(',');
                    }
                    assignedGroups = gp;

                    //we found it.  bail.
                    break;
                }
            }

            this.setState({
                projectList: projects,
                assignedGroups: assignedGroups
            });

        }).catch((e) => {
            alert("Error assigning group to project");
        })
    };

    removeGroupFromProject = (group) => {
        const project_id = this.state.projectToModify;
        const projects = this.state.projectList;

        Api.removeGroupFromProject(group, project_id).then((e) => {

            //we assigned the project.  Now update the UI
            let assignedGroups = [];
            for(let i=0; i<projects.length; i++) {
                if(projects[i].project_id == project_id) {
                    const gp = projects[i].groups.split(',');

                    console.log(gp);
                    const groupIndex = gp.indexOf(String(group));
                    if(groupIndex >= 0) {
                        gp.splice(groupIndex, 1);
                        projects[i].groups = gp.join(',');
                    }
                    assignedGroups = gp;

                    //we found it.  bail.
                    break;
                }
            }

            this.setState({
                projectList: projects,
                assignedGroups: assignedGroups
            });

        }).catch((e) => {
            alert("Error assigning group to project");
        })
    };

    render() {
        const {projectList, projectCount, pageSize, currentPage, newProjectWindowOpen,
            projectName, client, addressStreet, addressCity, connected,
            addressState, addressZip, description, downloadingProject, downloadProjectPercent,
            confirmRemoveProject, displayOverwriteMessage, removeProject,
            projectToOverwrite, removeProjectPercent, username, hasUnsavedChanges, groups, groupMap, assignGroupsModal,
            assignedGroups, projectToModify, group} = this.state;

            
        return(
            <div className="projectAligner">
                <div className="admin-bar">
                    <span className="float-left">
                        <span>
                            logged in as: &nbsp; &nbsp;
                            <span className="username">
                                {username}
                            </span>
                        </span>
                        <button className="admin-button" onClick={this.logout}>
                            Log out
                        </button>
                    </span>
                    <span className="float-right">
                        <button className="admin-button" onClick={this.admin}>
                            Administration Page
                        </button>
                    </span>
                </div>
                {
                    assignGroupsModal &&
                    <div className="downloadingBlocker">
                        <div className="assignWindow">
                            <div className="title">
                                Assigned Groups:
                                <div className="closeButton">
                                    <i className="fa fa-close" onClick={this.closeAssignGroupsModal}/>
                                </div>
                            </div>
                            <select type="text"onChange={this.assignGroupToProject}>
                                <option value="" hidden="hidden">Select a group</option>
                                <option value="" disabled="disabled">Select a group</option>
                                {
                                    groups.map((group) => {
                                        return(
                                            <option value={group.group_id}>
                                                {group.name}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                            {
                                assignedGroups.map((group) => {
                                    return(
                                        <span className="groupAssigned">{groupMap[group].name} <i className="fa fa-close closeButton" onClick={() => this.removeGroupFromProject(groupMap[group].group_id)}/></span>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
                {
                    hasUnsavedChanges &&
                    <div className="downloadingBlocker">
                        <div className="downloadingWindow">
                            <div className="alert">
                                This project has unsaved changes.  Do you want to try pushing these changes to the server?
                                <br/><br/>
                            </div>
                            <div className="windowButtons">
                                <div className="confirmButton" onClick={() => this.confirmPushUnsavedChanges(projectToOverwrite)}>
                                    <div>YES</div>
                                </div>
                                <div className="confirmButton" onClick={() => this.removeUnsavedChangesWindow(projectToOverwrite)}>
                                    <div>NO</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    displayOverwriteMessage &&
                    <div className="downloadingBlocker">
                        <div className="downloadingWindow">
                            <div className="alert">
                                Locally saved project values don't match project on server.<br/><br/>
                                Do you want to discard your local changes and update to match values on the server?
                                <br/><br/>
                            </div>
                            <div className="windowButtons">
                                <div className="confirmButton" onClick={() => this.confirmDownloadAndOpenProject(projectToOverwrite)}>
                                    <div>YES</div>
                                </div>
                                <div className="confirmButton" onClick={() => this.removeDownloadProjectWindow(projectToOverwrite)}>
                                    <div>NO</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    removeProject &&
                    <div className="downloadingBlocker">
                        <div className="downloadingWindow">
                            <div>
                                Removing from device
                            </div>
                            <div className="downloadingBar">
                                <div className="downloadingBarFill" style={{width: `${removeProjectPercent}%`}}></div>
                            </div>
                        </div>
                    </div>
                }
                {
                    confirmRemoveProject &&
                    <div className="downloadingBlocker">
                        <div className="downloadingWindow">
                            <div className="dialogFill">
                                <div>
                                    Remove From Device?<br/>Your project is still stored in the cloud
                                </div>
                            </div>
                            <div className="windowButtons">
                                <div className="confirmButton" onClick={this.removeProjectFromLocalStorage}>
                                    <div>YES</div>
                                </div>
                                <div className="confirmButton" onClick={this.removeConfirmWindow}>
                                    <div>NO</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    downloadingProject &&
                    <div className="downloadingBlocker">
                        <div className="downloadingWindow">
                            <div>Downloading Project</div>
                            <div className="downloadingBar">
                                <div className="downloadingBarFill" style={{width: `${downloadProjectPercent}%`}}></div>
                            </div>
                        </div>
                    </div>
                }
                <div className="newProjectContainer"
                    style={{visibility: newProjectWindowOpen ? 'visible' : 'hidden'}}
                    onClick={(e) => {
                        if (e.target.className == "newProjectContainer") {
                            this.closeNewProjectWindow();
                        }
                    }}
                >
                    <div className="newProjectWindow">
                        <div className="newProjectHeader">
                            <span>New Project</span>
                            <i className="fa fa-close icon-right" onClick={this.closeNewProjectWindow}/>
                        </div>
                        {
                            connected ? (
                                <div>
                                    <div className="newProjectBody">
                                        <div className="row">
                                            <div className="column small-4">Project Name:</div>
                                            <div className="column small-8"><input type="text"onChange={this.setProjectName} value={projectName}/></div>

                                            <div className="column small-4">Group:</div>
                                            <div className="column small-8">
                                                <select type="text"onChange={this.setGroup} value={group}>
                                                    <option value="" hidden="hidden">Select a group</option>
                                                    <option value="" disabled="disabled">Select a group</option>
                                                    {
                                                        groups.map((group) => {
                                                            return(
                                                                <option value={group.group_id}>
                                                                    {group.name}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>

                                            <div className="column small-4">Client:</div>
                                            <div className="column small-8"><input type="text" onChange={this.setClient} value={client}/></div>

                                            <div className="column small-4">Street:</div>
                                            <div className="column small-8"><input type="text" onChange={this.setAddressStreet}  value={addressStreet}/></div>

                                            <div className="column small-4">City:</div>
                                            <div className="column small-8"><input type="text" onChange={this.setAddressCity} value={addressCity}/></div>

                                            <div className="column small-4">State:</div>
                                            <div className="column small-8"><input type="text" onChange={this.setAddressState} value={addressState}/></div>

                                            <div className="column small-4">Zip Code:</div>
                                            <div className="column small-8"><input type="text" onChange={this.setAddressZip} value={addressZip}/></div>

                                            <div className="column small-4">Description:</div>
                                            <div className="column small-8"><input type="text" onChange={this.setDescription} value={description}/></div>
                                        </div>
                                    </div>
                                    <div className="bottomButton">
                                        <div
                                            className="button primary"
                                            onClick={this.createNewProject}
                                        >SETUP</div>
                                    </div>
                                </div>
                            ) : (
                                <span>You must be connected to the internet to create a new project.</span>
                            )
                        }
                    </div>
                </div>
                <div className="projectHeader">
                    <div className="row">
                        <div className="searchWidget column medium-6">
                            <input type="text" onChange={this.changeSearchCriteria}/>
                        </div>
                        <div className="searchProjectButton column medium-3 ">
                            <div className="button primary scaleButton" onClick={this.search}>
                                SEARCH
                            </div>
                        </div>
                        <div className="newProjectButton column medium-3 ">
                            <div className="button primary scaleButton" onClick={this.openNewProjectWindow}>
                                NEW
                            </div>
                        </div>
                    </div>
                </div>
                <div className="projectWindow">
                    <div className="row">
                        {
                            connected ? (
                                <span></span>
                            ) : (
                                <span>You are not connected to the internet right now.</span>
                            )
                        }
                    </div>
                    <div>
                        <PageButtonsWidget
                            currentPage={currentPage}
                            numItems={projectCount}
                            numItemsPerPage={pageSize}
                            changeCurrentPage={this.changeCurrentPage}
                        />
                        <button
                            className="page-nav-button"
                            onClick={() => {
                                this.changeCurrentPage(currentPage-1);
                            }}
                        >
                            Previous page
                        </button>


                        <button
                            className="page-nav-button"
                            onClick={() => {
                                this.changeCurrentPage(currentPage+1);
                            }}
                        >
                            Next page
                        </button>
                    </div>
                    <div className="projectListHeader">
                    Project List:
                    </div>
                    <table>
                        <tbody>
                {
                    /* Temporarily set it so that it will only go to the project if you click on the project name */
                    projectList.map((object, index) => {
                        let localDownloadCell;
                        if (connected && !this.isStoredLocally(object.project_id)) {
                            localDownloadCell = <td onClick={() => {this.downloadProjectValues(object.project_id)}} style={{width:92}}>
                                <i className="fa fa-cloud-download highlightOk"/>
                            </td>;
                        } else if (connected && this.isStoredLocally(object.project_id)) {
                            localDownloadCell = <td style={{width:92}}>
                                <i className="fa fa-check-circle-o"/>
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                <i className="fa fa-trash-o" onClick={() => {this.removeFromLocalStorage(object.project_id)}}/>
                            </td>;
                        } else {
                            localDownloadCell = <td style={{width:92}}>
                                <i className="fa fa-check-circle-o"/>
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                <i className="fa fa-trash-o highlightDelete" onClick={() => {this.removeFromLocalStorage(object.project_id)}}/>
                            </td>;
                        }

                        let hasChanges = localStorageUtil.getNumberOfChanges(object.project_id) > 0 || localStorageUtil.getNumberOfNotesChanges(object.project_id);

                        return(
                            <tr key={index} className={hasChanges && "hasChanges"}>
                                {localDownloadCell}

                                <td onClick={() => {this.gotoProject(object.project_id, hasChanges)}}>
                                    {object.project_name}
                                </td>
                                <td onClick={() => {this.gotoProject(object.project_id, hasChanges)}}>
                                    {object.client_name}
                                </td>
                                <td onClick={() => {this.gotoProject(object.project_id, hasChanges)}}>
                                    {object.street}
                                </td>
                                <td onClick={() => {this.gotoProject(object.project_id, hasChanges)}}>
                                    {object.city}
                                </td>
                                <td onClick={() => {this.gotoProject(object.project_id, hasChanges)}}>
                                    {object.state}
                                </td>
                                <td onClick={() => {this.gotoProject(object.project_id, hasChanges)}}>
                                    {object.zip}
                                </td>
                                <td onClick={() => {this.assignGroups(object.project_id, object.groups)}}>
                                    <i className="fa fa-folder"/>
                                </td>
                            </tr>
                        );
                    })
                }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
