/**
 * Created by bcole on 7/14/18.
 */
import React from 'react';
import Axios from 'axios';
//import Validator from 'validator';
import format from "string-template";
import BBCode from '../node-bbcode';
import HtmlToReactParser from 'html-to-react';
import Endpoints from '../endpoints/endpoints';
import {parseJSON} from '../Utils/utils';
import api from '../Utils/api'
import localStorageUtil from '../Utils/localStorageUtil';
import serverUtil from '../Utils/serverUtil';
import { parse } from 'node-html-parser';
import ErrorBase64 from '../components/ErrorBase64';
import numToWords from 'num-to-words';

//editor
//import ReactQuill, {Quill} from 'react-quill'; // ES6
//import 'react-quill/dist/quill.snow.css'; // ES6
//import ImageResize from 'quill-image-resize-module-react';
import './report.css'

//const quillTable = require('quill-table');
import Style from 'style-it';
import 'medium-editor/dist/css/medium-editor.css';
import 'medium-editor/dist/css/themes/default.css';

import MediumEditor from 'react-medium-editor';
import Api from "../Utils/api";



export default class REPORT extends React.Component {

    constructor(){
        super();

        this.storedRenderedNodes = [];

        this.reportExportStates = {
            unknown: 0,
            none: 1,
            generating: 2,
            available: 3
        };

        this.state = {
            values: [],
            otherValues: [],
            reportData: [],
            taggedImages: {},
            imageTags: [],
            savedHTML: null,
            reportExportState: 0,
            css: null,
            base64Images: {},
            generatingReport: true,
            notes: {},
            imageNumber: 0,
            totalImages: 0,
            formData: {}
        };

        //hold a reference to a poll timer;
        this.generateWordDocPoll = null;
        this.report_container = React.createRef();
        this.report = React.createRef();
    }

    componentDidMount() {
        //if we have a location.state use this to generate the report
        //otherwise get the formdata state from the api here
        let projectId = this.props.match.params.id;

        this.setState({
            projectId: projectId,
            reportExportState: this.reportExportStates.unknown
        });

        //check to see if we have a saved html document

        //we need to get the form data to handle rendering of some elements
        Api.getFormElementsByKeys(projectId).then((result) => {
            //we need to iterate though this and
            this.setState({formData: result.field_groups});

            //then request the report
            return(Axios(`${Endpoints.host}${Endpoints.path.edited_report}/${projectId}`, {
                method: 'get',
                withCredentials: true
            }))
        }).then((results) => {
            let savedHTML = results.data[0].text;
            this.setState({
                savedHTML: savedHTML,
                generatingReport: false
            });
        }).catch((e) => {
            //we don't have html so..
            this.getFields(projectId).then((result) => {
                return (this.mapNumbers());
            }).then((result) => {
                this.setState({
                    values: result
                })
            }).catch((e) => {
                if(e && e.response && e.response.status == 403) {
                    alert("Session has expired or user is not logged in.  Please log out and log back in.");
                } else {
                    alert("Please connect to the internet to generate reports");
                }
            })
        });

        /*
        this.getExportStatus(projectId).then((result) => {
            this.setState({
                reportExportState: result
            });
        }).catch((e) => {
            this.setState({
                reportExportState: this.reportExportStates.unknown
            });
        });
        */

        Axios(`${Endpoints.host}${Endpoints.path.style}`, {
            method: 'get',
            withCredentials: true
        }).then((result) => {
            const css = result.data.css;
            this.setState({
                css: css
            })
        }).catch((e) => {
            this.setState({
                css: null
            })
        })
    }

    mapNumbers = () => {
        const {values, formData} = this.state;

        return new Promise((resolve) => {
            const keys = Object.keys(values);
            for (let i = 0; i < keys.length; i++) {
                if (formData[keys[i]] && formData[keys[i]].numbersToWords) {
                    values[keys[i]] = numToWords(values[keys[i]]);
                    console.log("num to words:", values[keys[i]]);
                }
            }
            resolve(values);
        })
    }

    componentWillUnmount() {
        if(this.generateWordDocPoll != null) {
            window.clearTimeout(this.generateWordDocPoll);
            this.generateWordDocPoll = null;
        }
    }

    /*
    getExportStatus = (projectId) => {
        return new Promise((resolve, reject) => {
            Axios(`${Endpoints.host}${Endpoints.path.word_doc_status}${projectId}`, {
                method: 'get',
                withCredentials: true
            }).then((results) => {
                let status = results.data.complete;
                if (status == 0) {
                    resolve(this.reportExportStates.generating);
                } else if (status == 1) {
                    resolve(this.reportExportStates.available);
                }
            }).catch((e) => {
                resolve(this.reportExportStates.unknown);
            });
        })
    };
    */

    getValuesFromDatabase = (projectId) => {
        return new Promise((resolve, reject) => {
            if(this.props.location.state) {

                this.setState({
                    values: this.props.location.state.values,
                    otherValues: this.props.location.state.otherValues,
                    notes: this.props.location.state.notes || {}
                });
                resolve();
            } else {
                // Retrieve the values from the database
                Axios(`${Endpoints.host}${Endpoints.path.get_values}/${projectId}`, {
                    method: 'get',
                    withCredentials: true
                }).then((results) => {
                    let dbValues = results.data;
                    this.setState({
                        values: parseJSON(dbValues)
                    });

                    return(api.getNotes(projectId));
                }).then((result) => {
                    this.setState({
                        notes: parseJSON(result) || {}
                    });

                    resolve();
                }).catch((e) => {
                    reject();
                });
            }
        })
    };

    getReport = (projectId) => {
        return new Promise((resolve, reject) => {
            api.getReport().then((results) => {
                this.setState({
                    reportData: {pages: results}
                });
                resolve();
            }).catch((e) => {
                reject();
            });
        });
    };

    getImageTags = (projectId) => {
        return new Promise((resolve, reject) => {
            //load the report json from the api
            Axios.get('/mock/imageTags.json')
            .then((result) => {
                this.setState({
                    imageTags: result.data ? result.data.tags : [],
                });
                resolve();
            })
            .catch(() => {
                reject();
            })
        })
    };

    getTaggedImages = (projectId) => {
        return new Promise((resolve, reject) => {
            let getTaggedImages = `${Endpoints.host}${Endpoints.path.get_tagged_images}/${projectId}`;
            Axios(`${Endpoints.host}${Endpoints.path.get_tagged_images}/${projectId}`, {
                method: 'get',
                withCredentials: true
            }).then((results) => {
                let taggedImages = results.data;
                this.setState({
                    taggedImages: taggedImages || {}
                });
                resolve(taggedImages);
            }).catch((e) => {
                reject();
            });
        })
    };

    getFields = (projectId) => {
        return new Promise((resolve, reject) => {
            this.getValuesFromDatabase(projectId).then(() => {
                return this.getReport(projectId);
            }).then(() => {
                return this.getImageTags(projectId);
            }).then(() => {
                return this.getTaggedImages(projectId);
            }).then(() => {
                this.setState({
                    generatingReport: false
                });
                resolve();
            }).catch((err) => {
                this.setState({
                    generatingReport: false
                });
                reject();
            });
        })
    };

    /*
    getFields = (projectId) => {
        return new Promise((resolve, reject) => {
            this.getValuesFromDatabase(projectId).then(() => {
                return this.getReport(projectId);
            }).then(() => {
                return this.getImageTags(projectId);
            }).then(() => {
                return this.getTaggedImages(projectId);
            }).then((taggedImages) => {
                return this.getBase64Images(taggedImages);
            }).then(() => {
                this.setState({
                    generatingReport: false
                });
                resolve();
            }).catch((err) => {
                this.setState({
                    generatingReport: false
                });
                reject();
            });
        })
    };
    */

    getBase64Image = (imageList, base64Images) => {
        return new Promise((resolve, reject) => {

            const {filename, crop_x, crop_y, crop_w, crop_h} = imageList.pop();

            api.getBase64ImageData(filename, crop_x, crop_y, crop_w, crop_h).then((result) => {
                base64Images[filename] = result;
                const howManyDoWeHave = Object.keys(base64Images).length;

                this.setState({
                    imageNumber: howManyDoWeHave
                });

                console.log("exporting image:" + imageList.length);
                if (imageList.length > 0) {

                    //recursively call this method until we resolve everything.
                    this.getBase64Image(imageList, base64Images).then((base64Images) => {
                        resolve(base64Images);
                    });

                } else {
                    this.setState({
                        base64Images: base64Images
                    });

                    resolve(base64Images);
                }
            }).catch((err) => {
                console.log("error getting base 64 image:");
                console.log(err);
                //reject(err);

                base64Images[filename] = ErrorBase64;
                // for the time being, we'll put our error image here then keep exporting
                const howManyDoWeHave = Object.keys(base64Images).length;

                this.setState({
                    imageNumber: howManyDoWeHave
                });

                console.log("exporting image:" + imageList.length);
                if (imageList.length > 0) {

                    //recursively call this method until we resolve everything.
                    this.getBase64Image(imageList, base64Images).then((base64Images) => {
                        resolve(base64Images);
                    });

                } else {
                    this.setState({
                        base64Images: base64Images
                    });

                    resolve(base64Images);
                }


                //push the image we failed to download back on the stack
                /*
                imageList.push({filename: filename, crop_x: crop_x, crop_y: crop_y, crop_w: crop_w, crop_h: crop_h});

                //hopefully this will resolve
                this.getBase64Image(imageList, base64Images).then((base64Images) => {
                    resolve(base64Images);
                });
                 */
            })
        })
    }

    getBase64Images = (taggedImages) => {
        const tags = Object.keys(taggedImages);

        const base64Images = {};
        let totalImages = 0;

        for(let t=0; t<tags.length; t++) {
            totalImages += taggedImages[tags[t]].length;
        }

        this.setState({
            totalImages: totalImages,
            imageNumber: 0
        });

        let imageList = [];

        return new Promise((resolve, reject) => {
            if(totalImages == 0) {
                resolve()
            } else {

                for (let t = 0; t < tags.length; t++) {
                    for (let i = 0; i < taggedImages[tags[t]].length; i++) {
                        const filename = taggedImages[tags[t]][i].filename;
                        const crop_x = taggedImages[tags[t]][i].crop_x;
                        const crop_y = taggedImages[tags[t]][i].crop_y;
                        const crop_w = taggedImages[tags[t]][i].crop_w;
                        const crop_h = taggedImages[tags[t]][i].crop_h;

                        imageList.push({filename: filename, crop_x: crop_x, crop_y: crop_y, crop_w: crop_w, crop_h: crop_h});
                    }
                }

                console.log("testing image list");
                console.log(imageList);

                this.getBase64Image(imageList, base64Images).then((base64Images) => {
                    resolve(base64Images);
                }).catch((e) => {
                    console.log(e);
                    reject(e);
                })
            }
        })
    };

    renderReportPromise = (forExport) => {
        let projectId = this.props.match.params.id;

        this.setState({
            generatingReport: true
        });

        return new Promise((resolve, reject) => {
            this.getImageTags(projectId).then(() => {
                return this.getTaggedImages(projectId);
            }).then((taggedImages) => {
                return this.getBase64Images(taggedImages);
            }).then((base64Images) => {
                this.setState({
                    generatingReport: false
                });
                resolve(this.renderReport(forExport, base64Images));
            })
        })
    };

    renderReport = (forExport, base64Images) => {
        const {savedHTML} = this.state;
        let fixedHtml = savedHTML;

        const searchFor = [];
        const replaceWith = [];

        if (forExport && savedHTML != null) {
            const root = parse(savedHTML);
            const images = root.querySelectorAll("img");
            for (let i = 0; i < images.length; i++) {
                const oldImageString = images[i].toString();
                searchFor.push(images[i].attributes.src);

                const dataImg = images[i].attributes['data-img'];
                if(dataImg != null) {
                    replaceWith.push(base64Images[dataImg]);
                } else {
                    const splitUrlOnSlashes = images[i].attributes.src.split('/');
                    for(let w=0; w<splitUrlOnSlashes.length; w++) {
                        if(splitUrlOnSlashes[w] == "image") {
                            replaceWith.push(base64Images[splitUrlOnSlashes[w + 1]]);
                            break;
                        }
                    }
                }
            }

            for(let j=0; j<searchFor.length; j++) {
                fixedHtml = fixedHtml.replace(searchFor[j], replaceWith[j]);
            }

            return(fixedHtml);

        } else if (savedHTML == null) {
            const {reportData, values, otherValues} = this.state;
            const combinedValues = Object.assign(values, otherValues);

            const pages = reportData.pages || [];

            const report = [];
            for (let i = 0; i < pages.length; i++) {
                const page = pages[i];
                report.push(this.renderPage(page, values, i, forExport));
            }

            return(report.join(" "));
        } else {
            return(savedHTML);
        }
    };

    renderPage = (page, values, key, forExport) => {

        const {text,condition,not,alt,image,storedNode,as,pagenote,modList} = page.node || {};
        const {imageTags, taggedImages, notes, base64Images} = this.state;
        //check condition

        let rendernode = false;
        if(condition != null) {

            let matchingValues = 0;

            //check matching conditions
            for (let i = 0; i < condition.length; i++) {
                const key = condition[i].key;
                const value = condition[i].value;

                //in the event that the object exists
                const exists = condition[i].exists;

                //grab the comp values
                let v = values[key];

                if(key != "always" && !exists && (v == undefined || v == "") && key != undefined) {
                    v = "unset"
                }

                //try to trim strings
                //it's possible v is undefined or a number
                //if that's the case, just leave it alone
                try {
                    v = v.trim();
                } catch(e) {

                }

                if (key == "always") {
                    matchingValues = condition.length;
                    rendernode = true;
                    break;
                } else if (v == value || (typeof(v) == "object" && v.indexOf(value) >= 0)) {
                    matchingValues++;

                } else if (exists && v != null) {
                    matchingValues++;
                    break;
                }
            }

            if(matchingValues == condition.length) {
                rendernode = true;
            }

            //check NOT conditions
            if(not) {
                for (let i = 0; i < not.length; i++) {
                    const key = not[i].key;
                    const value = not[i].value;

                    //in the event that the object exists
                    const exists = not[i].exists;

                    //grab the comp values
                    let v = values[key];

                    if (key != "always" && !exists && (v == undefined || v == "") && key != undefined) {
                        v = "unset"
                    }

                    //try to trim strings
                    //it's possible v is undefined or a number
                    //if that's the case, just leave it alone
                    try {
                        v = v.trim();
                    } catch (e) {

                    }

                    if(key == "with_spa") {
                        console.log("v:", v);
                        console.log("value:", value);
                    }

                    if (key == "always") {
                        matchingValues = condition.length;
                        rendernode = true;
                        break;
                    } else if (v == value || (typeof(v) == "object" && v.indexOf(value) >= 0)) {
                        rendernode = false;
                        break;
                    } else if (exists && v != null) {
                        rendernode = false;
                        break;
                    }
                }
            }
        }

        let HTML = "";
        if(rendernode || pagenote || modList) {
            if(modList != null) {
                const {list, connector, modify} = modList;
                const lst = values[list];
                if(lst != null) {
                    for (let i = 0; i < lst.length; i++) {
                        for (let j = 0; j < modify.length; j++) {
                            const mod = modify[j];
                            if (lst[i] == mod.key) {
                                console.log("value test:", values);
                                lst[i] = BBCode.render(format(mod.value, values), {newLine: true, allowClasses: true});
                            }
                        }
                    }

                    if (lst.length > 1) {
                        //add the "connector"

                        //ok, I'm tired.  Let's just get this done
                        //we're triggering multiple renders and adding this sequentially
                        if(!lst[lst.length - 1].startsWith(connector)) {
                            lst[lst.length - 1] = `${connector} ${lst[lst.length - 1]}`
                        }
                    }
                }

            } else if(text != null && text=="[block]") {
                HTML = '<p>';
            } else if(text != null && text=="[/block]") {
                HTML = '</p>';
            } else if (as != null) {
                values[as] = text;
            } else if(text != null) {
                HTML = BBCode.render(format(text, values), {newLine: true, allowClasses: true});
            } else if(image != null) {
                //we need to get all the images with a certain tag
                //loop though them and create HTML
                let imageTagId = 0;
                for(let i=0; i<imageTags.length; i++) {
                    if(imageTags[i].value == image){
                        imageTagId = imageTags[i].id;
                        break;
                    }
                }

                HTML = '<p>';
                let tagged_images = taggedImages[imageTagId] || [];

                let width = "50%";
                if(tagged_images.length == 1) {
                    width = "100%"
                }

                for(let i=0; i<tagged_images.length; i++){
                    let tagged = tagged_images[i];

                    if (tagged != null) {
                        if(tagged.crop_h > 0 && tagged.crop_w > 0) {

                            if(forExport) {
                                HTML = HTML + `<img data-img=${tagged.filename} class='reportImage' src='${base64Images[tagged.filename]}' width='${width}'/>`;
                            } else {
                                HTML = HTML + `<img data-img=${tagged.filename} class='reportImage' src='${Endpoints.getEndpoint(Endpoints.path.get_image)}/${tagged.filename}/${tagged.crop_x}/${tagged.crop_y}/${tagged.crop_w}/${tagged.crop_h}' width='${width}'/>`;
                            }

                        } else {

                            if(forExport) {
                                HTML = HTML + `<img data-img=${tagged.filename} class='reportImage' src='${base64Images[tagged.filename]}' width='${width}'/>`;
                            } else {
                                HTML = HTML + `<img data-img=${tagged.filename} class='reportImage' src='${Endpoints.getEndpoint(Endpoints.path.get_image)}/${tagged.filename}' width='${width}'/>`;
                            }

                        }
                    }
                }
                HTML = HTML + '</p>';
            } else if (pagenote != null) {
                console.log("PAGE NOTE: " + pagenote);
                HTML = HTML + '<p style="color: red">' + notes[pagenote] + '</p>'
            }

            return (HTML);
        } else if (alt) {
            //if we have an alt value render that.
            HTML = BBCode.render( format(alt, values), {newLine: true} );

            return (HTML);
        }

        return null;
    };

    returnToReport = () => {
        this.props.history.push(`/project/${this.state.projectId}`)
    };

    handleEdit = (value) => {
        this.setState({
            savedHTML: value
        });
    };

    saveHTML = () => {
        //we MIGHT want to store this in the state
        let projectId = this.props.match.params.id;

        let {savedHTML} = this.state;
        if(savedHTML == null) {
            savedHTML = this.report.current.innerHTML;
        }

        console.log(savedHTML);

        this.syncProjectWithServer().then(() => {
            return(api.saveHtml(projectId, savedHTML));
        }).then(() => {
            alert("saved edits");
        }).catch((e) => {
            if (e && e.response && e.response.status == 403) {
                alert("edit error: Session expired or user logged out.  Please log back in.");
            } else {
                alert("edit error: Error saving.  You may not be connected to the internet");
            }
        });
    };

    syncProjectWithServer = () => {
        let projectId = this.props.match.params.id;

        return new Promise((resolve, reject) => {
            if (localStorageUtil.hasUncommittedChanges(projectId)) {
                serverUtil.updateDatabaseToMatchLocalStorage(projectId)
                    .then(() => {
                        console.log("Successfully synced the database with the project.");
                        resolve(1);
                    }).catch((e) => {
                    console.log("Error occurred whiling syncing database with the project:\n" + e);
                    reject(e);
                });
            } else {
                console.log("nothing to sync");
                resolve(1);
            }
        });
    };


    resetReport = () => {
        let projectId = this.props.match.params.id;

        this.setState({
            generatingReport: true,
            savedHTML: null
        });

        this.getFields(projectId).then(() => {
            this.mapNumbers();
            this.setState({
                savedHTML: null,
            })
        })
    };

    exportWord = () => {
        let projectId = this.props.match.params.id;
        const {css} = this.state;
        const cssWithTags = "<style>"+css+"</style>";

        this.renderReportPromise(true).then((result) => {

            const savedHTML = result;

            const exportHtml = "<html><body>" + cssWithTags + savedHTML + "</body></html>";
            console.log(exportHtml);

            const gz = window.GrabzIt("OTY0NmFlMGJmMzc4NGJjM2I2NTVmZTgxYzA2OWNiOGM=");
            const gzhtml = gz.ConvertHTML(exportHtml,
                {"format": "docx", "download": 1, "onfinish": this.wordDocExportOk, "onstart": this.startExport, "templateid": "pool", "mbottom": 25});
            gzhtml.Create();

            this.setState({
                reportExportState: this.reportExportStates.generating
            });
        }).catch((err) => {
            alert("error generating report");
        })
    };

    startExport = (id) => {
        console.log("START EXPORT");
        console.log(id);
    };

    wordDocExportOk = (id) => {
        console.log("FINISH EXPORT");
        console.log(id);

        this.setState({
            reportExportState: this.reportExportStates.available
        });
    };

    printElem = (divId) => {
        const content = document.getElementById(divId).innerHTML;
        const mywindow = window.open('', 'Print', 'height=600,width=800');

        mywindow.document.write('<html><head><title></title>');
        mywindow.document.write('</head><body >');
        mywindow.document.write(content);
        mywindow.document.write('</body></html>');

        mywindow.document.close();
        mywindow.focus();
        mywindow.print();
        //mywindow.close();
        return true;
    };

    /*
    pollStatus = (projectId) => {
        this.generateWordDocPoll = window.setTimeout(() => {
            this.getExportStatus(projectId).then((result) => {
                if(result == this.reportExportStates.available) {
                    this.generateWordDocPoll = null;
                    this.setState({
                        reportExportState: result
                    });
                } else {
                    this.pollStatus(projectId);
                }
            }).catch((e) => {
                this.generateWordDocPoll = null;
                this.setState({
                    reportExportState: this.reportExportStates.unknown
                });
            })
        }, 10000);
    };
    */

    render(){
        const parser = new HtmlToReactParser.Parser();
        let projectId = this.props.match.params.id;

        const reportText = this.renderReport();
        const {reportExportState, css, generatingReport, savedHTML, imageNumber, totalImages} = this.state;

        return(
            <div>
                {
                    (generatingReport) &&
                    <div className="reportGenerateBlocker">
                        <div>GENERATING REPORT</div>
                        {
                            (totalImages > 0) &&
                            <div>Getting Images: {imageNumber} of {totalImages}</div>
                        }
                    </div>
                }
                {
                    (reportExportState != this.reportExportStates.available && reportExportState != this.reportExportStates.unknown) &&
                        <div className="reportGenerateBlocker">
                            <div>EXPORTING REPORT</div>
                            <div><i className="fa fa-coffee"/></div>
                        </div>
                }
                <div className="report-title-bar">
                    <div className="back-button" onClick={() => {this.returnToReport()} }>| BACK |</div>
                    <div className="save-button" onClick={() => {this.saveHTML()} }>| SAVE |</div>
                    <div className="save-button" onClick={() => {this.resetReport()} }>| RESET |</div>
                    <div className="save-button" onClick={() => {this.printElem('textarea')} }>| PRINT |</div>
                    {
                        reportExportState == this.reportExportStates.generating &&
                        <div className="save-button"><i className="fa fa-spinner fa-spin"></i> EXPORTING </div>
                    }
                    {
                        reportExportState != this.reportExportStates.generating &&
                        <div className="save-button" onClick={() => {
                            this.exportWord()
                        } }>| EXPORT |</div>
                    }
                </div>

                <Style>
                    {`
                        ${css}
                    `}

                    <div className="report-container" id="report-container" ref={this.report_container}>
                        <div className="textarea" tag='textarea' id='textarea' ref={this.report}>
                            {
                                (reportText != null && reportText != "") &&
                                <MediumEditor
                                    text={reportText}
                                    onChange={this.handleEdit}
                                >
                                </MediumEditor>
                            }
                        </div>
                    </div>
                </Style>
            </div>
        )
    }
}
