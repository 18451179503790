import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import Axios from 'axios';
import Endpoints from '../endpoints/endpoints';
import Api from '../Utils/api';
import AWS from 'aws-sdk';
import sparkMD5 from 'spark-md5';

import './imageupload.css'
import localStorageUtil from '../Utils/localStorageUtil';
import crypto from 'crypto';
import Evaporate from 'evaporate';

export default class ImageUpload extends Component {

    checkConnection = () => {
        const {checkConnection} = this.props;
        return checkConnection();
    };

    refreshConnectionStatus = () => {
        const {refreshConnectionStatus} = this.props;
        refreshConnectionStatus();
    };

    constructor() {
        super()
        this.state = {
            uploads: [],
            // possibleUploadStatuses is used for the uploading flags showing the status of the upload.
            possibleUploadStatuses: ["preload", "uploading in progress", "uploaded", "error"],
            // uploadStatuses is used to store the upload status of each upload,
            //    which is important for the uploading flags.
            // For each item in the uploads array, they have one status out of the possibleUploadStatuses array.
            uploadStatuses: [],
            // isUploading tells us if an upload is in progress
            isUploading: false,
        }
    }

    setUploads = (uploads) => {
        const {setUploads} = this.props;
        setUploads(uploads);
    };

    // When the files are dropped, add them to the list of files ready to upload.
    onDrop = (files) => {
        const {imagesToUpload, projectId} = this.props;
        let newUploads = [];
        let allUploads = imagesToUpload;

        this.refreshConnectionStatus();
        Api.getLoggedInUser().then(() => {
            files.map((file) => {
                if (!this.isDuplicateUpload(file.name)) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        let newImageObj = {
                            file: file,
                            image: reader.result
                        };
                        newUploads = newUploads.concat(newImageObj);
                        allUploads = allUploads.concat(newImageObj);

                        this.props.storingImage(true);
                        if (newUploads.length === files.length) {
                            this.setUploads(allUploads);

                            Promise.all(newUploads.map(upload => localStorageUtil.storeImage(
                                projectId,
                                upload.file.name,
                                upload.file.type,
                                upload.image
                            ))).then(() => {
                                this.props.storingImage(false);

                            }).catch((e) => {
                                this.props.storingImage(false);
                                this.props.setError("Local Storage Full.  Please upload images.");
                            });
                        }
                    };
                    reader.readAsDataURL(file);
                }
            });
        }).catch((e) => {
            this.props.loginError(e);
        })
    }

    isDuplicateUpload = (filename) => {
        const {imagesToUpload} = this.props;

        for (let i = 0; i < imagesToUpload.length; i++) {
            if (imagesToUpload[i].file.name === filename) {
                return true;
            }
        }
        return false;
    }

    // This listener is for handling deleting specific items from the ready-to-upload items.
    deleteUpload = (e, index) => {
        const {possibleUploadStatuses, uploadStatuses, isUploading} = this.state;
        const {imagesToUpload, projectId} = this.props;

        e.preventDefault();

        console.log("Attempting to delete upload index " + index);
        let newArray = imagesToUpload;
        newArray.splice(index, 1);
        this.setUploads(newArray);
        localStorageUtil.removeImage(projectId, index);
    }

    // For testing purposes only. It puts the thread to sleep for a specified period of time
    // so that we can actually see the changes in the loading flags.
    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


// recursively upload one at a time
    submit = (e) => {
        const {possibleUploadStatuses, uploadStatuses, isUploading} = this.state;
        const {imagesToUpload, projectId} = this.props;
        const uploadsCopy = imagesToUpload.slice();

        this.checkConnection().then(() => {
            // Initialize the uploadStatuses with the "preload" flag
            for (var i = 0; i < imagesToUpload.length; i++) {
                uploadStatuses[i] = possibleUploadStatuses[0];
            }

            this.setState({
                isUploading: true
            });

            // Start the upload at the first index
            this.uploadFile(uploadsCopy, 0);
        }).catch(() => {
            /*
            alert("You are not connected to the internet, so images are being stored offline.\nYou must sync the images at a later time.");
            for (let i = 0; i < imagesToUpload.length; i++) {
                localStorageUtil.storeImage(
                    projectId,
                    imagesToUpload[i].file.name,
                    imagesToUpload[i].file.name,
                    imagesToUpload[i].file.type,
                    imagesToUpload[i].file.type,
                    imagesToUpload[i].image
                )
            }
            */
        });
    }

    // Recursive uploading function.
    // index is for referring to the position within uploadStatuses,

    uploadFile = (imageUploads, index) => {
        const {uploadStatuses, possibleUploadStatuses} = this.state;
        const {projectId} = this.props;

        if (imageUploads.length > 0) {
            //const config = { headers: { 'Content-Type': 'multipart/form-data'}, withCredentials: true };
            const config = {withCredentials: true};
            //const formData = new FormData();
            //formData.append('file', imageUploads[0].file, imageUploads[0].file.name);
            //formData.append('projectId', projectId);

            const data = {
                filename: imageUploads[0].file.name,
                mimetype: imageUploads[0].file.type
            }

            // "preload", "loading", "loaded", "error"
            let tempArr = uploadStatuses.slice();
            tempArr[index] = possibleUploadStatuses[1]; // Set the current upload status to uploading in progress
            this.setState({
                uploadStatuses: tempArr,
                isUploading: true
            });

            // Code to post the images begins here.
            Axios.post(`${Endpoints.host}${Endpoints.path.post_image_direct}/${projectId}`,
                {data: data}, config).then((result) => {
                // to do, check the result to make sure we get the result we need from the server

                console.log(result);
                const {awsS3Key, s3Bucket, filename} = result.data;
                console.log(imageUploads[0]);

                return (this.uploadDirectlyToS3(awsS3Key, s3Bucket, filename, imageUploads[0].file));
            }).then(() => {
                // Upload complete, set current status to success
                tempArr[index] = possibleUploadStatuses[2];

                this.setState({
                    uploadStatuses: tempArr,
                    isUploading: true
                });

                imageUploads.shift();
                index++;

                // Remove from local storage
                localStorageUtil.removeImage(projectId, imageUploads[0].file.name);

                this.uploadFile(imageUploads, index);
            }).catch((e) => {
                console.log(e);

                if(e.response && (e.response.status==401 || e.response.status==403)) {
                    console.log("I'm not logged in right");
                    this.props.loginError(e);
                } else {
                    // Upload failed, set the flag to error occurred
                    tempArr[index] = possibleUploadStatuses[3];

                    this.setState({
                        uploadStatuses: tempArr,
                        isUploading: true
                    });

                    imageUploads.shift();
                    index++;
                    this.uploadFile(imageUploads, index);
                }
            });

        } else {
            // If this is reached, we are out of uploads, so reset everything.
            this.setUploads([]);
            this.setState({
                uploadStatuses: [],
                isUploading: false
                });
            this.props.update();
        }
    }

    uploadDirectlyToS3(awsS3Key, s3Bucket, filename, file) {

        return new Promise((resolve, reject) => {
            var config = {
                signerUrl: Endpoints.GetAuthUpload(),
                aws_key: awsS3Key,
                bucket: s3Bucket,
                cloudfront: false,
                computeContentMd5: true,
                cryptoMd5Method: (d) => btoa(sparkMD5.ArrayBuffer.hash(d, true)),
                cryptoHexEncodedHash256: data => AWS.util.crypto.sha256(data, 'hex')
            };

            return Evaporate.create(config)
                .then(function (evaporate) {

                    const addConfig = {
                            name: filename,
                            file: file,
                            progress: function (progressValue) {
                                console.log('Progress', progressValue);
                            },
                            complete: function (_xhr, awsKey) {
                                console.log('Complete!');
                            },
                        },
                        overrides = {
                            // bucket: AWS_BUCKET // Shows that the bucket can be changed per
                        };
                    evaporate.add(addConfig, overrides)
                        .then(function (awsObjectKey) {
                                console.log('File successfully uploaded to:', awsObjectKey);
                                resolve();
                            },
                            function (reason) {
                                console.log('File did not upload sucessfully:', reason);
                                reject();
                            });
                });
        })
    }

    render() {
        const {uploadStatuses, possibleUploadStatuses, isUploading} = this.state;
        const {imagesToUpload, connected} = this.props;

        // The uploadArea is the html code that goes where upload button appears
        let uploadArea = <span></span>;

        // If there are uploads, show the button either enabled or disabled depending on the internet connection.
        if (imagesToUpload.length > 0) {
            if (connected) {
                uploadArea =
                    <div className="button-div">
                        <button className="button upload-button" onClick={this.submit}>Begin Upload</button>
                    </div>;
            } else {
                uploadArea =
                    <div className="button-div">
                        <button className="button disabled-button">You must be connected to the internet to begin upload.</button>;
                    </div>;
            }
        // If there are no uploads, then just show a message saying uploads will appear here.
        } else {
            if (!isUploading) {
                uploadArea = <h3 className="centered-text">Your images to be uploaded will appear here.</h3>;
            }
        }

        return (
            <div>
                <div className="row">
                    <div className="dropzone">
                        <Dropzone
                            disabled={isUploading}
                            className={isUploading ? "dropzone-component disabled-dropzone-component" : "dropzone-component"}
                            accept="image/jpeg, image/png"
                            onDrop={this.onDrop}>
                            {({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
                                if (isUploading) {
                                    return <div className="dropzone-text">Uploading in progress.<br/>Do not drop any images or refresh the page until loading is complete.</div>
                                } else if (isDragActive) {
                                    return <div className="dropzone-text allowed">Drop images for upload.</div>;
                                } else if (isDragReject) {
                                    return <div className="dropzone-text not-allowed">This file is not authorized.</div>;
                                } else {
                                    return <div className="dropzone-text"><h3>Drag your photos in here</h3><h4>-or-</h4><h3>click to upload photos.</h3></div>;
                                }
                            }}
                        </Dropzone>
                    </div>
                </div>
                    <div className="row">
                    {
                        imagesToUpload.map((upload, index) => {
                            let uploadFlagOrDeleteButton = <span></span>;
                            // If upload is not in effect, show a delete button to remove a pre-uploaded image
                            if (!isUploading) {
                                uploadFlagOrDeleteButton = <button
                                        className="button centered-text"
                                        onClick={(e) => {this.deleteUpload(e, index)}}>
                                        <i className="fa fa-2x fa-close" />
                                    </button>;
                            // If uploading is in effect, show the status of the upload as it is happening.
                            } else {
                                if (uploadStatuses[index] === possibleUploadStatuses[0]) {
                                    uploadFlagOrDeleteButton = <span className="pending-flag upload-flag">Pending</span>;
                                } else if (uploadStatuses[index] === possibleUploadStatuses[1]) {
                                    uploadFlagOrDeleteButton = <span className="uploading-flag upload-flag">UPLOADING...</span>;
                                } else if (uploadStatuses[index] === possibleUploadStatuses[2]) {
                                    uploadFlagOrDeleteButton = <span className="uploaded-flag upload-flag">Successfully Uploaded</span>;
                                } else if (uploadStatuses[index] === possibleUploadStatuses[3]) {
                                    uploadFlagOrDeleteButton = <span className="error-flag upload-flag">Error occurred while uploading</span>;
                                }
                            }

                            return (
                                <div className="medium-4 small-12">
                                    <div className="uploaded-image-div-outer">

                                        <div className="uploaded-image-div-inner centered-text">
                                            <img src={upload.image} className="uploaded-image"/>
                                        </div>

                                        <div className="delete-button">
                                            {uploadFlagOrDeleteButton}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                    {uploadArea}
            </div>
        )
    }
}
