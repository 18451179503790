import React from 'react';
import Axios from 'axios';
//import Validator from 'validator';
import Endpoints from '../endpoints/endpoints';
import localStorageUtil from '../Utils/localStorageUtil';
import Api from '../Utils/api';
import './login.css'

export default class Form extends React.Component {

    constructor() {
        super();

        this.state = {
            username: "",
            password: "",
            token: "",
            createPassword: false
        }
    }

    componentDidMount() {
        Api.checkConnection().then(() => {
            if (localStorageUtil.checkIfLoggedIn() === true) {
                this.pushToProjectsPage();
            } else {
                this.checkTokenLogin();
            }
        }).catch(() => {
            if (localStorageUtil.checkIfLoggedIn() === true) {
                this.pushToProjectsPage();
            } else {
                this.checkTokenLogin();
            }
        })
    }

    checkTokenLogin = () => {
        const user = this.props.match.params.user;
        const token = this.props.match.params.token;

        if(user && token) {

            this.setState({
                username: user,
                password: token
            });

            this.tryLogin();
        }
    };

    setUsername = (e) => {
        this.setState({
            username: e.currentTarget.value
        })
    };

    setPassword = (e) => {
        this.setState({
            password: e.currentTarget.value
        })
    };

    changePassword = (e) => {
        const {password, token, username} = this.state;
        Api.changePassword(username, token, password).then((result) => {
            this.setState({
                createPassword: false
            });
            this.tryLogin();
        }).catch((e) => {
            alert("There was an error setting the password.");
            this.setState({
                createPassword: false
            });
        })
    };

    tryLogin = () => {
        const {username, password} = this.state;

    /*
        TODO: this is causing errors if api is connected after logged in
        we should check network status BEFORE we do this
        otherwise next page kicks you out
    */
        //if (localStorageUtil.checkIfLoggedIn() === true) {
            Axios(Endpoints.getEndpoint(Endpoints.path.login), {
                method: 'post',
                withCredentials: true,
                data:{
                    user: username,
                    password: password
                },
            }).then((result) => {
                console.log("Logged in as user with id: " + result.data.user_id);

                if(result.data.token == 1) {
                    console.log("token present. We should reset the password.");
                    this.setState({
                        createPassword: true,
                        token: password,
                        password: ""
                    });
                } else {
                    localStorageUtil.setUserId(result.data.user_id);
                    this.pushToProjectsPage();
                }
            }).catch((e) => {
                alert("incorrect user name or password");
            });
        //} else {
        //    this.pushToProjectsPage();
        //}
    };

    pushToProjectsPage = () => {
        this.props.history.push('/project');
    };

    render() {
        const {createPassword} = this.state;


        return(
            <div className="loginAligner">
                {
                    createPassword &&
                        <div className="createPasswordBackdrop">
                            <div className="createPasswordWindow">
                                Create a new Password:
                                <input type="text" onChange={this.setPassword}/>
                                <button className="button primary" onClick={this.changePassword}>Set Password</button>
                            </div>
                        </div>
                }

                <div className="loginText">
                    Login:
                </div>
                <div className="loginWindow">
                    username:
                    <input type="text" onChange={this.setUsername}/>
                    password:
                    <input type="text" onChange={this.setPassword}/>
                    <button className="button primary" onClick={this.tryLogin}>login</button>
                </div>
                updated: 4/12/2021
                <div>
                    4/12/2021
                    <ul>
                        <li>New fields and text added</li>
                        <li>Fixed 'not' condition on report for unset values</li>
                        <li>Added new fields and report verbage</li>
                    </ul>
                    2/01/2021
                    <ul>
                        <li>fixed rendering of number fields in admin forms</li>
                        <li>Data: removed (s)</li>
                    </ul>
                </div>
            </div>
        )
    }
}
