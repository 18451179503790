/**
 * Created by bcole on 5/8/19.
 */
import React from 'react'
import api from '../Utils/api';
import Endpoints from '../endpoints/endpoints';
import Axios from 'axios';

export default class AdminStyles extends React.Component {

    constructor() {
        super();

        this.state = {
            css: ""
        }
    }

    componentDidMount() {
        Axios(`${Endpoints.host}${Endpoints.path.style}`, {
            method: 'get',
            withCredentials: true
        }).then((result) => {
            const css = result.data.css;
            this.setState({
                css: css
            })
        }).catch((e) => {
            this.setState({
                css: null
            })
        });

        api.getLoggedInUser().then((result) => {

        }).catch((e) => {
            this.props.history.push(`/`, this.state);
        })
    }

    changeCss = (e) => {
        const css = e.currentTarget.value;
        this.setState({
            css: css
        })
    };

    saveCss = () => {
        const {css} = this.state;

        Axios(`${Endpoints.host}${Endpoints.path.style}`, {
            method: 'post',
            withCredentials: true,
            data: {
                css: css
            }
        }).then((result) => {
            alert("SAVE SUCCESSFUL");
        }).catch((e) => {
            alert("SAVE ERROR" + e);
        })
    };

    render() {
        const {css} = this.state;

        return(
            <div className="jsonGroup">
                Styles:
                <textarea className="admin-textfield" value={css} onChange={(e) => this.changeCss(e)}></textarea>
                <button className="admin-button" onClick={this.saveCss}>save css</button>
            </div>
        )
    }
}