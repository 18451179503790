import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'
import 'font-awesome/css/font-awesome.css';

import './css/foundation.css';
import './foundation_override.css';

import Form from './pages/Form.js';
import Report from './pages/Report.js';
import Login from './pages/login.js';
import Project from './pages/project.js'
import Admin from './pages/admin.js';
import PrivateRoute from './PrivateRoute'

import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";


ReactDOM.render(
    <Router>
        <div className="app">
            <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/login/:user/:token" component={Login} />
                <Route path="/login" component={Login} />
                <PrivateRoute exact path="/admin" component={Admin} />
                <PrivateRoute exact path="/admin/:path" component={Admin} />
                <PrivateRoute exact path="/admin/:path/:component" component={Admin} />
                <PrivateRoute exact path="/project" component={Project} />
                <PrivateRoute path="/project/:id" component={Form} />
                <PrivateRoute path="/report/:id" component={Report} />
            </Switch>
        </div>
    </Router>

    , document.getElementById('root'));
registerServiceWorker();