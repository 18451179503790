/**
 * Created by bcole on 7/18/18.
 */
import React from 'react';
import ImageUpload from './imageupload';
import Axios from 'axios';

import Endpoints from '../endpoints/endpoints';

import './pageButtonsWidget.css'

export default class PageButtonsWidget extends React.Component {

    
    constructor (props) {
        super(props);
        this.state = {
            gap: -1234,
            maxButtonsToShow: 9
        }
    }


    changeCurrentPage = (newPageNumber) => {
        this.props.changeCurrentPage(newPageNumber);
    }


    retrievePagesThatShouldAppear = (numPages) => {
        const {currentPage} = this.props;
        const {maxButtonsToShow} = this.state;
        const {gap} = this.state;

        let pagesToAppear = [];

        for (let i = 0; i < numPages; i++) {
            pagesToAppear.push(i);
        }

        //if we have more than max pages in the list
        let pages = []
        if (pagesToAppear.length > maxButtonsToShow) {

            let sliceToStart = Math.floor(maxButtonsToShow/2.0 - 3/2.0);
            let sliceToEnd = Math.floor(maxButtonsToShow/2.0 - 3/2.0);

            let start = (pagesToAppear.slice(0,sliceToStart));
            let middle = (pagesToAppear.slice(currentPage-1, currentPage+2));
            let end = (pagesToAppear.slice(pagesToAppear.length-sliceToEnd, pagesToAppear.length));

            middle = middle.filter( function( el ) {
                return start.indexOf( el ) < 0;
            });

            middle = middle.filter(function (el) {
                return end.indexOf( el ) < 0;
            });

            pages = pages.concat(start);
            pages = pages.concat(middle);
            pages = pages.concat(end);
        } else {
            pages = pagesToAppear;
        }

        //we need to find the gaps and put flags there
        let pagesWithFlags = [];
        for(var i=0; i<pages.length; i++) {
            pagesWithFlags.push(pages[i]);

            if(pages[i]+1 != pages[i+1] && pages[i+1] != null) {
                pagesWithFlags.push(-1);
            }
        }


        return pagesWithFlags;
    }

    renderButton = (pageNumber, index) => {
        const {currentPage} = this.props;
        if (currentPage === pageNumber) {
            return(
                <div
                    key={index}
                    className="page-button active-page"
                    onClick={() => {
                        this.changeCurrentPage(pageNumber);
                    }}>
                    {pageNumber}
                </div>
            );
        } else {
            return(
                <div
                    key={index}
                    className="page-button"
                    onClick={() => {
                        this.changeCurrentPage(pageNumber);
                    }}>
                    {pageNumber}
                </div>
            );
        }
    }

    render() {
        const {numItems, numItemsPerPage} = this.props;
        const {gap} = this.state;
        let numberOfPages = Math.ceil(numItems/numItemsPerPage);
        let pagesToAppear = this.retrievePagesThatShouldAppear(numberOfPages);

        return(
            <div className="row">
            {
                pagesToAppear.map((value, index) => {
                    if (value !== -1) {
                        return(
                            this.renderButton(value, index)
                        );
                    } else {
                        return(
                            <span key={index}>...</span>
                        );
                    }
                })
            }
            </div>
        )
    }

}