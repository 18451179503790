/**
 * Created by bcole on 8/4/19.
 */
import React from 'react';
import api from '../Utils/api'
import utils from '../Utils/utils';
import "./userManager.css"

export default class UserManager extends React.Component {

    constructor() {
        super();

        this.state = {
            users: [
                {username: "user1", user_id: 0, email: "ok@ok.com", groups: [6, 12, 15]},
                {username: "user2", user_id: 1, email: "blah@blah.com", groups: [12]},
                {username: "user3", user_id: 2, email: "oops@oops.com", groups: [6, 15]}
            ],
            groups: [{name: "Preferred Pool Inspections", id: 6}, {name: "Whatever", id: 12}, {
                name: "Another One",
                id: 15
            }],
            assigningGroupToUser: false,
            userIndexAssigning: -1,
            creatingNewUser: false,
            active: false,
            newuser: {username: "", email: ""},
            newGroup: "",
            openGroup: false
        }
    }

    componentDidMount() {
        this.refreshUsers();
    }

    componentWillReceiveProps(newProps) {

    };

    refreshUsers = () => {
        api.getLoggedInUser().then((result) => {
            api.getGroups().then((result) => {
                this.setState({
                    groups: result
                })
            });


            api.getUsers().then((result) => {
                this.setState({
                    users: result
                })
            });
        }).catch((e) => {
            this.props.history.push(`/`, this.state);
        });
    };

    removeUserFromGroup(group, userIndex) {
        const {users} = this.state;

        api.removeUserFromGroup(users[userIndex].user_id, group).then((result) => {
            const groupIndex = users[userIndex].groups.indexOf(group);
            users[userIndex].groups.splice(groupIndex, 1);

            this.setState({users: users});
        }).catch((e) => {
            alert("There was an error removing the group.");
        })
    };

    openCreateNewUser = () => {
        this.setState({
            creatingNewUser: true
        })
    };

    openAssignGroupToUser(userIndex) {
        this.setState({
            assigningGroupToUser: true,
            userIndexAssigning: userIndex
        });
    };

    closeAssignGroupsModal = () => {
        this.setState({
            assigningGroupToUser: false,
            userIndexAssigning: -1
        });
    };

    closeCreateUser = () => {
        this.setState({
            creatingNewUser: false
        })
    };

    addAllGroupsToProjects = () => {
        api.addAllGroupsToProjects().then((result) => {
            alert("added all groups to all projects");
        }).catch((e) => {
            alert("error assigning groups");
        })
    }

    assignGroupToUser = (e, userIndexAssigning) => {
        const {users} = this.state;
        const val = e.currentTarget.value;
        const numValue = Number(val);
        const groups = users[userIndexAssigning].groups || [];

        //we're not sure if it's a number or a string
        if (users[userIndexAssigning].groups.indexOf(numValue) < 0 && users[userIndexAssigning].groups.indexOf(val) < 0) {
            api.assignGroupToUser(users[userIndexAssigning].user_id, val).then((result) => {
                if (val != "") {
                    users[userIndexAssigning].groups.unshift(numValue);
                }

                this.setState({
                    users: users,
                    assigningGroupToUser: false,
                    userIndexAssigning: -1
                })
            }).catch((e) => {
                alert("There was an error assigning group to user.");
                this.setState({
                    users: users,
                    assigningGroupToUser: false,
                    userIndexAssigning: -1
                })
            })
        }
    };

    setusername = (e) => {
        const {newuser} = this.state;
        newuser.username = e.currentTarget.value;

        this.setState({
            newuser: newuser
        })
    };

    setemail = (e) => {
        const {newuser} = this.state;
        newuser.email = e.currentTarget.value;

        this.setState({
            newuser: newuser
        })
    };

    addNewUser = () => {
        const {newuser} = this.state;
        const username = newuser.username;
        const email = newuser.email;

        const userNameNoSpace = username.replace(/\s+/g, '');
        const emailValidate = utils.emailIsValid(email);

        if (userNameNoSpace != username || username.length < 3) {
            alert("please enter a username with no spaces and more than 3 characters");

        } else if (!emailValidate) {
            alert("please enter a valid email address.  You'll need this to get a password.");

        } else {

            this.setState({
                active: true
            });

            api.addNewUser(username, email).then((result) => {
                alert("created new user");
                this.setState({
                    creatingNewUser: false,
                    active: false
                });

                this.refreshUsers();

            }).catch((e) => {
                alert("error creating new user");
                this.setState({
                    creatingNewUser: false,
                    active: false
                });

                this.refreshUsers();
            })
        }
    };

    removeUser = (userIndex) => {
        const {users} = this.state;

        api.removeUser(users[userIndex].user_id).then((result) => {
            users.splice(userIndex, 1);

            this.setState({users: users});
        }).catch((e) => {
            alert("There was an error removing this user.");
        })
    };

    openGroupDialog = () => {
        this.setState({
            openGroup: true
        })
    };

    closeGroupDialog = () => {
      this.setState({
          openGroup: false,
      })
    };

    addNewGroup = () => {
        const {groups, newGroup} = this.state;

        if (newGroup.length < 3) {
            alert("please enter a group name with more than 3 characters");

        } else {
            api.addNewGroup(newGroup).then((result) => {

                groups.push(result.data);

                this.setState({
                    groups: groups,
                    newGroup: "",
                    openGroup: false,
                    active: false
                })

            }).catch((e) => {
                alert("There was an error adding this group.");
            })
        }
    };

    setNewGroup = (e) => {

        const newGroup = e.currentTarget.value;

        this.setState({
            newGroup: newGroup
        })
    }

    deleteGroup = (groupIndex) => {

        const {groups} = this.state;
        const group_id = groups[groupIndex].group_id;

        api.deleteGroup(group_id).then((result) => {
            groups.splice(groupIndex, 1);

            this.setState({groups: groups});
        }).catch((e) => {
            alert("There was an error removing this user.");
        })
    };

    render(){

        const {users, groups, assigningGroupToUser, userIndexAssigning, creatingNewUser, newuser, active, openGroup, newGroup} = this.state;

        let userlist = [];
        let groupList = [];

        let groupObj = {};
        groups.forEach((group) => {
           groupObj[group.group_id] = group;
        });

        users.forEach((user, userIndex) => {
            userlist.push(
                <div className="userManagerItemHolder">
                    <div className="userManagerItem">
                        <div className="userTitle">{user.username}</div>
                        <div className="userTitle">{user.verified ? <i className="fa fa-check" /> : <i className="fa fa-close" />}</div>
                        <div className="admin-button-noMargin growHide centered-text" onClick={() => this.openAssignGroupToUser(userIndex)}>assign groups <i className="fa fa-folder"/></div>
                        <div className="admin-button-noMargin growHide centered-text" onClick={() => this.removeUser(userIndex)}><i className="fa fa-minus-circle"/></div>
                    </div>
                    <div className="userManagerItemGroupAssignment">
                        {
                            user.groups.map((gp) => {
                                return (
                                    <div className="userRow">
                                        <div>{ groupObj[gp] ? groupObj[gp].name : "unknown" }</div>
                                        <div className="admin-button-noMargin clearHeight" onClick={() => this.removeUserFromGroup(gp, userIndex)}>
                                            remove <i className="fa fa-minus-circle"/>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>);
        });

        groups.forEach((group, index) => {
            groupList.push(<div className="userManagerItem">
                <div>{group.name}</div>
                <div className="admin-button-noMargin" onClick={() => this.deleteGroup(index)}><i className="fa fa-minus-circle"/> </div>
            </div>);
        });

        return(
            <div className="userManager">
                { active &&
                    <div className="userManagerActive">
                        <i className="fa fa-spinner fa-spin"/>
                    </div>
                }
                {
                    openGroup &&
                    <div className="userManagerOverlay">
                        <div className="creatingNewUserWindow">
                            <div>Create New Group</div>
                            <div className="closeAssignGroupButton" onClick={this.closeGroupDialog}><i className="fa fa-close"/></div>
                            <div>username: <input type="text" value={newGroup} onChange={this.setNewGroup}/></div>
                            <button className="admin-button" onClick={this.addNewGroup}>
                                Add new group
                            </button>
                        </div>
                    </div>
                }
                {
                    creatingNewUser &&
                    <div className="userManagerOverlay">
                        <div className="creatingNewUserWindow">
                            <div>Create New User</div>
                            <div className="closeAssignGroupButton" onClick={this.closeCreateUser}><i className="fa fa-close"/></div>
                            <div>username: <input type="text" value={newuser.username} onChange={this.setusername}/></div>
                            <div>email: <input type="email" value={newuser.email} onChange={this.setemail}/></div>
                            <button className="admin-button" onClick={this.addNewUser}>
                                create user
                            </button>
                        </div>
                    </div>
                }

                {
                    assigningGroupToUser &&
                        <div className="userManagerOverlay">
                            <div className="assignGroupToUser">
                                <div>{users[userIndexAssigning].username}</div>
                                <div className="closeAssignGroupButton" onClick={this.closeAssignGroupsModal}><i className="fa fa-close"/></div>
                                <select onChange={(e) => this.assignGroupToUser(e, userIndexAssigning)}>
                                    <option value="" hidden="hidden">Add a group</option>
                                    <option value="" disabled="disabled">Add a group</option>
                                    {
                                        groups.map((group) => {
                                            return(
                                                <option value={group.group_id}>
                                                    {group.name}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                                <strong>assigned to:</strong>
                                <div className="assignedGroups">
                                    {
                                        users[userIndexAssigning].groups.map((gp) => {
                                            return(
                                                <div className="assignedName">
                                                    <i className="fa fa-check"/>
                                                    {groupObj[gp] ? groupObj[gp].name : "unknown"}
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                            </div>
                        </div>
                }
                <div className="userManagerGroups">
                    <div className="userManagerTitle">
                        <h4>Groups</h4>
                        <div className="titleText">Allow access to projects by group</div>
                        <button className="admin-button-noMargin" onClick={this.openGroupDialog}>add new group <i className="fa fa-plus-circle"/> </button>
                        <button className="admin-button-noMargin" onClick={this.addAllGroupsToProjects}>Add all groups to projects<i className="fa fa-plus-circle"/> </button>
                    </div>
                    {groupList}
                </div>

                <div className="userManagerUsers">
                    <div className="userManagerTitle">
                        <h4>Users</h4>
                        <div className="titleText">Assign groups to users</div>
                        <button className="admin-button-noMargin" onClick={this.openCreateNewUser}>create new user <i className="fa fa-plus-circle"/> </button>
                    </div>

                    <div className="userManagerItemHolder">
                        <div className="userManagerItem userManagerItemHeader">
                            <div className="userTitle">username</div>
                            <div className="userTitle">verfied</div>
                        </div>
                    </div>

                    {userlist}
                </div>
            </div>
        )
    }
}