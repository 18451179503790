/**
 * Created by bcole on 7/18/18.
 */
import React from 'react';
import api from '../Utils/api';

export default class SelectWidget extends React.Component {

    constructor() {
        super();

        this.state = {
            options: []
        };
    }

    componentDidMount() {
        const {data, dataurl} = this.props;
        if(dataurl) {
            api.getOptionsFrom(dataurl).then((result) => {
                this.setState({
                    options: result
                })
            })
        } else {
            this.setState({
                options: data || []
            })
        }
    }

    render() {

        const {label, setValue, value, otherOption, id, filledValue, otherFilledValue} = this.props;
        const {options} = this.state;

        return(
            <div className="inputField">
                {label}
                <select style={{width:"100%"}}
                        value={filledValue}
                        onChange={
                    (e) => setValue(e.currentTarget.value, id)
                }>
                    <option value="" key="0">- select one -</option>
                {
                    options.map((option, index) => {
                        return (<option value={option.value} key={index+1}>{option.key}</option>)
                    })
                }
                </select>
                {
                    value === "!" && otherOption &&
                    <div>
                        <input type="text" onChange={(e) => {
                            setValue(e.currentTarget.value, id, true)
                        }} />
                    </div>
                }
            </div>
        )
    }
}