/**
 * Created by bcole on 7/18/18.
 */
import React from 'react';
import ImageUpload from './imageupload';
import ResizeWidget from './resizeWidget';
import Axios from 'axios';
import Api from '../Utils/api';
import Endpoints from '../endpoints/endpoints';
import './imageWidget.css'

export default class ImageWidget extends React.Component {

    constructor(){
        super();

        this.state = {
            images: [],
            modalIsOpen: false,
            resizeWidgetOpen: false,
            modalImage: "",
            modalImageId: "",
            modalImageTags: [],
            allImageTags: [],
            searchValue: "",
            const_deletedImages: "deleted_image",
            crop: {x: 0, y: 0, width: 0, height: 0},
            openIndex: -1
        }
        this.domModalImage = React.createRef();
    }

    checkConnection = () => {
        return new Promise((resolve, reject) => {
            Api.checkConnection().then(() => {
                resolve()
            }).catch((e) => {
                reject(e);
            });
        });
    };

    refreshConnectionStatus = () => {
        this.props.refreshConnectionStatus();
    }

    update = () => {
        this.componentDidMount();
    }

    componentDidMount(){
        //reach out and grab uploaded images for this project
        //we MAY need to paginate
        const {projectId} = this.props;

        this.loadImages();

        Axios.get(`/mock/imageTags.json`)
        .then((result) => {
            this.setState({
                allImageTags: result.data.tags
            });
        });

        document.addEventListener("keydown", this.escapePress, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escapePress, false);
    }

    loadImages = () => {
        const {projectId} = this.props;

        console.log("LOAD IMAGES: "+ projectId);
        this.checkConnection().then(() => {
            return(Api.getImages(projectId))
        }).then((result) => {
            console.log(result.data);
            this.setState({
                images: result.data || [],
            });
        }).catch((e) => {
            // No connection
        });

    }

    // "Deletes" the images by setting the property, is_deleted to 1
    deleteImage = (e, id) => {
        const {projectId} = this.props;
        const {images, const_deletedImages} = this.state;
        e.preventDefault();
        console.log("Delete button clicked for " + id);

        let deletionData = this.consolidateDeletionsInLocalStorage();
        deletionData.push({"project_id": projectId, "image_id": id});

        Axios(`${Endpoints.host}${Endpoints.path.delete_image}`, {
                method: 'post',
                withCredentials: true,
                data: {
                    data: deletionData,
                    user_id: sessionStorage.user_id
                }
            }
        ).then(() => {
            let csv = localStorage.getItem(const_deletedImages);
            let debugMessage = "Successfully deleted image --->\n  image_id: " + id + "\n";
            if (csv !== null) {
                debugMessage += "Also deleted the following images in localStorage:\n  " + csv;
            }
            console.log(debugMessage);
            localStorage.removeItem(const_deletedImages);
            this.update();
        }).catch((result) => {
            console.log("Failed to connect to api.\nStoring deletion with id " + id + " in localStorage.");
            this.storeDeletionInLocalStorage(id);
            console.log(result.data);
            this.setState({
                images: this.removeImageFromArray(id)
            });
        });
    }

    /**
     * Store the deleted images' IDs in localStorage together
     * as a csv because localStorage will only store strings
     */
    storeDeletionInLocalStorage = (imageId) => {
        const {const_deletedImages} = this.state;
        let deletedItems;
        if (localStorage.getItem(const_deletedImages) === null) {
            localStorage.setItem(const_deletedImages, imageId);
        } else {
            deletedItems = localStorage.getItem(const_deletedImages) + "," + imageId;
            localStorage.setItem(const_deletedImages, deletedItems);
        }
        console.log("Stored deletion in localStorage --->\n"
            + "  image_id: " + imageId + "\n"
            + "  deleted IDs in localStorage:\n    " + localStorage.getItem(const_deletedImages)
        )
    }

    /**
     * Grab the csv of deleted images from local storage, then convert
     * it into an array of data objects that will be processed by
     * the api one by one.
     */
    consolidateDeletionsInLocalStorage = () => {
        const {const_deletedImages} = this.state;
        const {projectId} = this.props;
        let dataObjectArray = [];
        let csv = localStorage.getItem(const_deletedImages);

        if (csv !== null) {
            let idArray = csv.split(',');

            for (let i = 0; i < idArray.length; i++) {
                dataObjectArray.push({"project_id": projectId, "image_id": idArray[i]});
            }
        }
        return dataObjectArray;
    }

    removeImageFromArray = (imageId) => {
        const {images} = this.state;
        let returnArr = images;
        for (let i = 0; i < images.length; i++) {
            if (images[i].image_id === imageId) {
                returnArr.splice(i, 1);
                break;
            }
        }
        return returnArr;
    }

    // MODAL CALLBACK methods
    onOpenModal = (imageFile, imageId, x, y, width, height, index) => {
        const {modalImageTags, allImageTags} = this.state;
        /*
        console.log(
            "Running onOpenModal: " +
            "\n  imageFile: " + imageFile +
            "\n  imageId: " + imageId
        )
        */

        // Grab this image's tags
        Axios(`${Endpoints.host}${Endpoints.path.get_tags_from_image}/${imageId}`, {
            method: 'get',
            withCredentials: true
        })
        .then((result) => {
            /*
            console.log(
                "Result of getting image tags: " +
                "\n  " + result.data +
                "\n  isArray: " + (Array.isArray(result.data))
            );
            */

            let intArray = [];
            for (let i = 0; i < result.data.length; i++) {
                intArray.push(result.data[i]);
            }

            let tagsArray = [];
            for (let i = 0; i < allImageTags.length; i++) {
                let index = intArray.indexOf(parseInt(allImageTags[i].id));
                if (index >= 0) {
                    tagsArray.push(allImageTags[i]);
                }
            }

            this.setState({
                modalImageTags: tagsArray,
                modalIsOpen: true,
                modalImage: imageFile,
                modalImageId: imageId,
                crop: {x: x, y: y, width: width, height: height},
                openIndex: index
            });
        });
    }

    onCloseModal = () => {
        this.setState({
            modalIsOpen: false,
            modalImage: "",
            modalImageId: "",
            modalImageTags: [],
            searchValue: "",
            resizeWindowOpen: false
        });
    }

    escapePress = (e) => {
        const {modalIsOpen} = this.state;
        if (e.keyCode === 27 && modalIsOpen) {
            this.onCloseModal();
        }
    }


    // Adds a tag to an image
    addTag = (imageId, tagObject) => {
        const {modalImageTags} = this.state;
        const {projectId} = this.props;
        /*
        console.log(
            "Running addTag: " +
            "\n  imageId: " + imageId +
            "\n  tagId: " + tagId
        )
        */

        Axios(`${Endpoints.host}${Endpoints.path.add_tag_to_image}`, {
            method: 'post',
            data: {
                data: [
                    {
                        "image_id": imageId,
                        "tag_id": tagObject.id,
                        "project_id": projectId
                    }
                ]
            },
            withCredentials: true
        })
        .then((result) => {
            let tags = modalImageTags;
            let index = tags.map((e) => {return parseInt(e.id)}).indexOf(parseInt(tagObject.id));

            if (index < 0) {
                tags.push(tagObject);
            }
            
            this.setState({
                modalImageTags: tags
            });

            console.log(
                "Successfully added a tag:" +
                "\n  image id: " + imageId +
                "\n  tag id: " + tagObject.id
            );
        })
        .catch((e) => {
            console.log(e);
        });
    }

    // Remove tags
    removeTag = (imageId, tagObject) => {
        const {modalImageTags} = this.state;
        const {projectId, imagesToUpload} = this.props;

        Axios(`${Endpoints.host}${Endpoints.path.remove_tag_from_image}`, {
            method: 'post',
            data: {
                data: [
                    {
                        "image_id": imageId, 
                        "tag_id": tagObject.id,
                        "project_id": projectId
                    }
                ]
            },
            withCredentials: true
        })
        .then((result) => {
            let tags = modalImageTags;
            let index = tags.map((e) => {return parseInt(e.id)}).indexOf(parseInt(tagObject.id));

            // If the tag is in the arra in state, then remove it.
            if (index >= 0) {
                tags.splice(index, 1);
            }

            this.setState({
                modalImageTags: tags
            });

            console.log(
                "TAG: Successfully removed " +
                "\n  image id: " + imageId +
                "\n  tag id: " + tagObject.id
            );
        })
        .catch((e) => {
            console.log(e);
        });
    }

    // The value of the search field
    setSearchValue = (input) => {
        this.setState({
            searchValue: input
        });
    };

    openResizeModal = () => {
        this.setState({
            resizeWidgetOpen: true
        })
    };

    closeResizeModal = () => {
        this.setState({
            resizeWidgetOpen: false
        })
    };

    saveCrop = (crop, image) => {
        this.setState({
            crop: crop
        });
        Api.saveCrop(crop, image).then(() => {
            this.closeResizeModal();
        }).catch(() => {
            alert("error saving cropping");
        })
    };

    loadNextImage = () => {
        const {openIndex, images} = this.state;
        if(images.length > openIndex + 1) {
            const element = images[openIndex + 1];

            this.onOpenModal(element.filename, element.image_id,
                element.crop_x, element.crop_y, element.crop_w, element.crop_h,
                openIndex+1);
        }
    };

    loadPreviousImage = () => {
        const {openIndex, images} = this.state;
        if(openIndex > 0) {
            const element = images[openIndex - 1];

            this.onOpenModal(element.filename, element.image_id,
                element.crop_x, element.crop_y, element.crop_w, element.crop_h,
                openIndex-1);
        }
    };

    render(){

        const {data, label, id, projectId, imagesToUpload, connected} = this.props;
        const {
                images, modalIsOpen, modalImage,
                modalImageId, modalImageTags, allImageTags,
                searchValue, resizeWidgetOpen, crop
        } = this.state;

        return(
            <div className="imageWidget">
                { resizeWidgetOpen &&
                <ResizeWidget
                    src={`${Endpoints.getEndpoint(Endpoints.path.get_image)}/${modalImage}`}
                    domModalImage={this.domModalImage.current}
                    closeResizeModal={this.closeResizeModal}
                    saveCrop={(crop) => this.saveCrop(crop, modalImage)}
                    crop={crop}
                />}
                <div className="imageContainer"
                    style={{visibility: modalIsOpen ? 'visible' : 'hidden'}}
                    onClick={(e) => {
                        if (e.target.className == "imageContainer") {
                            this.onCloseModal();
                        }
                    }}
                    onKeyDown={(e) => {
                        let keyPressed = e.keyCode;
                        console.log("key: " + keyPressed);
                    }}
                >
                    <div className="imageWindow"
                        style={{visibility: modalIsOpen ? 'visible' : 'hidden'}}
                    >

                        <div className="imageHeader">
                            <span>Image</span>
                            <i className="fa fa-close icon-right" onClick={this.onCloseModal}/>
                        </div>

                        {/* The Image we are tagging */}
                        <div className="uploaded-modal-container">
                            {/* And previous */}
                            <div className="previous-image" onClick={this.loadPreviousImage}>
                                <i className="fa fa-chevron-circle-left"/>
                            </div>

                            <img
                                className="uploaded-modal-image"
                                src={`${Endpoints.getEndpoint(Endpoints.path.get_image)}/${modalImage}`}
                                ref={this.domModalImage}
                                onClick={this.openResizeModal}
                            />

                            {/* And next */}
                            <div className="next-image" onClick={this.loadNextImage}>
                                <i className="fa fa-chevron-circle-right"/>
                            </div>
                            <br/>
                        </div>


                        {/* Image Tagging area */}
                        {/*
                            The image tags are loaded from imageTags.json, which is filled
                            with a bunch of phony tags used for testing purposes.
                        */}
                        
                        <h4 className="centered-text"><strong>Tags</strong></h4>

                        {/* Input field enables user to filter the possible tags to choose from */}
                        <input type="text"
                            onChange={(e) => this.setSearchValue(e.target.value)}
                            placeholder="Search tags"
                        />

                        {/* Tags displayed here have not been attached to the image yet */}
                        <div className="tags-box row collapse"
                            style={{visibility: modalIsOpen ? 'visible' : 'hidden'}}
                        >
                        {
                            allImageTags.map((option, index) => {
                                let checked = false;
                                let tagId = parseInt(option.id);
                                let tagValue = option.value;

                                if (modalIsOpen) {
                                    if (modalImageTags.length > 0) {
                                        let indexOfTag = modalImageTags.map((e) => {return parseInt(e.id)}).indexOf(tagId);
                                        if (indexOfTag >= 0) {
                                            checked = true;
                                        }
                                    }

                                    if (tagValue.toLowerCase().includes( searchValue.toLowerCase() )
                                        || searchValue === ""
                                        || searchValue == null ) {
                                        return (
                                            <div key={index} className="checkboxWidget medium-6 small-12">
                                                <input type="checkbox"
                                                    id={`${id}_${tagId}`}
                                                    name={`${tagId}`}
                                                    checked={checked}
                                                    defaultChecked={checked}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            this.addTag(modalImageId, option);
                                                        } else {
                                                            this.removeTag(modalImageId, option);
                                                        }
                                                    }}
                                                />

                                                <label htmlFor={`${id}_${tagId}`}>
                                                    <div className="checkbox-label" style={{position: "absolute", top: 0, left: 40}}>
                                                        {tagValue}
                                                    </div>
                                                </label>
                                            </div>
                                        )
                                    }
                                }
                            })
                        }
                        </div>

                    </div>

                </div>

                <ImageUpload
                    projectId={projectId}
                    update={this.update}
                    setUploads={this.props.setUploads}
                    imagesToUpload={imagesToUpload}
                    checkConnection={this.checkConnection}
                    refreshConnectionStatus={this.refreshConnectionStatus}
                    connected={this.props.connected}
                    storingImage={this.props.storingImage}
                    setError={this.props.setError}
                    loginError={this.props.loginError}
                />
                <hr/>
                <div>
                    {/* If there are no images uploaded, show a message. */}
                    {
                        images.length <= 0 && connected ? (
                            <p className="centered-text">No Images Uploaded</p>
                        ) : (
                            <span></span>
                        )
                    }

                    {
                        !connected ? (
                            <p className="centered-text">
                                You are not connected to the internet.<br/>
                                You must be connected to see your uploaded images.
                            </p>
                        ) : (
                            <span></span>
                        )
                    }

                    <div className="row">
                    { /* Code to map through the images and display them. */
                        images.map((element, index) => {
                            return(
                                <div className="medium-4 small-12" key={index}>
                                    <div className="uploaded-image-div-outer">
                                        <div
                                            className="uploaded-image-div-inner centered-text"
                                            onClick={
                                                () => this.onOpenModal(
                                                    element.filename, element.image_id,
                                                    element.crop_x, element.crop_y, element.crop_w, element.crop_h,
                                                    index
                                                )
                                            }>
                                            <div className="uploaded-image-div-inner-image">
                                                <img className="uploaded-image" src={`${Endpoints.getEndpoint(Endpoints.path.get_image)}/${element.filename}`} />
                                            </div>
                                        </div>
                                        <div className="delete-button">
                                            <button
                                                onClick={(e) => { 
                                                    this.deleteImage(e, element.image_id);
                                                }}
                                                className="button centered-text">
                                                <i className="fa fa-2x fa-close" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                    </div>
                </div>
            </div>
        )
    }
}
