/**
 * Created by bcole on 7/18/18.
 */
import React from 'react';
import Calendar from 'react-calendar';

export default class DateWidget extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showCalendar: false
        }
    }

    showCalendar = () => {
        let {showCalendar} = this.state;

        this.setState({
            showCalendar: !showCalendar
        })
    };

    hideCalendar = () => {
        this.setState({
            showCalendar: false
        })
    };

    render(){

        const {data, label, setValue, id, filledValue, otherFilledValue} = this.props;
        const {showCalendar} = this.state;

        let filledDate = new Date(filledValue);
        if(isNaN(filledDate.getTime())) {
            filledDate = new Date();
        }

        let filledDateString = this.formatDate(filledDate);

        // TODO: Make sure you're updating the state of whatever is sending the properties to that component
        return(
            <div className="inputField">
                {label}
                { showCalendar &&
                    <Calendar
                        value={filledDate}
                        ref={this.input}
                        onChange={
                            (date) => {
                                this.hideCalendar();
                                let dateString = this.formatDate(date);
                                setValue(dateString, id)
                            }
                        }
                    />
                }
                <input type="text" autoCorrect="off" autoCapitalize="none"
                    // defaultValue is necessary here because it is the only way to make the value
                    // mutable using onBlur
                       value={filledDateString}
                       readOnly="true"
                       ref={this.input}
                       onClick={
                           this.showCalendar
                       }
                />
            </div>
        )
    }

    formatDate = (value) => {
        return value.getMonth()+1 + "/" + value.getDate() + "/" + value.getFullYear();
    }
}
