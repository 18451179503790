/**
 * Created by bcole on 7/18/18.
 */
import React from 'react';

export default class TextWidget extends React.Component {

    constructor(props) {
        super(props);
    }

    render(){

        const {data, label, setValue, id, filledValue, otherFilledValue} = this.props;

        // TODO: Make sure you're updating the state of whatever is sending the properties to that component
        return(
            <div className="inputField">
                {label}
                <input type="text" autoCorrect="off" autoCapitalize="none"
                    // defaultValue is necessary here because it is the only way to make the value
                    // mutable using onBlur
                       defaultValue={filledValue}
                       ref={this.input}
                       onBlur={
                            (e) => setValue(e.currentTarget.value, id)
                        }
                />
            </div>
        )
    }
}
