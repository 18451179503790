/**
 * Created by bcole on 5/2/19.
 */
import React from 'react';
import api from '../Utils/api'

export default class AdminForm extends React.Component {

    constructor() {
        super();

        this.state = {
            formJson: null,
            loaded: null
        }
    }

    componentDidMount() {

        let component = this.props.component;
        this.setForm(component, true);

        api.checkConnection().then((result) => {

        }).catch((e) => {
            this.props.history.push(`/`, this.state);
        })
    }

    componentWillReceiveProps(newProps) {
        let path = newProps.path;
        let component = newProps.component;

        //only update if we need to
        if(component != this.props.component) {
            this.setForm(component, true);
        }
    };

    setForm = (whatJson, willRecieveProps) => {

        //this is a little hacky, but we really only want to fetch new JSON if we're hitting back or forward
        //and don't want to push new history.
        //there is a better way to handle this
        if(!willRecieveProps) {
            this.props.history.push(`/admin/form/${whatJson}`, this.state);
        }

        api.getJSONForm(whatJson).then((result) => {
            console.log(result);
            this.setState({
                formJson: result,
                loaded: whatJson
            })
        }).catch((e) => {
            console.log("error:" + e);
        })
    };

    saveFormJSON = () => {
        const {loaded, formJson} = this.state;
        console.log(formJson);
        console.log(JSON.stringify(formJson));

        api.saveFormJSON(loaded, formJson).then((result) => {
            alert("SAVE SUCCESS");
        }).catch((e) => {
            alert("SAVE ERROR");
        })
    };

    changeCompanyName = (e) => {
        const {formJson} = this.state;
        const value = e.currentTarget.value;
        formJson.company_name = value;

        this.setState({
            formJson: formJson
        });
    };

    changeLabel = (e, index) => {
        const {formJson} = this.state;
        const value = e.currentTarget.value;
        formJson.group[index].label = value;

        this.setState({
            formJson: formJson
        });
    };

    changeCssClass = (e, index) => {
        const {formJson} = this.state;
        const value = e.currentTarget.value;
        formJson.group[index].css_class = value;

        this.setState({
            formJson: formJson
        });
    };

    changeValue = (e, index) => {
        const {formJson} = this.state;
        const value = e.currentTarget.value;
        formJson.group[index].value = value;

        this.setState({
            formJson: formJson
        });
    };

    addElement = (index) => {
        const {formJson} = this.state;
        formJson.group.splice(index+1, 0, {
            id: 0,
            display: ["always"],
            value: "",
            label: "",
            type: "select",
            data: [],
            css_class: "medium-12 small-12"
        });

        this.setState({
            formJson: formJson
        });
    };

    removeElement = (index) => {
        const {formJson} = this.state;
        formJson.group.splice(index, 1);

        if(formJson.group.length == 0) {
            formJson.group[index] = {
                id: 0,
                display: ["always"],
                value: "",
                label: "",
                type: "select",
                data: [],
                css_class: "medium-12 small-12"
            };
        }

        this.setState({
            formJson: formJson
        });
    };

    moveElementUp = (index) => {
        if(index == 0) return;

        const {formJson} = this.state;
        const group = formJson.group;

        let dataItem = group.splice(index, 1)[0];
        group.splice(index-1, 0, dataItem);
        formJson.group = group;

        this.setState({
            formJson: formJson
        });
    };

    moveElementDown = (index) => {
        const {formJson} = this.state;
        const group = formJson.group;

        let dataItem = group.splice(index, 1)[0];
        group.splice(index+1, 0, dataItem);
        formJson.group = group;

        this.setState({
            formJson: formJson
        });
    };

    moveUp = (index, item) => {
        const {formJson} = this.state;
        const data = formJson.group[index].data;
        let dataItem = data.splice(item, 1)[0];
        data.splice(item-1, 0, dataItem);

        formJson.group[index].data = data;

        this.setState({
            formJson: formJson
        });
    };

    moveDown = (index, item) => {
        const {formJson} = this.state;
        const data = formJson.group[index].data;
        let dataItem = data.splice(item, 1)[0];
        data.splice(item+1, 0, dataItem);

        formJson.group[index].data = data;

        this.setState({
            formJson: formJson
        });
    };

    changeType = (e, index) => {
        const value = e.currentTarget.value;

        const {formJson} = this.state;
        formJson.group[index].type = value;

        this.setState({
            formJson: formJson
        })
    };

    changeNumbersToWords = (e, index) => {
        const value = e.currentTarget.checked;

        const {formJson} = this.state;
        formJson.group[index].numbersToWords = value;

        this.setState({
            formJson: formJson
        })
    }

    changeOptionValue = (e, index, item, keyvalue) => {
        const {formJson} = this.state;
        const value = e.currentTarget.value;
        formJson.group[index].data[item][keyvalue] = value;

        this.setState({
            formJson: formJson
        });
    };

    addDisplayField = (index, item) => {
        const {formJson} = this.state;
        formJson.group[index].display.splice(item+1, 0, "");

        this.setState({
            formJson: formJson
        });
    };

    removeDisplayField = (index, item) => {
        const {formJson} = this.state;
        formJson.group[index].display.splice(item, 1);

        if(formJson.group[index].display.length == 0) {
            formJson.group[index].display = ["always"];
        }

        this.setState({
            formJson: formJson
        });
    };

    addOption = (index, item) => {
        const {formJson} = this.state;
        formJson.group[index].data.splice(item+1, 0, {key: "", value: ""});

        this.setState({
            formJson: formJson
        });
    };

    removeOption = (index, item) => {
        const {formJson} = this.state;
        formJson.group[index].data.splice(item, 1);

        this.setState({
            formJson: formJson
        });
    };

    changeRenderDisplayOn = (e, index, item, val) => {
        const {formJson} = this.state;
        const value = e.currentTarget.value;

        const sp = formJson.group[index].display[item].split("=");
        sp[val] = value;

        let joined;
        if (sp[1] == null || sp[1] == "") {
            joined = sp[0];
        } else {
            joined = sp.join("=");
        }
        formJson.group[index].display[item] = joined;

        this.setState({
            formJson: formJson
        });
    };

    renderDisplayOn = (displayOn, index) => {
        return(
            displayOn.map((display, i) => {
                let sp = display.split("=");
                return(<span className="admin-typein-container" key={i}>
                    <input type="text" value={sp[0]} className="admin-typein" onChange={(e) => this.changeRenderDisplayOn(e, index, i, 0)}/>
                    <div className="admin-typein-equal centered-text">
                        <div>
                            =
                        </div>
                    </div>
                    <input type="text" value={sp[1] || ""} className="admin-typein" onChange={(e) => this.changeRenderDisplayOn(e, index, i, 1)}/>
                    <div className="admin-typein-buttons">
                        <i className="fa fa-plus-circle fa-2x" onClick={(e) => this.addDisplayField(index, i)} />
                        <i className="fa fa-minus-circle fa-2x" onClick={(e) => this.removeDisplayField(index, i)} />
                    </div>
                </span>);
            })
        )
    };

    renderOptions = (options, index) => {
        if(options == null) {
            return;
        }

        if(options.length > 0) {
            return (
                options.map((option, i) => {
                    return (<span className="admin-typein-container" key={i}>
                        <input type="text" value={option.key} className="admin-typein"
                               onChange={(e) => this.changeOptionValue(e, index, i, "key")}/>
                        <div className="admin-typein-equal centered-text">
                            <div>
                                :
                            </div>
                        </div>
                        <input type="text" value={option.value} className="admin-typein"
                               onChange={(e) => this.changeOptionValue(e, index, i, "value")}/>
                        <div className="admin-options-buttons">
                            <i className="fa fa-plus-circle fa-2x" onClick={() => this.addOption(index, i)}/>
                            <i className="fa fa-minus-circle fa-2x" onClick={() => this.removeOption(index, i)}/>
                            <i className="fa fa-arrow-up fa-2x" onClick={() => this.moveUp(index, i)}/>
                            <i className="fa fa-arrow-down fa-2x" onClick={() => this.moveDown(index, i)}/>
                        </div>
                    </span>);
                })
            )
        }

        return(
            <div>
                <i className="fa fa-plus-circle fa-2x" onClick={() => this.addOption(index, 0)}/>
            </div>
        )
    };

    renderLabel = (label, index) => {
        return(
            <div>
                <span>Label:</span>
                <input type="text" value={label} onChange={(e) => this.changeLabel(e, index)}/>
            </div>
        )
    };

    renderValue = (value, index) => {
        return(
            <div>
                <span>Value:</span>
                <input type="text" value={value} onChange={(e) => this.changeValue(e, index)}/>
            </div>
        )
    };

    renderCssClass = (css_class, index) => {
        return(<input type="text" value={css_class} className="admin-typein" onChange={(e) => this.changeCssClass(e, index)}/>);
    };

    renderSelect = (formElement, index) => {
        return (
            <div key={index}>
                {
                    this.renderLabel(formElement.label, index)
                }
                {
                    this.renderValue(formElement.value, index)
                }
                <div>Type:</div>
                {
                    <select value={formElement.type} onChange={(e) => this.changeType(e, index)}>
                        <option value="select">select</option>
                        <option value="checkbox">checkbox</option>
                        <option value="text">text</option>
                        <option value="number">number</option>
                        <option value="date">date</option>
                        <option value="startGroup">startGroup</option>
                        <option value="endGroup">endGroup</option>
                    </select>
                }
                <div>Display On:</div>
                {
                    this.renderDisplayOn(formElement.display, index)
                }
                <div>Data:</div>
                {
                    this.renderOptions(formElement.data, index)
                }
                <div>CSS Class:</div>
                {
                    this.renderCssClass(formElement.css_class, index)
                }
            </div>
        )
    };

    renderText = (formElement, index) => {
        return (
            <div>
                {
                    this.renderLabel(formElement.label, index)
                }
                {
                    this.renderValue(formElement.value, index)
                }
                <div>Type:</div>
                {
                    <select value={formElement.type} onChange={(e) => this.changeType(e, index)}>
                        <option value="select">select</option>
                        <option value="checkbox">checkbox</option>
                        <option value="text">text</option>
                        <option value="number">number</option>
                        <option value="date">date</option>
                        <option value="startGroup">startGroup</option>
                        <option value="endGroup">endGroup</option>
                    </select>
                }

                <div>Display On:</div>
                {
                    this.renderDisplayOn(formElement.display, index)
                }
                <div>CSS Class:</div>
                {
                    this.renderCssClass(formElement.css_class, index)
                }
            </div>
        )
    };

    renderNumber = (formElement, index) => {
        return (
            <div>
                {
                    this.renderLabel(formElement.label, index)
                }
                {
                    this.renderValue(formElement.value, index)
                }
                <div>Type:</div>
                {
                    <select value={formElement.type} onChange={(e) => this.changeType(e, index)}>
                        <option value="select">select</option>
                        <option value="checkbox">checkbox</option>
                        <option value="text">text</option>
                        <option value="number">number</option>
                        <option value="date">date</option>
                        <option value="startGroup">startGroup</option>
                        <option value="endGroup">endGroup</option>
                    </select>
                }

                <div className="numbersToWords">
                    Save digits as text (2='two'):
                    <input type="checkbox"
                           checked={formElement.numbersToWords}
                           onChange={(e) => this.changeNumbersToWords(e, index)}
                    />
                </div>

                <div>Display On:</div>
                {
                    this.renderDisplayOn(formElement.display, index)
                }
                <div>CSS Class:</div>
                {
                    this.renderCssClass(formElement.css_class, index)
                }
            </div>
        )
    };

    renderDate = (formElement, index) => {
        return (
            <div>
                {
                    this.renderLabel(formElement.label, index)
                }
                {
                    this.renderValue(formElement.value, index)
                }
                <div>Type:</div>
                {
                    <select value={formElement.type} onChange={(e) => this.changeType(e, index)}>
                        <option value="select">select</option>
                        <option value="checkbox">checkbox</option>
                        <option value="text">text</option>
                        <option value="number">number</option>
                        <option value="date">date</option>
                        <option value="startGroup">startGroup</option>
                        <option value="endGroup">endGroup</option>
                    </select>
                }

                <div>Display On:</div>
                {
                    this.renderDisplayOn(formElement.display, index)
                }
                <div>CSS Class:</div>
                {
                    this.renderCssClass(formElement.css_class, index)
                }
            </div>
        )
    };

    renderCheckbox = (formElement, index) => {
        return (
            <div>
                {
                    this.renderLabel(formElement.label, index)
                }
                {
                    this.renderValue(formElement.value, index)
                }
                <div>Type:</div>
                {
                    <select value={formElement.type} onChange={(e) => this.changeType(e, index)}>
                        <option value="select">select</option>
                        <option value="checkbox">checkbox</option>
                        <option value="text">text</option>
                        <option value="number">number</option>
                        <option value="date">date</option>
                        <option value="startGroup">startGroup</option>
                        <option value="endGroup">endGroup</option>
                    </select>
                }

                <div>Display On:</div>
                {
                    this.renderDisplayOn(formElement.display, index)
                }
                <div>Data:</div>
                {
                    this.renderOptions(formElement.data, index)
                }
                <div>CSS Class:</div>
                {
                    this.renderCssClass(formElement.css_class, index)
                }
            </div>
        )
    };

    renderStartGroup = (formElement, index) => {
        return (
            <div>
                <div>Display On:</div>
                {
                    this.renderDisplayOn(formElement.display, index)
                }
                <div>Type:</div>
                {
                    <select value={formElement.type} onChange={(e) => this.changeType(e, index)}>
                        <option value="select">select</option>
                        <option value="checkbox">checkbox</option>
                        <option value="text">text</option>
                        <option value="number">number</option>
                        <option value="date">date</option>
                        <option value="startGroup">startGroup</option>
                        <option value="endGroup">endGroup</option>
                    </select>
                }

                <div>CSS Class:</div>
                {
                    this.renderCssClass(formElement.css_class, index)
                }
            </div>
        )
    };

    renderEndGroup = (formElement, index) => {
        return (
            <div>
                <div>Display On:</div>
                {
                    this.renderDisplayOn(formElement.display, index)
                }
                <div>Type:</div>
                {
                    <select value={formElement.type} onChange={(e) => this.changeType(e, index)}>
                        <option value="select">select</option>
                        <option value="checkbox">checkbox</option>
                        <option value="text">text</option>
                        <option value="number">number</option>
                        <option value="date">date</option>
                        <option value="startGroup">startGroup</option>
                        <option value="endGroup">endGroup</option>
                    </select>
                }
                <div>CSS Class:</div>
                {
                    this.renderCssClass(formElement.css_class, index)
                }
            </div>
        )
    };

    render(){
        const {formJson} = this.state;

        //we call it group in each file
        const data = [];
        if(formJson && formJson.field_groups) {

            console.log(formJson.field_groups);

            data.push(
                <div className="jsonGroup" key={-1}>
                    Company Name:
                    <input type="text" value={formJson.company_name} key={-1} onChange={(e) => this.changeCompanyName(e)}/>
                </div>
            );

            let index;
            for (let i=0; i < formJson.field_groups.length; i++) {
                index = i;
                if (formJson.field_groups[i].type == null) {
                    data.push(
                        <div key={i}>
                            <div className="admin-button" onClick={() => {
                                this.setForm(formJson.field_groups[i])
                            }}>
                                {formJson.field_groups[i]}
                            </div>
                        </div>
                    )
                }
            }

            data.push(<div className="save-button" key={index + 1}>
                <div className="admin-button" onClick={this.saveFormJSON}>
                    SAVE
                </div>
            </div>)
        }
        if(formJson && formJson.group) {
            if(formJson.group.length == 0) {
                data.push(
                    <div>
                        <i className="fa fa-plus-circle fa-2x" onClick={() => this.addElement(0)}/>
                    </div>
                );
            } else {

                data.push(
                    <div className="admin-filename jsonGroup">
                        File Loaded: <span className="admin-fileLoaded">{this.state.loaded}</span>
                    </div>
                );

                for (let i = 0; i < formJson.group.length; i++) {
                    if (formJson.group[i].type == "image_widget") {
                        data.push(
                            <div className="jsonGroup" key={i}>
                                IMAGE WIDGET
                                <div className="admin-options-buttons-bottom">
                                    <i className="fa fa-arrow-circle-o-up fa-2x" onClick={() => this.moveElementUp(i)}/>
                                    <i className="fa fa-arrow-circle-o-down fa-2x"
                                       onClick={() => this.moveElementDown(i)}/>
                                    <i className="fa fa-ellipsis-v fa-2x"/>
                                    <i className="fa fa-plus-circle fa-2x" onClick={() => this.addElement(i)}/>
                                    <i className="fa fa-minus-circle fa-2x" onClick={() => this.removeElement(i)}/>
                                    <div className="admin-options-buttons-right">
                                        <div className="admin-button" onClick={this.saveFormJSON}>
                                            SAVE
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    } else if (formJson.group[i].type == "select") {
                        data.push(
                            <div className="jsonGroup" key={i}>
                                <div>
                                    {this.renderSelect(formJson.group[i], i)}
                                </div>
                                <div className="admin-options-buttons-bottom">
                                    <i className="fa fa-arrow-circle-o-up fa-2x" onClick={() => this.moveElementUp(i)}/>
                                    <i className="fa fa-arrow-circle-o-down fa-2x"
                                       onClick={() => this.moveElementDown(i)}/>
                                    <i className="fa fa-ellipsis-v fa-2x"/>
                                    <i className="fa fa-plus-circle fa-2x" onClick={() => this.addElement(i)}/>
                                    <i className="fa fa-minus-circle fa-2x" onClick={() => this.removeElement(i)}/>
                                    <div className="admin-options-buttons-right">
                                        <div className="admin-button" onClick={this.saveFormJSON}>
                                            SAVE
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    } else if (formJson.group[i].type == "text") {
                        data.push(
                            <div className="jsonGroup" key={i}>
                                <div>
                                    {this.renderText(formJson.group[i], i)}
                                </div>
                                <div className="admin-options-buttons-bottom">
                                    <i className="fa fa-arrow-circle-o-up fa-2x" onClick={() => this.moveElementUp(i)}/>
                                    <i className="fa fa-arrow-circle-o-down fa-2x"
                                       onClick={() => this.moveElementDown(i)}/>
                                    <i className="fa fa-ellipsis-v fa-2x"/>
                                    <i className="fa fa-plus-circle fa-2x" onClick={() => this.addElement(i)}/>
                                    <i className="fa fa-minus-circle fa-2x" onClick={() => this.removeElement(i)}/>
                                    <div className="admin-options-buttons-right">
                                        <div className="admin-button" onClick={this.saveFormJSON}>
                                            SAVE
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    } else if (formJson.group[i].type == "number") {
                        data.push(
                            <div className="jsonGroup" key={i}>
                                <div>
                                    {this.renderNumber(formJson.group[i], i)}
                                </div>
                                <div className="admin-options-buttons-bottom">
                                    <i className="fa fa-arrow-circle-o-up fa-2x" onClick={() => this.moveElementUp(i)}/>
                                    <i className="fa fa-arrow-circle-o-down fa-2x"
                                       onClick={() => this.moveElementDown(i)}/>
                                    <i className="fa fa-ellipsis-v fa-2x"/>
                                    <i className="fa fa-plus-circle fa-2x" onClick={() => this.addElement(i)}/>
                                    <i className="fa fa-minus-circle fa-2x" onClick={() => this.removeElement(i)}/>
                                    <div className="admin-options-buttons-right">
                                        <div className="admin-button" onClick={this.saveFormJSON}>
                                            SAVE
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    } else if (formJson.group[i].type == "date") {
                        data.push(
                            <div className="jsonGroup" key={i}>
                                <div>
                                    {this.renderDate(formJson.group[i], i)}
                                </div>
                                <div className="admin-options-buttons-bottom">
                                    <i className="fa fa-arrow-circle-o-up fa-2x" onClick={() => this.moveElementUp(i)}/>
                                    <i className="fa fa-arrow-circle-o-down fa-2x"
                                       onClick={() => this.moveElementDown(i)}/>
                                    <i className="fa fa-ellipsis-v fa-2x"/>
                                    <i className="fa fa-plus-circle fa-2x" onClick={() => this.addElement(i)}/>
                                    <i className="fa fa-minus-circle fa-2x" onClick={() => this.removeElement(i)}/>
                                    <div className="admin-options-buttons-right">
                                        <div className="admin-button" onClick={this.saveFormJSON}>
                                            SAVE
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    } else if (formJson.group[i].type == "checkbox") {
                        data.push(
                            <div className="jsonGroup" key={i}>
                                <div>
                                    {this.renderCheckbox(formJson.group[i], i)}
                                </div>
                                <div className="admin-options-buttons-bottom">
                                    <i className="fa fa-arrow-circle-o-up fa-2x" onClick={() => this.moveElementUp(i)}/>
                                    <i className="fa fa-arrow-circle-o-down fa-2x"
                                       onClick={() => this.moveElementDown(i)}/>
                                    <i className="fa fa-ellipsis-v fa-2x"/>
                                    <i className="fa fa-plus-circle fa-2x" onClick={() => this.addElement(i)}/>
                                    <i className="fa fa-minus-circle fa-2x" onClick={() => this.removeElement(i)}/>
                                    <div className="admin-options-buttons-right">
                                        <div className="admin-button" onClick={this.saveFormJSON}>
                                            SAVE
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    } else if (formJson.group[i].type == "startGroup") {
                        data.push(
                            <div className="jsonGroup" key={i}>
                                <div>
                                    {this.renderStartGroup(formJson.group[i], i)}
                                </div>
                                <div className="admin-options-buttons-bottom">
                                    <i className="fa fa-arrow-circle-o-up fa-2x" onClick={() => this.moveElementUp(i)}/>
                                    <i className="fa fa-arrow-circle-o-down fa-2x"
                                       onClick={() => this.moveElementDown(i)}/>
                                    <i className="fa fa-ellipsis-v fa-2x"/>
                                    <i className="fa fa-plus-circle fa-2x" onClick={() => this.addElement(i)}/>
                                    <i className="fa fa-minus-circle fa-2x" onClick={() => this.removeElement(i)}/>
                                    <div className="admin-options-buttons-right">
                                        <div className="admin-button" onClick={this.saveFormJSON}>
                                            SAVE
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    } else if (formJson.group[i].type == "endGroup") {
                        data.push(
                            <div className="jsonGroup" key={i}>
                                <div>
                                    {this.renderEndGroup(formJson.group[i], i)}
                                </div>
                                <div className="admin-options-buttons-bottom">
                                    <i className="fa fa-arrow-circle-o-up fa-2x" onClick={() => this.moveElementUp(i)}/>
                                    <i className="fa fa-arrow-circle-o-down fa-2x"
                                       onClick={() => this.moveElementDown(i)}/>
                                    <i className="fa fa-ellipsis-v fa-2x"/>
                                    <i className="fa fa-plus-circle fa-2x" onClick={() => this.addElement(i)}/>
                                    <i className="fa fa-minus-circle fa-2x" onClick={() => this.removeElement(i)}/>
                                    <div className="admin-options-buttons-right">
                                        <div className="admin-button" onClick={this.saveFormJSON}>
                                            SAVE
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                }
            }
        }

        return(
            <div>
                {data}
            </div>
        )
    }
}
