/**
 * Created by bcole on 5/2/19.
 */
import React from 'react';
import api from '../Utils/api'

export default class AdminReport extends React.Component {

    constructor() {
        super();

        this.state = {
            formJson: null,
            loaded: null
        }
    }

    componentDidMount() {

        let component = this.props.component;
        this.setReport(component, true);

        api.getLoggedInUser().then((result) => {

        }).catch((e) => {
            this.props.history.push(`/`, this.state);
        })
    }

    componentWillReceiveProps(newProps) {
        let path = newProps.path;
        let component = newProps.component;

        //only update if we need to
        if (component != this.props.component) {
            this.setReport(component, true);
        }
    };

    setReport = (whatJson, willRecieveProps) => {

        if (!willRecieveProps) {
            this.props.history.push(`/admin/report/${whatJson}`, this.state);
        }

        api.getJSONForm(whatJson).then((result) => {
            console.log(result);
            this.setState({
                formJson: result,
                loaded: whatJson
            })
        }).catch((e) => {
            console.log("error:" + e);
        })
    };

    saveFormJSON = () => {
        const {loaded, formJson} = this.state;
        api.saveFormJSON(loaded, formJson).then((result) => {
            alert("SAVE SUCCESS");
        }).catch((e) => {
            alert("SAVE ERROR");
        })
    };

    changeValue = (e, index) => {
        const {formJson} = this.state;
        const value = e.currentTarget.value;
        formJson.group[index].value = value;

        this.setState({
            formJson: formJson
        });
    };

    addElement = (index) => {
        const {formJson} = this.state;
        formJson.page.splice(index + 1, 0, {
            node: {
                id: 0,
                display: ["always"],
                text: "",
            }
        });

        this.setState({
            formJson: formJson
        });
    };

    removeElement = (index) => {
        const {formJson} = this.state;
        formJson.page.splice(index, 1);

        if (formJson.page.length == 0) {
            formJson.page[index] = {
                condition: ["always"],
                text: ""
            };
        }

        this.setState({
            formJson: formJson
        });
    };

    moveElementUp = (index) => {
        if (index == 0) return;

        const {formJson} = this.state;
        const page = formJson.page;

        let dataItem = page.splice(index, 1)[0];
        page.splice(index - 1, 0, dataItem);
        formJson.page = page;

        console.log(formJson.page);

        this.setState({
            formJson: formJson
        });
    };

    moveElementDown = (index) => {
        const {formJson} = this.state;
        const page = formJson.page;

        let dataItem = page.splice(index, 1)[0];
        page.splice(index + 1, 0, dataItem);
        formJson.page = page;

        console.log(formJson.page);

        this.setState({
            formJson: formJson
        });
    };

    addDisplayField = (index, item) => {
        const {formJson} = this.state;

        if (formJson.page[index].node.condition) {
            formJson.page[index].node.condition.splice(item + 1, 0, "");
        } else {
            formJson.page[index].node.condition = [""];
        }

        this.setState({
            formJson: formJson
        });
    };

    removeDisplayField = (index, item) => {
        const {formJson} = this.state;
        formJson.page[index].node.condition.splice(item, 1);

        this.setState({
            formJson: formJson
        });
    };

    addNotDisplayField = (index, item) => {
        const {formJson} = this.state;

        if (formJson.page[index].node.not) {
            formJson.page[index].node.not.splice(item + 1, 0, "");
        } else {
            formJson.page[index].node.not = [""];
        }

        this.setState({
            formJson: formJson
        });
    };

    removeNotDisplayField = (index, item) => {
        const {formJson} = this.state;
        formJson.page[index].node.not.splice(item, 1);

        this.setState({
            formJson: formJson
        });
    };

    changeRenderDisplayOn = (e, index, item, val) => {
        const {formJson} = this.state;
        const value = e.currentTarget.value;

        if (val == "value" && typeof(formJson.page[index].node.condition[item]) == "string") {
            let oldVal = formJson.page[index].node.condition[item];
            formJson.page[index].node.condition[item] = {key: oldVal, value: value};

        } else if (val == "key" && value == "always") {
            formJson.page[index].node.condition[item] = value;
        } else if (val == "key" && typeof(formJson.page[index].node.condition[item]) == "string") {
            formJson.page[index].node.condition[item] = value;
        } else {
            formJson.page[index].node.condition[item][val] = value;
        }

        if ((formJson.page[index].node.condition[item]["key"] != "" && formJson.page[index].node.condition[item]["key"] != "always") &&
            (formJson.page[index].node.condition[item]["value"] == "")) {
            formJson.page[index].node.condition[item]["exists"] = true;
        } else if (formJson.page[index].node.condition[item]["exists"]) {
            delete(formJson.page[index].node.condition[item]["exists"]);
        }

        this.setState({
            formJson: formJson
        });
    };

    changeNotRenderDisplayOn = (e, index, item, val) => {
        const {formJson} = this.state;
        const value = e.currentTarget.value;

        if (val == "value" && typeof(formJson.page[index].node.not[item]) == "string") {
            let oldVal = formJson.page[index].node.not[item];
            formJson.page[index].node.not[item] = {key: oldVal, value: value};

        } else if (val == "key" && value == "always") {
            formJson.page[index].node.not[item] = value;
        } else if (val == "key" && typeof(formJson.page[index].node.not[item]) == "string") {
            formJson.page[index].node.not[item] = value;
        } else {
            formJson.page[index].node.not[item][val] = value;
        }

        if ((formJson.page[index].node.not[item]["key"] != "" && formJson.page[index].node.not[item]["key"] != "always") &&
            (formJson.page[index].node.not[item]["value"] == "")) {
            formJson.page[index].node.not[item]["exists"] = true;
        } else if (formJson.page[index].node.not[item]["exists"]) {
            delete(formJson.page[index].node.not[item]["exists"]);
        }

        this.setState({
            formJson: formJson
        });
    };

    changeTextValue = (e, index) => {
        const {formJson} = this.state;
        const value = e.currentTarget.value;
        formJson.page[index].node.text = value;

        this.setState({
            formJson: formJson
        });
    };

    changeAltTextValue = (e, index) => {
        const {formJson} = this.state;
        const value = e.currentTarget.value;
        formJson.page[index].node.alt = value;

        this.setState({
            formJson: formJson
        });
    };

    renderDisplayOn = (conditions, index) => {

        if (!conditions || conditions.length == 0) {
            return (
                <i className="fa fa-plus-circle fa-2x" onClick={(e) => this.addDisplayField(index, 0)}/>
            );
        }

        return (
            conditions.map((condition, i) => {
                return (<span className="admin-typein-container" key={i}>
                    <input type="text" value={condition.key ? condition.key : condition} className="admin-typein"
                           onChange={(e) => this.changeRenderDisplayOn(e, index, i, "key")}/>
                    <div className="admin-typein-equal centered-text">
                        <div>
                            =
                        </div>
                    </div>
                    <input type="text" value={condition.value || ""} className="admin-typein"
                           onChange={(e) => this.changeRenderDisplayOn(e, index, i, "value")}/>
                    <div className="admin-typein-buttons">
                        <i className="fa fa-plus-circle fa-2x" onClick={(e) => this.addDisplayField(index, i)}/>
                        <i className="fa fa-minus-circle fa-2x" onClick={(e) => this.removeDisplayField(index, i)}/>
                    </div>
                </span>);
            })
        )
    };

    renderDontDisplayOn = (conditions, index) => {

        if (conditions == null || conditions.length == 0) {
            return (
                <i className="fa fa-plus-circle fa-2x" onClick={(e) => this.addNotDisplayField(index, 0)}/>
            );
        }

        return (
            conditions.map((condition, i) => {
                return (<span className="admin-typein-container" key={i}>
                    <input type="text" value={condition.key ? condition.key : condition} className="admin-typein"
                           onChange={(e) => this.changeNotRenderDisplayOn(e, index, i, "key")}/>
                    <div className="admin-typein-equal centered-text">
                        <div>
                            =
                        </div>
                    </div>
                    <input type="text" value={condition.value || ""} className="admin-typein"
                           onChange={(e) => this.changeNotRenderDisplayOn(e, index, i, "value")}/>
                    <div className="admin-typein-buttons">
                        <i className="fa fa-plus-circle fa-2x" onClick={(e) => this.addNotDisplayField(index, i)}/>
                        <i className="fa fa-minus-circle fa-2x" onClick={(e) => this.removeNotDisplayField(index, i)}/>
                    </div>
                </span>);
            })
        )
    };

    renderText = (text, index) => {
        return (
            <textarea type="text" key={index} className="admin-textfield"
                      onChange={(e) => this.changeTextValue(e, index)} value={text}>
            </textarea>
        )
    };


    changeRenderAs = (e, index) => {
        const value = e.currentTarget.value;

        const {formJson} = this.state;
        formJson.page[index].node.as = value;

        this.setState({
            formJson: formJson
        })
    };

    changeRenderAsCheck = (e, index) => {
        const value = e.currentTarget.checked;

        const {formJson} = this.state;
        if (value) {
            formJson.page[index].node.as = "";
        } else {
            delete(formJson.page[index].node.as);
        }
        this.setState({
            formJson: formJson
        })
    };

    renderAs = (text, index) => {
        return (
            <input type="text" key={index} className="admin-textfield"
                   onChange={(e) => this.changeRenderAs(e, index)} value={text}>
            </input>
        )

    };

    checkRenderAs = (text, index) => {
        return (
            <input type="checkbox" key={index} checked={text} className="admin-textfield"
                   onChange={(e) => this.changeRenderAsCheck(e, index)} value={text}>
            </input>
        )
    };

    changeAltText = (e, index) => {
        const value = e.currentTarget.checked;

        const {formJson} = this.state;
        if (value) {
            formJson.page[index].node.alt = "";
        } else {
            delete(formJson.page[index].node.alt);
        }
        this.setState({
            formJson: formJson
        })
    };

    renderAltText = (alt, index) => {
        if (alt == undefined) {
            return (
                <div className="store-checkbox">
                    <div className="admin-storeResult">AltText:</div>
                    <input type="checkbox" key={index} className="admin-checkbox"
                           onChange={(e) => this.changeAltText(e, index)} checked={false}>
                    </input>
                </div>
            );
        } else {
            return (
                <div>
                    Alt Text:
                    <textarea type="text" key={index} className="admin-alt-textfield"
                              onChange={(e) => this.changeAltTextValue(e, index)} value={alt}>
                </textarea>
                    <div className="store-checkbox">
                        <div className="admin-storeResult">AltText:</div>
                        <input type="checkbox" key={index} className="admin-checkbox"
                               onChange={(e) => this.changeAltText(e, index)} checked={true}>
                        </input>
                    </div>
                </div>
            );
        }
    };

    changeToTextNode = (index) => {
        const {formJson} = this.state;

        if(!formJson.page[index].node) {
            formJson.page[index].node = {};
        }

        if(formJson.page[index].node.modList !== undefined) {
            delete(formJson.page[index].node.modList);
        }

        if(formJson.page[index].node.pagenote !== undefined) {
            delete(formJson.page[index].node.pagenote);
        }

        if (formJson.page[index].node.image !== undefined) {
            delete(formJson.page[index].node.image);

            if (formJson.page[index].node.images_per_line) {
                delete(formJson.page[index].node.images_per_line);
            }
        }

        formJson.page[index].node.text = "";

        if(formJson.page[index].NOTES !== undefined) {
            delete(formJson.page[index].NOTES);

            formJson.page[index].node.text = "";
        }

        this.setState({
            formJson: formJson
        })
    };

    changeToModList = (index) => {
        const {formJson} = this.state;

        if(!formJson.page[index].node) {
            formJson.page[index].node = {};
        }

        if(formJson.page[index].node.pagenote !== undefined) {
            delete(formJson.page[index].node.pagenote);
        }

        if (formJson.page[index].node.image !== undefined) {
            delete(formJson.page[index].node.image);

            if (formJson.page[index].node.images_per_line) {
                delete(formJson.page[index].node.images_per_line);
            }
        }

        if (formJson.page[index].node.text !== undefined) {
            delete(formJson.page[index].node.text);

            if (formJson.page[index].node.alt) {
                delete(formJson.page[index].node.alt);
            }
        }

        if(formJson.page[index].NOTES !== undefined) {
            delete(formJson.page[index].NOTES);

            formJson.page[index].node.text = "";
        }

        formJson.page[index].node.modList = {list: "", connector: "", modify: []};

        this.setState({
            formJson: formJson
        })
    }

    changeToImageNode = (index) => {
        const {formJson} = this.state;

        if(!formJson.page[index].node) {
            formJson.page[index].node = {};
        }

        if(formJson.page[index].node.modList !== undefined) {
            delete(formJson.page[index].node.modList);
        }

        if(formJson.page[index].node.pagenote !== undefined) {
            delete(formJson.page[index].node.pagenote);
        }

        if (formJson.page[index].node.text !== undefined) {
            delete(formJson.page[index].node.text);

            if (formJson.page[index].node.alt) {
                delete(formJson.page[index].node.alt);
            }
        }

        formJson.page[index].node.image = "";
        formJson.page[index].node.images_per_line = 2;

        if(formJson.page[index].NOTES !== undefined) {
            delete(formJson.page[index].NOTES);

            formJson.page[index].node.image = "";
            formJson.page[index].node.images_per_line = 2;
        }

        this.setState({
            formJson: formJson
        })
    };

    changeToNote = (index) => {
        const {formJson} = this.state;
        if (formJson.page[index].node) {
            delete(formJson.page[index].node);
        }

        formJson.page[index].NOTES = "";
        this.setState({
            formJson: formJson
        })
    };

    changeToPageNote = (index) => {
        const {formJson} = this.state;

        if(formJson.page[index].node.modList !== undefined) {
            delete(formJson.page[index].node.modList);
        }

        if(!formJson.page[index].node) {
            formJson.page[index].node = {};
        }

        if(formJson.page[index].node.image !== undefined) {
            delete(formJson.page[index].node.image);
        }

        if (formJson.page[index].node.text !== undefined) {
            delete(formJson.page[index].node.text);

            if (formJson.page[index].node.alt) {
                delete(formJson.page[index].node.alt);
            }
        }

        formJson.page[index].node.pagenote = "";

        if(formJson.page[index].NOTES !== undefined) {
            delete(formJson.page[index].NOTES);

            formJson.page[index].node.image = "";
            formJson.page[index].node.images_per_line = 2;
        }

        this.setState({
            formJson: formJson
        })
    };


    renderType = (page, index) => {

        let isNotes = page.NOTES !== undefined;
        let isImage = page.node && page.node.image !== undefined;
        let isPageNote = page.node && page.node.pagenote != undefined;
        let isModList = page.node && page.node.modList != undefined;
        let isText = !isNotes && !isImage && !isPageNote && !isModList;

        return (<div className="admin-type-select">
            Type:
            <span className={`select-button ${isText ? 'selected' : ""}`}
                  onClick={() => this.changeToTextNode(index)}>TEXT NODE</span>
            <span className={`select-button ${isModList ? 'selected' : ""}`}
                  onClick={() => this.changeToModList(index)}>MODIFY LIST</span>
            <span className={`select-button ${isImage ? 'selected' : ""}`}
                  onClick={() => this.changeToImageNode(index)}>IMAGE</span>
            <span className={`select-button ${isPageNote ? 'selected' : ""}`}
                  onClick={() => this.changeToPageNote(index)}>PAGE NOTE</span>
            <span className={`select-button ${isNotes ? 'selected' : ""}`}
                  onClick={() => this.changeToNote(index)}>COMMENT</span>
        </div>);
    };

    changeRenderImageTag = (e, index) => {
        const value = e.currentTarget.value;

        const {formJson} = this.state;
        formJson.page[index].node.image = value;

        this.setState({
            formJson: formJson
        })
    };

    changeImagesPerLine = (e, index) => {
        let value = parseInt(e.currentTarget.value);
        if (isNaN(value)) {
            value = 0;
        }

        const {formJson} = this.state;
        formJson.page[index].node.images_per_line = value;

        this.setState({
            formJson: formJson
        })
    };

    renderImageTag = (text, index) => {
        return (
            <div>
                <input type="text" value={text} onChange={(e) => this.changeRenderImageTag(e, index)}/>
            </div>
        )
    };

    renderImagesPerLine = (text, index) => {
        return (
            <div>
                <input type="text" value={text} onChange={(e) => this.changeImagesPerLine(e, index)}/>
            </div>
        )
    };

    changeNote = (e, index) => {
        const value = e.currentTarget.value;

        const {formJson} = this.state;
        formJson.page[index].NOTES = value;

        this.setState({
            formJson: formJson
        })
    };

    renderNotes = (note, index) => {
        return (
            <div>
                <input type="text" value={note} onChange={(e) => this.changeNote(e, index)}/>
            </div>
        )
    };

    renderPageNote = (note, index) => {
        return (
            <div>
                <input type="text" value={note} onChange={(e) => this.changePageNote(e, index)}/>
            </div>
        )
    };


    //modify a list of values
    renderModifyList = (modList, index) => {
        //{list: "", connector: "", modify: []};
        const {list} = modList;
        return (
            <div>
                <input type="text" value={list} onChange={(e) => this.changeModifyList(e, index)}/>
            </div>
        )
    };

    changeModifyList = (e, index) => {
        const value = e.currentTarget.value;

        const {formJson} = this.state;
        formJson.page[index].node.modList.list = value;

        this.setState({
            formJson: formJson
        })
    };

    renderAddConnector = (modList, index) => {
        //{list: "", connector: "", modify: []};
        const {connector} = modList;
        return (
            <div>
                <input type="text" value={connector} onChange={(e) => this.changeAddConnector(e, index)}/>
            </div>
        )
    };

    changeAddConnector = (e, index) => {
        const value = e.currentTarget.value;

        const {formJson} = this.state;
        formJson.page[index].node.modList.connector = value;

        this.setState({
            formJson: formJson
        })
    };

    renderChangeListValue = (modList, index) => {
        //{list: "", connector: "", modify: []};
        const {modify} = modList;
        if (!modify || modify.length == 0) {
            return (
                <i className="fa fa-plus-circle fa-2x" onClick={(e) => this.addModListValue(index, 0)}/>
            );
        }

        return (
            modify.map((condition, i) => {
                console.log("condition", condition);
                return (<span className="admin-typein-container" key={i}>
                    <input type="text" value={condition.key ? condition.key : ""} className="admin-typein"
                           onChange={(e) => this.changeModListValue(e, index, i, "key")}/>
                    <div className="admin-typein-equal centered-text">
                        <div>
                            to
                        </div>
                    </div>
                    <input type="text" value={condition.value || ""} className="admin-typein"
                           onChange={(e) => this.changeModListValue(e, index, i, "value")}/>
                    <div className="admin-typein-buttons">
                        <i className="fa fa-plus-circle fa-2x" onClick={(e) => this.addModListValue(index, i)}/>
                        <i className="fa fa-minus-circle fa-2x" onClick={(e) => this.removeModListValue(index, i)}/>
                    </div>
                </span>);
            })
        )
    }

    addModListValue = (index, atIndex) => {
        const {formJson} = this.state;
        formJson.page[index].node.modList.modify.push({key: "", value: ""});

        this.setState({
            formJson: formJson
        })
    }

    removeModListValue = (index, atIndex) => {
        const {formJson} = this.state;
        formJson.page[index].node.modList.modify[atIndex].delete();

        this.setState({
            formJson: formJson
        })
    }

    changeModListValue = (e, index, atIndex, key) => {
        const value = e.currentTarget.value;

        const {formJson} = this.state;
        let {modList} = formJson.page[index].node;
        let kvp = modList.modify[atIndex] || {key: "", value: ""}

        kvp[key] = value;
        formJson.page[index].node.modList.modify[atIndex] = kvp;

        this.setState({
            formJson: formJson
        })
    }


    changePageNote = (e, index) => {
        const value = e.currentTarget.value;

        const {formJson} = this.state;
        formJson.page[index].node.pagenote = value;

        this.setState({
            formJson: formJson
        })
    };

    renderNode = (page, index) => {
        if(page.node && page.node.modList != undefined) {
            return(
                <div key={index}>
                    <div>Modify List:</div>
                    {
                        this.renderModifyList(page.node.modList, index)
                    }
                    <div>Add Final Connector:</div>
                    {
                        this.renderAddConnector(page.node.modList, index)
                    }
                    <div>Change Value To:</div>
                    {
                        this.renderChangeListValue(page.node.modList, index)
                    }
                    {
                        page.node.as !== undefined &&
                        <div>
                            Store Result As:
                            {
                                this.renderAs(page.node.as, index)
                            }
                        </div>
                    }
                    <div className="store-checkbox">
                        <div className="admin-storeResult">Store Result:</div>
                        {
                            <div className="admin-checkbox">
                                {this.checkRenderAs(page.node.as, index)}
                            </div>
                        }
                    </div>
                </div>
            )
        } else if(page.node && page.node.pagenote != undefined) {
            return(
                <div key={index}>
                    {
                        this.renderPageNote(page.node.pagenote, index)
                    }
                </div>
            )
        } else if(page.NOTES !== undefined) {
            console.log("I HAVE NOTES!");
            return(
                <div key={index}>
                    {
                        this.renderNotes(page.NOTES, index)
                    }
                </div>
            )
        } else if(page.node.image !== undefined) {
            return(
                <div key={index}>
                    <div>Display Condition:</div>
                    {
                        this.renderDisplayOn(page.node.condition, index)
                    }
                    <div>Don't Display Condition:</div>
                    {
                        this.renderDontDisplayOn(page.node.not, index)
                    }
                    <div>Image Tag:</div>
                    {
                        this.renderImageTag(page.node.image, index)
                    }
                    <div>Images Per Line:</div>
                    {
                        this.renderImagesPerLine(page.node.images_per_line, index)
                    }
                </div>
            )
        } else {
            return (
                <div key={index}>
                    <div>Display Condition:</div>
                    {
                        this.renderDisplayOn(page.node.condition, index)
                    }
                    <div>Don't Display Condition:</div>
                    {
                        this.renderDontDisplayOn(page.node.not, index)
                    }
                    <div>Text:</div>
                    {
                        this.renderText(page.node.text, index)
                    }
                    {
                        this.renderAltText(page.node.alt, index)
                    }
                    {
                        page.node.as !== undefined &&
                        <div>
                            Store Result As:
                            {
                                this.renderAs(page.node.as, index)
                            }
                        </div>
                    }
                    <div className="store-checkbox">
                        <div className="admin-storeResult">Store Result:</div>
                        {
                            <div className="admin-checkbox">
                                {this.checkRenderAs(page.node.as, index)}
                            </div>
                        }
                    </div>
                </div>
            )
        }
    };

    render(){
        const {formJson} = this.state;

        //we call it group in each file
        const data = [];
        if(formJson && formJson.pages) {

            console.log(formJson.pages);

            let index;

            data.push(
                <div className="padFileList"></div>
            );

            for (let i=0; i < formJson.pages.length; i++) {
                index = i;
                if (formJson.pages[i].type == null) {
                    data.push(
                        <div key={i}>
                            <div className="admin-button" onClick={() => {
                                this.setReport(formJson.pages[i])
                            }}>
                                {formJson.pages[i]}
                            </div>
                        </div>
                    )
                }
            }

            data.push(<div className="save-button" key={index + 1}>
                <div className="admin-button" onClick={this.saveFormJSON}>
                    SAVE
                </div>
            </div>)
        }
        if(formJson && formJson.page) {
            if(formJson.page.length == 0) {
                data.push(
                    <div>
                        <i className="fa fa-plus-circle fa-2x" onClick={() => this.addElement(0)}/>
                    </div>
                );
            } else {
                data.push(
                    <div className="admin-filename jsonGroup">
                        File Loaded: <span className="admin-fileLoaded">{this.state.loaded}</span>
                    </div>
                );

                data.push(
                    <div className="jsonGroup admin-displayOrder">
                        Page Display Order:
                        <input type="text" value={formJson.order} />
                    </div>
                );

                for (let i = 0; i < formJson.page.length; i++) {
                    data.push(
                        <div className="jsonGroup" key={i}>
                            <div>
                                {this.renderType(formJson.page[i], i)}
                                {this.renderNode(formJson.page[i], i)}
                            </div>
                            <div className="admin-options-buttons-bottom">
                                <i className="fa fa-arrow-circle-o-up fa-2x" onClick={() => this.moveElementUp(i)}/>
                                <i className="fa fa-arrow-circle-o-down fa-2x"
                                   onClick={() => this.moveElementDown(i)}/>
                                <i className="fa fa-ellipsis-v fa-2x"/>
                                <i className="fa fa-plus-circle fa-2x" onClick={() => this.addElement(i)}/>
                                <i className="fa fa-minus-circle fa-2x" onClick={() => this.removeElement(i)}/>
                                <div className="admin-options-buttons-right">
                                    <div className="admin-button" onClick={this.saveFormJSON}>
                                        SAVE
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
        }

        return(
            <div>
                {data}
            </div>
        )
    }
}
